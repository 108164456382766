import {
  BillingPeriod,
  DATE_FORMAT_ORDINAL_MONTH_DAY_FNS,
  DATE_FORMAT_RANGE_FNS,
  PaymentFrequency,
  PaymentFrequencyType,
  RecurringDaySpec,
} from '@schooly/api';
import { DAY_OF_WEEK_OPTIONS } from '@schooly/components/form-text-field';
import { newDateTimezoneOffset } from '@schooly/utils/date';
import { format } from 'date-fns';
import { useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';

interface GetPeriodDateLabelProps {
  day: number;
  periodType: RecurringDaySpec['offset_cycle'];
  labelTextId?: string;
}

export const useFrequencyDateLabels = (frequency: PaymentFrequency) => {
  const { $t } = useIntl();

  const getWeeklyDateLabel = useCallback(
    ({ day, periodType, labelTextId }: GetPeriodDateLabelProps) => {
      const dayLabelTextId = DAY_OF_WEEK_OPTIONS.find(
        ({ value }) => day + 1 === value,
      )?.labelTextId;
      const weekDayLabel = $t({ id: dayLabelTextId });
      const periodTypeLabel = $t({ id: `frequencies-PeriodType-${periodType}` });

      return $t({ id: labelTextId }, { day: weekDayLabel, periodType: periodTypeLabel });
    },
    [$t],
  );

  const getMonthlyDateLabel = useCallback(
    ({ day, periodType, labelTextId }: GetPeriodDateLabelProps) => {
      const periodTypeLabel = $t({ id: `frequencies-PeriodType-${periodType}` });

      return $t(
        { id: labelTextId },
        { day: format(day + 1, DATE_FORMAT_ORDINAL_MONTH_DAY_FNS), periodType: periodTypeLabel },
      );
    },
    [$t],
  );

  const getLabelsFromDates = useCallback(<T extends BillingPeriod>(data: T[]) => {
    const [generationDays, invoiceDays] = data.reduce<Array<string[]>>(
      (acc, { generation_date, invoice_date }) => {
        acc[0].push(format(newDateTimezoneOffset(generation_date), DATE_FORMAT_RANGE_FNS));
        acc[1].push(format(newDateTimezoneOffset(invoice_date), DATE_FORMAT_RANGE_FNS));
        return acc;
      },
      [[], []],
    );

    return {
      generationDateLabel: generationDays.join(' / '),
      invoiceDateLabel: invoiceDays.join(' / '),
    };
  }, []);

  const [generationDateLabel, invoiceDateLabel] = useMemo(() => {
    switch (frequency.type) {
      case PaymentFrequencyType.Weekly: {
        const { offset_days: generationDay, offset_cycle: generationPeriodType } =
          frequency.billing_periods_spec.generation_date;
        const { offset_days: invoiceDay, offset_cycle: invoicePeriodType } =
          frequency.billing_periods_spec.invoice_date;

        return [
          getWeeklyDateLabel({
            day: generationDay,
            periodType: generationPeriodType,
            labelTextId: `frequencies-BillingFrequencyDescription-${frequency.type}`,
          }),
          getWeeklyDateLabel({
            day: invoiceDay,
            periodType: invoicePeriodType,
            labelTextId: `frequencies-BillingFrequencyDescription-${frequency.type}`,
          }),
        ];
      }

      case PaymentFrequencyType.Monthly: {
        const { offset_days: generationDay, offset_cycle: generationPeriodType } =
          frequency.billing_periods_spec.generation_date;
        const { offset_days: invoiceDay, offset_cycle: invoicePeriodType } =
          frequency.billing_periods_spec.invoice_date;

        return [
          getMonthlyDateLabel({
            day: generationDay,
            periodType: generationPeriodType,
            labelTextId: `frequencies-BillingFrequencyDescription-${frequency.type}`,
          }),
          getMonthlyDateLabel({
            day: invoiceDay,
            periodType: invoicePeriodType,
            labelTextId: `frequencies-BillingFrequencyDescription-${frequency.type}`,
          }),
        ];
      }

      case PaymentFrequencyType.Termly: {
        if (frequency.school_year_period_group_id) {
          const { generationDateLabel, invoiceDateLabel } = getLabelsFromDates(
            frequency.billing_periods,
          );

          return [generationDateLabel, invoiceDateLabel];
        }
        return [];
      }

      case PaymentFrequencyType.Biannually:
      case PaymentFrequencyType.Annually: {
        const { generationDateLabel, invoiceDateLabel } = getLabelsFromDates(
          frequency.billing_periods,
        );

        return [generationDateLabel, invoiceDateLabel];
      }

      default:
        return [];
    }
  }, [frequency, getLabelsFromDates, getMonthlyDateLabel, getWeeklyDateLabel]);

  const dueDateLabel = $t({ id: 'frequencies-DueDateDays' }, { count: frequency.due_days_count });

  return { generationDateLabel, invoiceDateLabel, dueDateLabel };
};
