import { Box, MenuItem, MenuList, Stack, Tooltip } from '@mui/material';
import { useGetEnrollments } from '@schooly/api';
import { Spin } from '@schooly/style';
import { getUserFullName } from '@schooly/utils/user-helpers';
import { FC, useCallback } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';

import { ModalSideBar } from '../../components/uikit-components/Modal/Modal.styled';
import { ProfileModalMode } from '../../context/profile/helpers';
import { useProfile } from '../../context/profile/useProfile';
import useAppLocation from '../../hooks/useAppLocation';
import { useSchool } from '../../hooks/useSchool';

export const ProfileModalLeftSidebar: FC = () => {
  const location = useAppLocation();
  const { tabs, mode, actions, userType, user } = useProfile();
  const { schoolId } = useSchool();
  const studentRelationId = user?.relation_id ?? '';
  const isStudent = userType === 'student';
  const { $t } = useIntl();

  const { data } = useGetEnrollments(
    {
      schoolId: schoolId ?? '',
      studentId: studentRelationId,
    },
    {
      enabled: Boolean(schoolId && isStudent && studentRelationId),
      refetchOnMount: 'always',
    },
  );

  const handleClick = useCallback(
    (to: ProfileModalMode) => () => {
      actions.setMode(to);
    },
    [actions],
  );

  return (
    <ModalSideBar
      sx={{
        paddingTop: (theme) => theme.spacing(1),
      }}
    >
      <MenuList>
        {tabs.map((tab) => {
          const isStudentRegistrationTab = isStudent && tab.id === 'registrations';
          const disableRegistrationTitle =
            isStudentRegistrationTab && data?.rollover_in_process && user
              ? $t(
                  { id: 'students-AnnualRollover-DisableRegistration' },
                  { fullName: getUserFullName(user) },
                )
              : '';
          return (
            <Tooltip title={disableRegistrationTitle}>
              <Box
                sx={{
                  a: disableRegistrationTitle && {
                    pointerEvents: 'none',
                  },
                }}
              >
                <Link
                  key={tab.id}
                  onClick={handleClick(tab.id)}
                  to={{ hash: `#${tab.id}` }}
                  state={location.state}
                  replace
                >
                  <MenuItem selected={mode === tab.id} key={tab.id}>
                    <Stack
                      direction="row"
                      alignItems="center"
                      justifyContent="space-between"
                      flexGrow={1}
                    >
                      <FormattedMessage id={tab.title} />
                      {disableRegistrationTitle && <Spin fontSize="18px" color="primary.main" />}
                    </Stack>
                  </MenuItem>
                </Link>
              </Box>
            </Tooltip>
          );
        })}
      </MenuList>
    </ModalSideBar>
  );
};
