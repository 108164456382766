import { Group } from '@schooly/api';
import { debounce, isEqual } from 'lodash';
import { FC, PropsWithChildren, useCallback, useMemo, useRef, useState } from 'react';

import {
  ApplyActionPayload,
  FiltersActions,
  FiltersContext,
  getDefaultDraft,
  getInitialState,
  InitialStateProps,
  ListGroupFilters,
} from './FiltersContext';

export interface WithFiltersProps extends PropsWithChildren<InitialStateProps> {}

export const WithFilters: FC<WithFiltersProps> = ({ children, ...settings }) => {
  const [state, setState] = useState(getInitialState(settings));
  const [groups, setGroups] = useState<Group[]>([]);
  const [groupsFilters, setGroupsFilters] = useState<ListGroupFilters>({});
  const [asyncFiltersInitialized, setAsyncFiltersInitialized] = useState(false);
  const appliedFilter = useRef<ApplyActionPayload | null>(null);

  const setMultipleDraftValues = useCallback<FiltersActions['setMultipleDraftValues']>(
    (payload) => {
      const newState = {
        ...state,
        filters: {
          ...state.filters,
          draft: {
            ...state.filters.draft,
          },
        },
      };

      payload.forEach((item) => {
        newState.filters.draft[item.key] = item.value;
      });

      setState(newState);
    },
    [state],
  );

  const setDraftValue = useCallback<FiltersActions['setDraftValue']>(
    (payload) => {
      setMultipleDraftValues([payload]);
    },
    [setMultipleDraftValues],
  );

  const applyFiltersNoDebounce = useCallback<FiltersActions['applyFilters']>(
    (payload) => {
      if (isEqual(payload, appliedFilter.current)) {
        return;
      }

      appliedFilter.current = payload ?? null;

      const newState = {
        ...state,
        filters: {
          ...state.filters,
        },
      };

      if (payload?.filters) {
        newState.filters.draft = payload.filters;
      }

      if (payload?.query != null) {
        newState.filters.draftQuery = payload.query;
      }

      if (payload) {
        // `undefined` is a valid value
        newState.filters.draftArrangeBy = payload.arrangeBy;
      }

      if (payload) {
        // `undefined` is a valid value
        newState.filters.draftGroupBy = payload.groupBy;
      }

      // apply by default
      if (payload?.apply == null || payload?.apply) {
        newState.filters.applied = newState.filters.draft;
        newState.filters.appliedArrangeBy = newState.filters.draftArrangeBy;
        newState.filters.appliedGroupBy = newState.filters.draftGroupBy;
        newState.filters.appliedQuery = newState.filters.draftQuery;
        newState.filters.appliedExclude = newState.filters.draftExclude;
      }

      setState(newState);

      // https://schooly.atlassian.net/browse/TR-2327
      // Set filters synchronously as they might be used synchronously right after apply.
      // See `HeaderFilterSaveModal::handleSaveApply()`
      state.filters = newState.filters;
    },
    [state],
  );

  const applyFiltersWithDebounce = useMemo<FiltersActions['applyFilters']>(
    // This is a temporary fix, we need to debounce applyFilters where it was called
    () => debounce(applyFiltersNoDebounce, 1000),

    [applyFiltersNoDebounce],
  );

  const applyFilters = useCallback<FiltersActions['applyFilters']>(
    (payload) => {
      // TODO: get rid of noDebounce flag
      if (payload?.noDebounce) {
        applyFiltersNoDebounce(payload);
      } else {
        applyFiltersWithDebounce(payload);
      }
    },
    [applyFiltersNoDebounce, applyFiltersWithDebounce],
  );

  const setFilters = useCallback<FiltersActions['setFilters']>(
    (draft) => {
      const newState = {
        ...state,
        filters: {
          ...state.filters,
        },
      };

      newState.filters.draft = draft;

      setState(newState);
    },
    [state],
  );

  const setArrangeBy = useCallback<FiltersActions['setArrangeBy']>(
    (draftArrangeBy) => {
      const newState = {
        ...state,
        filters: {
          ...state.filters,
        },
      };

      newState.filters.draftArrangeBy = draftArrangeBy;

      setState(newState);
    },
    [state],
  );

  const setGroupBy = useCallback<FiltersActions['setGroupBy']>(
    (draftGroupBy) => {
      const newState = {
        ...state,
        filters: {
          ...state.filters,
        },
      };

      newState.filters.draftGroupBy = draftGroupBy;

      setState(newState);
    },
    [state],
  );

  const setExclude = useCallback<FiltersActions['setExclude']>(
    (draftExclude) => {
      const newState = {
        ...state,
        filters: {
          ...state.filters,
        },
      };

      newState.filters.draftExclude = draftExclude;

      setState(newState);
    },
    [state],
  );

  const resetFilters = useCallback<FiltersActions['resetFilters']>(() => {
    return {
      ...state,
      filters: {
        ...state.filters,
        draft: getDefaultDraft(),
        applied: undefined,
      },
    };
  }, [state]);

  const value = useMemo(
    () => ({
      ...state,
      lists: {
        groups,
      },
      listFilters: {
        groups: groupsFilters,
      },
      actions: {
        setDraftValue,
        setMultipleDraftValues,
        applyFilters,
        applyFiltersWithoutDebounce: applyFiltersNoDebounce,
        setFilters,
        setArrangeBy,
        setGroupBy,
        resetFilters,
        setExclude,
        setGroupsList: setGroups,
        setGroupsListFilters: setGroupsFilters,
        setAsyncFiltersInitialized,
      },
      asyncFiltersInitialized,
    }),
    [
      state,
      groups,
      groupsFilters,
      setDraftValue,
      setMultipleDraftValues,
      applyFilters,
      applyFiltersNoDebounce,
      setFilters,
      setArrangeBy,
      setGroupBy,
      resetFilters,
      setExclude,
      asyncFiltersInitialized,
    ],
  );

  return <FiltersContext.Provider value={value}>{children}</FiltersContext.Provider>;
};
