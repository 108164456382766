import { Button, Stack, Typography } from '@mui/material';
import {
  Product,
  SORT_DIRECTION,
  useGetProductsListQuery,
  useGetSchoolPaymentFrequencies,
  useGetSchoolQuery,
} from '@schooly/api';
import { useAuth } from '@schooly/components/authentication';
import { PageHeader, PageHeaderSearchInput } from '@schooly/components/filters';
import { MainGridNoResultsStub } from '@schooly/components/stubs';
import { CURRENCY_SYMBOLS } from '@schooly/constants';
import { GridBody, MainPageGrid, PlusIcon, SkeletonGridLoader, SkeletonRows } from '@schooly/style';
import { FC, useCallback, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { Link, useNavigate } from 'react-router-dom';

import useSchoolYears from '../../../hooks/useSchoolYears';
import { getSortedFrequencies } from './helpers';
import { ProductFilters } from './ProductFilters';
import { FrequenciesTooltip, NoFrequencies } from './SchoolProductCommon';
import { ProductRow, ProductsHeader } from './SchoolProductsGrid';
import { SchoolProductsSkeleton } from './SchoolProductsSkeleton';

export const PAGE_SIZE = 30;
export const SKELETON_COLUMNS = 12;

export const SchoolProductsContent: FC = () => {
  const { schoolId = '', permissions } = useAuth();
  const { $t } = useIntl();
  const navigate = useNavigate();

  const [showAllFrequencies, setShowAllFrequencies] = useState(false);

  const { defaultValidity } = useSchoolYears();

  const canCreate =
    permissions.includes('product_and_invoice_creator') ||
    permissions.includes('product_and_invoice_manager');

  const canViewFrequency = permissions.includes('product_and_invoice_viewer');

  const { isLoading, data, params, isFetchingNextPage, fetchNextPage, hasNextPage, setParams } =
    useGetProductsListQuery(
      { schoolId, query: '', sort: { columnTextId: 'name', direction: SORT_DIRECTION.ASC } },
      { refetchOnMount: 'always', enabled: !!schoolId },
    );

  const { data: currentSchool } = useGetSchoolQuery(schoolId, {
    enabled: !!schoolId,
  });
  const currencySymbol = currentSchool?.currency ? CURRENCY_SYMBOLS[currentSchool.currency] : '';

  const handleSetFiltersQuery = useCallback(
    (query: string) => {
      setParams((p) => ({ ...p, query }));
    },
    [setParams],
  );

  const { data: frequencyData } = useGetSchoolPaymentFrequencies(
    {
      school_id: schoolId,
      year_id: defaultValidity?.id ?? '',
    },
    { enabled: !!defaultValidity?.id },
  );

  const { sortedFrequencies, hasAvailableFrequencies } = useMemo(() => {
    const sortedFrequencies = frequencyData?.frequencies
      ? getSortedFrequencies(frequencyData.frequencies)
      : undefined;

    return {
      sortedFrequencies: showAllFrequencies
        ? sortedFrequencies
        : sortedFrequencies?.filter((f) => f.in_use),
      hasAvailableFrequencies: !!sortedFrequencies?.some((f) => f.in_use),
    };
  }, [frequencyData?.frequencies, showAllFrequencies]);

  const handleAddApplicationClick = useCallback(() => {
    navigate('/settings/products/new');
  }, [navigate]);

  const entries = useMemo(
    () => data?.pages.reduce<Product[]>((prev, curr) => [...prev, ...curr.results], []) ?? [],

    [data?.pages],
  );

  const total = data?.pages[0].count;

  if (!data || !sortedFrequencies) return <SchoolProductsSkeleton />;

  const noAvailableFrequenciesTitle = !hasAvailableFrequencies ? (
    <NoFrequencies canView={canViewFrequency} />
  ) : (
    ''
  );

  if (!params.query && !total) {
    const createProductButton = (
      <Button disabled={!hasAvailableFrequencies} startIcon={<PlusIcon />}>
        {$t({ id: 'products-NewProduct' })}
      </Button>
    );

    return (
      <Stack alignItems="center" justifyContent="center" flex={1}>
        <Typography variant="h1" textAlign="center" mb={1} maxWidth={417}>
          {$t({ id: 'products-NoProducts' })}
        </Typography>

        {canCreate && (
          <>
            <Typography variant="h3" mb={3}>
              {$t({ id: 'products-CreateANewProduct' })}
            </Typography>

            {hasAvailableFrequencies ? (
              <Link to="/settings/products/new">{createProductButton} </Link>
            ) : (
              <FrequenciesTooltip title={noAvailableFrequenciesTitle}>
                <span>{createProductButton}</span>
              </FrequenciesTooltip>
            )}
          </>
        )}
      </Stack>
    );
  }

  return (
    <>
      <Stack gap={1}>
        <PageHeader
          pageTitleCounter={total}
          buttonTextId="products-NewProduct"
          pageTitleTextId="products-Title"
          showActionButton={canCreate}
          buttonIcon={<PlusIcon />}
          buttonDisabled={!hasAvailableFrequencies}
          onButtonClick={handleAddApplicationClick}
          tooltipTitle={noAvailableFrequenciesTitle}
        >
          <PageHeaderSearchInput
            value={params.query || ''}
            onChangeText={handleSetFiltersQuery}
            placeholder={$t({ id: 'products-SearchAmongProducts' })}
          />
        </PageHeader>
        <ProductFilters
          onSetShowAllFrequencies={setShowAllFrequencies}
          showAllFrequencies={showAllFrequencies}
          defaultFilters={{}}
          filters={{}}
          defaultShowAllFrequencies={showAllFrequencies}
        />
      </Stack>

      <MainPageGrid mt={2}>
        <ProductsHeader frequencies={sortedFrequencies} />
        <GridBody>
          {entries?.map((entry) => (
            <ProductRow
              schoolId={schoolId}
              product={entry}
              key={entry.id}
              currencySymbol={currencySymbol}
              frequencies={sortedFrequencies}
              canViewFrequency={canViewFrequency}
            />
          ))}
          {isLoading && <SkeletonRows columnsCount={SKELETON_COLUMNS} amount={PAGE_SIZE} />}
          <SkeletonGridLoader
            isFetching={isLoading || isFetchingNextPage}
            fetchNextPage={fetchNextPage}
            hasNextPage={hasNextPage}
            columnsCount={SKELETON_COLUMNS}
            amount={Math.min(
              PAGE_SIZE,
              total && data ? total - data.pages.length * PAGE_SIZE : PAGE_SIZE,
            )}
          />
        </GridBody>
      </MainPageGrid>
      {!isLoading && !entries.length && (
        <MainGridNoResultsStub textId="products-noProductsMatching" showFiltersInfo={false} />
      )}
    </>
  );
};
