import { Icon, Stack, Typography } from '@mui/material';
import { LegalEntity, useGetSchoolQuery } from '@schooly/api';
import { ArrowRightIcon } from '@schooly/style';
import React, { FC, useCallback, useEffect } from 'react';
import { useIntl } from 'react-intl';

import {
  LegalEntityRemoveModalType,
  useLegalEntity,
} from '../../../../context/legalEntity/WithLegalEntity';
import { ReplaceProductsAccountsForm } from '../SchoolLegalEntityCreate/ReplaceProductsAccountsContent';
import { LegalEntityProductsModal } from '../SchoolLegalEntityProducts/LegalEntityProductsModal';
import { LegalEntityRemoveConfirmationModal } from './LegalEntityRemoveConfirmationModal';
import { LegalEntityReplaceModal } from './LegalEntityReplaceModal';
import { LegalEntityStartRemoveModal } from './LegalEntityStartRemoveModal';
import { ReplaceProductsAccountsOnRemoveModal } from './ReplaceProductsAccountsOnRemoveModal';

type HandleRemoveProps = {
  legalEntityToReplaceId?: LegalEntity['id'];
  replaceProductsAccounts?: ReplaceProductsAccountsForm;
};

type LegalEntityRemoveModalProps = {
  legalEntity?: LegalEntity;
  onClose: () => void;
  onGoBack: () => void;
  schoolId: string;
  initialModalType: LegalEntityRemoveModalType;
  yearId: string;
};

export const LegalEntityRemoveModal: FC<LegalEntityRemoveModalProps> = ({
  legalEntity,
  onGoBack,
  schoolId,
  onClose,
  initialModalType,
  yearId,
}) => {
  const { $t } = useIntl();

  const {
    isRemoving,
    legalEntityToReplace,
    setLegalEntityToReplace,
    remove,
    changeRemoveModalType,
    removeModalType,
  } = useLegalEntity();

  useEffect(() => {
    if (initialModalType) {
      changeRemoveModalType(initialModalType);
    }
  }, [changeRemoveModalType, initialModalType]);

  const { data: currentSchool } = useGetSchoolQuery(schoolId, {
    enabled: !!schoolId,
  });

  //TR-6076 Full delete/archive flow will be available in future
  // const { data } = useGetBillingCountsQuery(schoolId, {
  //   enabled: !!schoolId,
  // });
  // const totalActiveLegalEntities = data?.legal_entities.active ?? 0;
  // const hasMultipleLegalEntities = totalActiveLegalEntities > 1;

  const handleRemove = useCallback(
    async ({ legalEntityToReplaceId, replaceProductsAccounts }: HandleRemoveProps = {}) => {
      const replaceToAccounts = replaceProductsAccounts?.replace_to_accounts?.map(
        ({ product, account }) => ({
          account_id: account.id,
          product_id: product.id,
        }),
      );

      const result = await remove({
        replace_to_accounts: replaceToAccounts,
        replace_to_id: legalEntityToReplaceId,
      });
      if (result) onClose();
    },
    [onClose, remove],
  );

  const renderContent = () => {
    if (!legalEntity) return null;

    switch (removeModalType) {
      case 'remove-start':
        return (
          <LegalEntityStartRemoveModal
            legalEntityName={`"${legalEntity.name}"`}
            canDelete={legalEntity.can_be_deleted}
            onViewList={
              legalEntity.has_products ? () => changeRemoveModalType('products-list') : undefined
            }
            onReplace={() => changeRemoveModalType('replace-legal-entity')}
            onRemove={() => changeRemoveModalType('remove-confirm')}
            onClose={onGoBack}
          />
        );
      case 'remove-confirm':
        return (
          <LegalEntityRemoveConfirmationModal
            legalEntityName={`"${legalEntity.name}"`}
            canDelete={legalEntity.can_be_deleted}
            onCancel={onGoBack}
            onConfirm={handleRemove}
            isRemoving={isRemoving}
            //TR-6076 Full delete/archive flow will be available in future
            // content={
            //   <Stack gap={2.5}>
            //     {!hasMultipleLegalEntities && (
            //       <Typography variant="h3">
            //         {$t({ id: 'legalEntities-OneLegalEntityRemove' })}
            //       </Typography>
            //     )}
            //     <Typography variant="h3">
            //       {$t({
            //         id: legalEntity.can_be_deleted
            //           ? 'legalEntities-DeleteConfirmDescription'
            //           : 'legalEntities-ArchiveConfirmDescription',
            //       })}
            //     </Typography>
            //   </Stack>
            // }
          />
        );
      case 'products-list':
        return (
          <LegalEntityProductsModal
            schoolId={schoolId}
            legalEntityId={legalEntity.id}
            canDelete={legalEntity.can_be_deleted}
            currency={legalEntity.currency ?? currentSchool?.currency}
            onGoBack={() => changeRemoveModalType('remove-start')}
            onClose={onGoBack}
            onReplace={() => changeRemoveModalType('replace-legal-entity')}
            onRemove={() => changeRemoveModalType('remove-confirm')}
            yearId={yearId}
            title={
              <>
                <Typography variant="h2">
                  {$t({ id: 'legalEntities-ReplaceProductsTitle' })}
                </Typography>
                <Typography color="text.primary">{legalEntity.name}</Typography>
              </>
            }
          />
        );
      case 'replace-legal-entity':
        return (
          <LegalEntityReplaceModal
            legalEntityName={`"${legalEntity.name}"`}
            schoolId={schoolId}
            canDelete={legalEntity.can_be_deleted}
            legalEntityId={legalEntity.id}
            onClose={onGoBack}
            onReplace={(entity) => {
              setLegalEntityToReplace(entity);
              if (entity.accounts.length > 1) {
                changeRemoveModalType('replace-accounts');
                return;
              }
              handleRemove({ legalEntityToReplaceId: entity.id });
            }}
            onRemove={() => changeRemoveModalType('remove-confirm')}
            isRemoving={isRemoving}
          />
        );
      case 'replace-accounts':
        if (!legalEntityToReplace) return null;
        return (
          <ReplaceProductsAccountsOnRemoveModal
            schoolId={schoolId}
            accounts={legalEntityToReplace.accounts}
            legalEntityId={legalEntity.id}
            canDelete={legalEntity.can_be_deleted}
            onReplace={(replaceProductsAccounts) =>
              handleRemove({
                legalEntityToReplaceId: legalEntityToReplace.id,
                replaceProductsAccounts,
              })
            }
            onClose={() => {
              onGoBack();
              setLegalEntityToReplace();
            }}
            onRemove={() => changeRemoveModalType('remove-confirm')}
            isRemoving={isRemoving}
            title={
              <>
                <Typography variant="h2">
                  {$t({ id: 'legalEntities-ReplaceProductsTitle' })}
                </Typography>
                <Typography color="text.primary">
                  <Stack direction="row" alignItems="center" gap={1}>
                    {legalEntity.name}
                    <Icon>
                      <ArrowRightIcon />
                    </Icon>
                    {legalEntityToReplace.name}
                  </Stack>
                </Typography>
              </>
            }
          />
        );
    }
  };

  return renderContent();
};
