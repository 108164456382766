import { Box, IconButton, Stack, styled } from '@mui/material';
import { FilterKeys, Group, SORT_DIRECTION, useGetGroupsQuery } from '@schooly/api';
import { useAuth } from '@schooly/components/authentication';
import { SearchInput } from '@schooly/components/filters';
import { useFlag } from '@schooly/hooks/use-flag';
import { useInfiniteScroll } from '@schooly/hooks/use-infinite-scroll';
import {
  CrossIcon,
  FilterIcon,
  Loading,
  ModalContent,
  PlusIcon,
  SimpleButton,
} from '@schooly/style';
import React, { FC, useCallback, useEffect, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { NoSearchResultsFound } from '../../../components/common/NoSearchResultsFound/NoSearchResultsFound';
import { ModalFooter } from '../../../components/uikit-components/Modal/Modal.styled';
import { ModalHeader } from '../../../components/uikit-components/Modal/ModalHeader';
import RoundCard from '../../../components/uikit-components/RoundCard';
import { AddGroupsFiltersPopup } from './AddGroupsFiltersPopup';
import { InlineFiltersTags } from './InlineFiltersTags';

export type GroupsQueryFilters = {
  [FilterKeys.AgeGroup]?: string[];
  [FilterKeys.StaffHouse]?: string[];
  [FilterKeys.StudentHouse]?: string[];
  [FilterKeys.StaffStatus]?: string[];
  [FilterKeys.StudentStatus]?: string[];
  [FilterKeys.OnlyTutorGroups]?: string[];
  [FilterKeys.Subject]?: string[];
};

type AddGroupsExternalSidebarProps = {
  selectedGroupIds?: string[];
  date?: string;
  onSelectGroupIds: (v: string[]) => void;
};

const PAGE_SIZE = 30;

const initialFiltersState: GroupsQueryFilters = {
  [FilterKeys.StaffHouse]: [],
  [FilterKeys.StudentHouse]: [],
  [FilterKeys.AgeGroup]: [],
  [FilterKeys.StaffStatus]: [],
  [FilterKeys.StudentStatus]: [],
  [FilterKeys.Subject]: [],
  [FilterKeys.OnlyTutorGroups]: ['1'],
};

export const AddGroupsExternalSidebar: FC<AddGroupsExternalSidebarProps> = ({
  selectedGroupIds,
  date,
  onSelectGroupIds,
}) => {
  const { $t } = useIntl();
  const { schoolId = '' } = useAuth();
  const [filtersModalVisible, openFilterModal, closeFiltersModal] = useFlag(false);
  const { data, isLoading, params, isFetchingNextPage, hasNextPage, fetchNextPage, setParams } =
    useGetGroupsQuery(
      {
        schoolId,
        pageSize: PAGE_SIZE,
        singleDate: date,
        sort: { columnTextId: 'name', direction: SORT_DIRECTION.ASC },
        filters: initialFiltersState,
      },
      { refetchOnMount: 'always', enabled: !!(date && schoolId) },
    );

  useEffect(() => {
    if (!date) return;

    setParams((p) => ({ ...p, singleDate: date }));
  }, [date, setParams]);

  const handleSetFiltersQuery = useCallback(
    (query: string) => {
      setParams((p) => ({ ...p, query }));
    },
    [setParams],
  );

  const handleSetFilters = useCallback(
    (filters: GroupsQueryFilters) => {
      setParams((p) => ({ ...p, filters }));
    },
    [setParams],
  );

  const handleResetFilters = useCallback(() => {
    setParams((p) => ({ ...p, filters: {} }));
  }, [setParams]);

  const loaderRef = useInfiniteScroll(isLoading || isFetchingNextPage, fetchNextPage);

  const groups = useMemo(
    () =>
      data?.pages.reduce<Group[]>(
        (prev, curr) => [...prev, ...curr.results.filter((g) => !selectedGroupIds?.includes(g.id))],
        [],
      ) ?? [],
    [data?.pages, selectedGroupIds],
  );

  const onCardClick = useCallback(
    (group: Group) => {
      onSelectGroupIds([group.id]);
    },
    [onSelectGroupIds],
  );

  const handleSelectAll = useCallback(() => {
    onSelectGroupIds(groups.map((g) => g.id));
  }, [groups, onSelectGroupIds]);

  const hasFiltersApplied = Object.keys(initialFiltersState).some(
    (key) => !!params.filters[key as keyof typeof initialFiltersState]?.length,
  );

  const renderContent = () => {
    if (isLoading) {
      return <Loading />;
    }

    if (!groups?.length) return <NoSearchResultsFound type="small" />;

    return (
      <Box>
        {groups?.map((group) => (
          <RoundCard
            onClick={onCardClick}
            item={group}
            label={group.subject?.archived ? undefined : group.subject?.name}
            count={group.student_count}
            withTooltip
            name={group?.name}
            generateHref={(id) => `/groups/${id}`}
            key={group.id}
          />
        ))}

        {hasNextPage && (
          <>
            {isFetchingNextPage && (
              <Box sx={{ pt: 2 }}>
                <Loading />
              </Box>
            )}
            <div ref={loaderRef} />
          </>
        )}
      </Box>
    );
  };

  return (
    <Stack sx={{ height: '100%' }}>
      <ModalHeader title={$t({ id: 'assessments-Groups' })} active />
      <Box px={2.5} pt={2.5}>
        <Stack gap={1}>
          <Stack justifyContent="center" position="relative">
            <SearchInput
              placeholder={$t(
                { id: 'assessments-SearchAmongCount' },
                {
                  assessmentsPlural: $t({ id: 'assessments-Groups' }).toLowerCase(),
                },
              )}
              onChangeText={handleSetFiltersQuery}
              value={params.query || ''}
              canClear={false}
              InputProps={{
                endAdornment: (
                  <Stack pl={0.75} gap={0.5} pr={1} flexDirection="row">
                    {!!params.query && (
                      <IconButton
                        sx={(theme) => ({ '&:hover': { color: theme.palette.text.primary } })}
                        inverse
                        onClick={() => handleSetFiltersQuery('')}
                      >
                        <CrossIcon />
                      </IconButton>
                    )}
                    <FilterButton onClick={openFilterModal} hasFiltersApplied={hasFiltersApplied}>
                      <FilterIcon />
                    </FilterButton>
                  </Stack>
                ),
              }}
            />
          </Stack>
          <InlineFiltersTags
            onResetFilters={handleResetFilters}
            onOpenFilters={openFilterModal}
            filters={params.filters}
          />
        </Stack>
        {filtersModalVisible && (
          <AddGroupsFiltersPopup
            onClose={closeFiltersModal}
            defaultFilters={initialFiltersState}
            onSetFilters={handleSetFilters}
            filters={params.filters}
          />
        )}
      </Box>
      <ModalContent active>{renderContent()}</ModalContent>
      {Boolean(groups?.length) && (
        <ModalFooter sx={{ justifyContent: 'flex-start', height: 60 }} active>
          <SimpleButton startIcon={<PlusIcon />} onClick={handleSelectAll}>
            <FormattedMessage id="groups-AddAllGroups" values={{ count: groups?.length }} />
          </SimpleButton>
        </ModalFooter>
      )}
    </Stack>
  );
};

const FilterButton = styled(IconButton)<{ hasFiltersApplied: boolean }>(
  ({ theme, hasFiltersApplied }) => ({
    cursor: 'pointer',
    color: hasFiltersApplied ? undefined : 'common.grey',
    '&:hover': {
      color: 'unset',
    },

    '&:after': hasFiltersApplied
      ? {
          content: "''",
          display: 'block',
          width: '0.4rem',
          height: '0.4rem',
          borderRadius: '50%',
          background: theme.palette.success.main,
          border: '1px solid white',
          position: 'absolute',
          right: '0',
          top: -2,
        }
      : undefined,
  }),
);
