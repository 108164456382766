import {
  ConductEntry,
  DATE_FORMAT_FULL_MONTH_FNS,
  DEFAULT_DATE_FORMAT,
  FilterKeys,
  GetConductEntriesQueryFilters,
  useGetConductEntriesQuery,
} from '@schooly/api';
import { MONTH_NONE } from '@schooly/constants';
import { newDateTimezoneOffset } from '@schooly/utils/date';
import { format } from 'date-fns';
import { useCallback, useMemo } from 'react';

import { useSchool } from '../../hooks/useSchool';
import useSchoolYears from '../../hooks/useSchoolYears';
import { useGroupDates } from '../../pages/Groups/ManageGroupModal/useGroupDates';
import { useGroup } from '../groups/useGroup';

export const useConductForGroup = () => {
  const { group } = useGroup();
  const { schoolId } = useSchool();
  const { schoolYears } = useSchoolYears();
  const { getValidityDates } = useGroupDates({ schoolYears, dateFormat: DEFAULT_DATE_FORMAT });
  const validityDates = group && getValidityDates(group.validity);

  const { data, setParams, params, hasNextPage, fetchNextPage, isLoading, isFetchingNextPage } =
    useGetConductEntriesQuery(
      {
        schoolId: schoolId || '',
        filters: {
          [FilterKeys.ConductStatus]: [],
          [FilterKeys.ConductType]: [],
          [FilterKeys.Date]: validityDates ? [validityDates.from, validityDates.to] : [],
          [FilterKeys.Group]: [group?.id || ''],
        },
        query: '',
      },
      { refetchOnMount: 'always' },
    );

  const setSearchText = useCallback(
    (query: string) => setParams((p) => ({ ...p, query })),
    [setParams],
  );

  const entriesByMonth = useMemo(() => {
    return (
      data?.pages
        .reduce<ConductEntry[]>((prev, curr) => {
          prev.push(...curr.results);
          return prev;
        }, [])
        ?.reduce<Record<string, ConductEntry[]>>((prev, entry) => {
          const month = entry.date
            ? format(newDateTimezoneOffset(entry.date), DATE_FORMAT_FULL_MONTH_FNS).toUpperCase()
            : MONTH_NONE;

          if (!prev[month]) {
            prev[month] = [];
          }

          prev[month].push(entry);

          return prev;
        }, {}) ?? {}
    );
  }, [data]);

  const setFilters = useCallback(
    (filters: GetConductEntriesQueryFilters) => {
      setParams((params) => ({ ...params, filters }));
    },
    [setParams],
  );

  return {
    group,
    filters: params.filters,
    setFilters,
    searchText: params.query || '',
    setSearchText,
    entriesByMonth,
    totalCount: data?.pages[0].count,
    hasNextPage,
    fetchNextPage,
    isLoading,
    isFetchingNextPage,
  };
};
