import { Button, IconButton, Popover, Stack } from '@mui/material';
import {
  ConductEntriesRequest,
  GetConductEntriesQueryFilters,
  GetConductEntriesQuerySort,
  useExportConductForPage,
} from '@schooly/api';
import { useAuth } from '@schooly/components/authentication';
import { useNotifications } from '@schooly/components/notifications';
import { useFlag } from '@schooly/hooks/use-flag';
import { CheckIcon, DownloadIcon, Spin } from '@schooly/style';
import { IntlError } from '@schooly/utils/intl-error';
import { FC, PropsWithChildren, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { downloadFile } from '../../utils/downloadFile';

export type ConductExportProps = {
  params: Omit<ConductEntriesRequest, 'filters' | 'sort'> & {
    filters: GetConductEntriesQueryFilters;
    sort?: GetConductEntriesQuerySort;
  };
  onSuccess?: () => void;
};

const DELAY_BEFORE_CHANGE_STATUS = 3000;

export const ConductExport: FC<PropsWithChildren<ConductExportProps>> = ({
  params,
  onSuccess,
  children,
}) => {
  const { schoolId } = useAuth();
  const { showError } = useNotifications();
  const [isPopoverOpened, showPopover, hidePopover] = useFlag();

  const ref = useRef<HTMLButtonElement>(null);

  const [isSuccess, setIsSuccess] = useState(false);

  const { mutateAsync: exportConductForPage, isLoading } = useExportConductForPage(params);

  const handleExportClick = useCallback(async () => {
    if (!schoolId) {
      return;
    }

    hidePopover();

    await exportConductForPage(params, {
      onSuccess: (data) => {
        try {
          downloadFile(data, `conducts.csv`);

          setIsSuccess(true);
          onSuccess?.();
        } catch (err) {
          showError(err as IntlError);
        }
      },
      onError: showError,
    });
  }, [showError, exportConductForPage, hidePopover, onSuccess, params, schoolId]);

  useEffect(() => {
    if (isSuccess) {
      setTimeout(() => setIsSuccess(false), DELAY_BEFORE_CHANGE_STATUS);
    }
  }, [isSuccess]);

  const exportIcon = useMemo(() => {
    if (isLoading) {
      return <Spin />;
    }

    if (isSuccess) {
      return <CheckIcon />;
    }

    return <DownloadIcon />;
  }, [isLoading, isSuccess]);

  return (
    <>
      <IconButton
        sx={{ '&&': { color: isLoading || isSuccess ? 'primary.main' : undefined } }}
        onClick={isLoading ? undefined : showPopover}
        ref={ref}
      >
        {exportIcon}
      </IconButton>

      <Popover
        open={isPopoverOpened}
        anchorEl={ref.current}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        onClose={hidePopover}
        PaperProps={{
          sx: { p: 2, width: 300 },
        }}
      >
        <Stack>
          <Button variant="outlined" onClick={handleExportClick} disabled={!schoolId} fullWidth>
            {children}
          </Button>
        </Stack>
      </Popover>
    </>
  );
};
