import { useMutation, useQuery } from '@tanstack/react-query';

import {
  Application,
  ApplicationsByChildrenIds,
  ApplicationsByChildrenIdsRequest,
  ApplicationShort,
  ApplicationStatus,
} from './apiTypes/applications';
import {
  ApplicationConvertRequest,
  ApplicationCreateRequest,
  ApplicationRejectRequest,
  ApplicationUpdateNotesRequest,
  ApplicationUpdateRequest,
  ParentApplicationCreateRequest,
  ParentApplicationCreateResponse,
} from './apiTypes/endpoints/applications';
import { ApiError } from './apiTypes/misc';
import { RQUseMutationOptions, RQUseMutationResult, RQUseQueryOptions } from './apiTypes/query';
import { School } from './apiTypes/schools';
import * as api from './requests';

const APPLICATIONS_URL = '/application';

type GetApplicationsRequest = {
  school_id: School['id'];
  status: ApplicationStatus;
};

export const GET_APPLICATIONS_QUERY = `${APPLICATIONS_URL}/GET_APPLICATIONS_QUERY`;

export function getApplications({
  school_id,
  status,
}: GetApplicationsRequest): Promise<ApplicationShort[]> {
  return api.get(`${APPLICATIONS_URL}/list?school_id=${school_id}&status=${status}`);
}

export const useGetApplicationsQuery = (
  data: GetApplicationsRequest,
  options?: RQUseQueryOptions<ApplicationShort[]>,
) => {
  return useQuery<ApplicationShort[], ApiError>(
    [GET_APPLICATIONS_QUERY, data],
    () => getApplications(data),
    options,
  );
};

export function getApplication(id: Application['id']): Promise<Application> {
  return api.get(`${APPLICATIONS_URL}/${id}`);
}

export function getApplicationsByStudentIds({
  children_ids,
  school_user_relation_id,
}: ApplicationsByChildrenIdsRequest): Promise<ApplicationsByChildrenIds[]> {
  return api.get(`${APPLICATIONS_URL}/for-children`, {
    params: {
      children_ids,
      school_user_relation_id,
    },
  });
}

export const GET_APPLICATIONS_BY_STUDENT_IDS_QUERY = `${APPLICATIONS_URL}/GET_APPLICATIONS_BY_STUDENT_IDS_QUERY`;

export const useGetApplicationsByStudentIdsQuery = (
  data: ApplicationsByChildrenIdsRequest,
  options?: RQUseQueryOptions<ApplicationsByChildrenIds[]>,
) => {
  return useQuery<ApplicationsByChildrenIds[], ApiError>(
    [GET_APPLICATIONS_BY_STUDENT_IDS_QUERY, data],
    () => getApplicationsByStudentIds(data),
    options,
  );
};

export function getApplicationStatuses(): Promise<Array<ApplicationStatus>> {
  return api.get(`${APPLICATIONS_URL}/statuses`);
}

export function createApplication({ schoolId, ...params }: ApplicationCreateRequest): Promise<{
  success: 'string';
  application_id: Application['id'];
}> {
  return api.post(`${APPLICATIONS_URL}/create/${schoolId}`, params);
}

export function createParentApplication({
  schoolId,
  ...params
}: ParentApplicationCreateRequest): Promise<ParentApplicationCreateResponse> {
  return api.post(`${APPLICATIONS_URL}/create/${schoolId}/public`, params);
}

export const CREATE_PARENT_APPLICATION_MUTATION = `${APPLICATIONS_URL}/CREATE_PARENT_APPLICATION_MUTATION`;

export const useCreateParentApplicationMutation = (
  options?: RQUseMutationOptions<ParentApplicationCreateResponse, ParentApplicationCreateRequest>,
): RQUseMutationResult<ParentApplicationCreateResponse, ParentApplicationCreateRequest> => {
  return useMutation([CREATE_PARENT_APPLICATION_MUTATION], createParentApplication, {
    ...options,
  });
};

export function updateApplication({ id, ...params }: ApplicationUpdateRequest): Promise<{
  success: 'string';
  application_id: Application['id'];
}> {
  return api.patch(`${APPLICATIONS_URL}/${id}`, params);
}

export function updateApplicationNotes({ id, ...params }: ApplicationUpdateNotesRequest): Promise<{
  success: 'string';
}> {
  return api.patch(`${APPLICATIONS_URL}/${id}/notes`, params);
}

export function convertApplication({
  id,
  ...params
}: ApplicationConvertRequest): Promise<{ status: 'success' }> {
  return api.patch(`${APPLICATIONS_URL}/${id}/convert`, params);
}

export function rejectApplication({
  id,
  ...data
}: ApplicationRejectRequest): Promise<{ status: 'success' }> {
  return api.patch(`${APPLICATIONS_URL}/${id}/reject`, data);
}
