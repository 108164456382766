import { Button, Stack, Typography } from '@mui/material';
import { DialogProps } from '@mui/material/Dialog/Dialog';
import { Loading, ModalConfirm, ModalPanel, Spin } from '@schooly/style';
import React, { PropsWithChildren, ReactNode, useCallback } from 'react';
import { FormattedMessage } from 'react-intl';

interface ConfirmationDialogProps extends PropsWithChildren<Omit<DialogProps, 'open'>> {
  isOpen: boolean;
  isLoading?: boolean;
  isContentLoading?: boolean;
  isDestructive?: boolean;
  shouldCloseAutomatically?: boolean;
  className?: string;
  titleTextId?: string;
  textValues?: Record<string, React.ReactNode>;
  confirmTextId?: string;
  cancelTextId?: string;
  confirmIcon?: JSX.Element;
  onClose: () => void;
  onConfirm?: () => void;
  message?: any;
  onlyCancelButton?: boolean;
  cancelButtonStartIcon?: React.ReactNode;
  content?: ReactNode;
}

export const ConfirmationDialog: React.FC<ConfirmationDialogProps> = ({
  isOpen,
  isLoading,
  isDestructive,
  shouldCloseAutomatically,
  className,
  titleTextId,
  textValues,
  confirmTextId = 'action-Confirm',
  cancelTextId = 'action-Cancel',
  onClose,
  onConfirm = () => {},
  children,
  message,
  onlyCancelButton,
  cancelButtonStartIcon,
  isContentLoading,
  content,
  ...props
}) => {
  const handleConfirm = useCallback(() => {
    if (shouldCloseAutomatically) {
      onClose();
    }

    onConfirm();
  }, [shouldCloseAutomatically, onClose, onConfirm]);

  return (
    <ModalConfirm open={isOpen} onClose={onClose} PaperProps={{ className }} fullWidth {...props}>
      <ModalPanel active>
        {isContentLoading ? (
          <Loading />
        ) : (
          <>
            {(titleTextId || message) && (
              <Typography variant="h1">
                {titleTextId ? <FormattedMessage id={titleTextId} values={textValues} /> : message}
              </Typography>
            )}
            {content}
          </>
        )}
      </ModalPanel>
      {children}
      <ModalPanel
        component={Stack}
        sx={{
          flexDirection: 'row',
          justifyContent: 'center',
          gap: 1,
          pt: 0,
          '& .MuiButton-root': { flex: '1 1 50%' },
        }}
        active
      >
        <Button
          variant={onlyCancelButton ? 'contained' : 'outlined'}
          onClick={onClose}
          disabled={isLoading}
          startIcon={cancelButtonStartIcon}
          data-cy="popup-cancel"
        >
          <FormattedMessage id={cancelTextId} />
        </Button>
        {!onlyCancelButton && (
          <Button
            color={isDestructive ? 'error' : 'primary'}
            onClick={handleConfirm}
            disabled={isLoading}
            startIcon={isLoading ? <Spin /> : undefined}
            data-cy="popup-confirm"
          >
            <FormattedMessage id={confirmTextId} />
          </Button>
        )}
      </ModalPanel>
    </ModalConfirm>
  );
};

export default ConfirmationDialog;
