import {
  Button,
  ClickAwayListener,
  Icon,
  MenuItem,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import {
  ApplicationStatus,
  CustomField,
  SHORT_FORMATTED_DATE_FORMAT_FNS,
  SyncUser,
  useGetApplicationsByStudentIdsQuery,
  UserType,
} from '@schooly/api';
import { useAuth } from '@schooly/components/authentication';
import { useFlag } from '@schooly/hooks/use-flag';
import { CancelIcon, ConvertedIcon, PlusIcon, PublishIcon, theme } from '@schooly/style';
import { Loading } from '@schooly/style';
import { format } from 'date-fns';
import React, { FC, PropsWithChildren, ReactNode, useCallback, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';

import { useProfile } from '../../../context/profile/useProfile';
import { useCustomFields } from '../../../hooks/useCustomFields';
import PreferNameProperty from '../ProfileModalHeader/PreferNameProperty';
import AddressProperty from '../properties/AddressProperty';
import { CustomFieldProperty } from '../properties/customFields/CustomFieldProperty';
import DateProperty from '../properties/DateProperty';
import EmailProperty from '../properties/EmailProperty';
import GenderProperty from '../properties/GenderProperty';
import LanguageProperty from '../properties/LanguageProperty';
import MedicalProperty from '../properties/MedicalProperty';
import MembershipNumberProperty from '../properties/MembershipNumberProperty';
import NationalityProperty from '../properties/NationalityProperty';
import PhoneNumberProperty from '../properties/PhoneNumberProperty';

export const ProfileModalAbout: FC = () => {
  const { formatMessage } = useIntl();
  const { permissions } = useAuth();

  const { user, userType, schoolMembership, isSchoolUser, canEditProfile, customFieldValues } =
    useProfile();
  const { customFieldsByUserRole, fetching: fetchingCustomFields } = useCustomFields();

  const currentRoleCustomFields = useMemo(
    () => customFieldsByUserRole?.[schoolMembership!.role],
    [customFieldsByUserRole, schoolMembership],
  );

  const currentRoleCustomFieldsFilled = useMemo(
    () =>
      currentRoleCustomFields?.filter((field) =>
        customFieldValues?.some((value) => value.custom_field_id === field.id),
      ),
    [currentRoleCustomFields, customFieldValues],
  );

  const currentRoleCustomFieldsEmpty = useMemo(
    () =>
      currentRoleCustomFields?.filter(
        (field) => !currentRoleCustomFieldsFilled?.some((value) => value.id === field.id),
      ),
    [currentRoleCustomFields, currentRoleCustomFieldsFilled],
  );

  const showAddInformationHeaderButton = useMemo(
    () => !!currentRoleCustomFieldsEmpty?.length && !!currentRoleCustomFieldsFilled?.length,
    [currentRoleCustomFieldsEmpty, currentRoleCustomFieldsFilled],
  );

  const showAddInformationEmptyButton = useMemo(
    () => !!currentRoleCustomFieldsEmpty?.length && !currentRoleCustomFieldsFilled?.length,
    [currentRoleCustomFieldsEmpty, currentRoleCustomFieldsFilled],
  );

  const canViewApplications = useMemo(() => {
    return (
      permissions.includes('application_viewer') || permissions.includes('application_manager')
    );
  }, [permissions]);

  const userTypeAdditionalInfoMessage = useMemo(() => {
    switch (userType) {
      case 'student':
        return formatMessage({ id: 'profile-NoAdditionalInformation-student' });
      case 'parent':
        return formatMessage({ id: 'profile-NoAdditionalInformation-parent' });
      case 'staff':
        return formatMessage({ id: 'profile-NoAdditionalInformation-staff' });
      default:
        return formatMessage({ id: 'profile-NoAdditionalInformation-student' });
    }
  }, [userType, formatMessage]);

  if (!user || !userType) {
    return null;
  }

  const canRenderAddPhoneNumberButton =
    !user.other_telephones || (canEditProfile && user.other_telephones.length < 3);

  return fetchingCustomFields ? (
    <Loading />
  ) : (
    <>
      <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'} mb={2.5}>
        <Typography variant="h2">
          <FormattedMessage id="profile-About" />
        </Typography>
        {(userType === 'student' || userType === 'child') && canViewApplications && (
          <ViewApplicationButton user={user} userType={userType} />
        )}
      </Stack>

      <div className="cards-wrapper section">
        {(userType === 'student' || userType === 'child') && <PreferNameProperty />}
        <DateProperty />
        <GenderProperty />
        <NationalityProperty />
        <LanguageProperty value={user.language} />
        {typeof user.language === 'number' && (
          <LanguageProperty value={user.other_languages_spoken} multiple />
        )}
      </div>
      {isSchoolUser && (
        <>
          <h4>
            <FormattedMessage id="peopleDetail-SchoolInformation" />
          </h4>
          <div className="cards-wrapper section">
            <MembershipNumberProperty />
          </div>
        </>
      )}
      <h4>
        <FormattedMessage id="peopleDetail-ContactInformation" />
      </h4>
      <div className="cards-wrapper section">
        <PhoneNumberProperty value={user.telephone} />
        {user?.other_telephones?.map((telephone) => (
          <PhoneNumberProperty key={telephone} value={telephone} hideSuggestedChanges />
        ))}

        {user.telephone && canRenderAddPhoneNumberButton && (
          <PhoneNumberProperty hideSuggestedChanges />
        )}
        <EmailProperty />
        {!['student', 'child'].includes(userType) && <AddressProperty />}
      </div>
      {userType === 'student' && (
        <>
          <h4>
            <FormattedMessage id="peopleDetail-MedicalNeeds" />
          </h4>
          <div className="cards-wrapper section">
            <MedicalProperty />
          </div>
        </>
      )}

      {schoolMembership && !!currentRoleCustomFields?.length && (
        <>
          <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'} mb={1}>
            <Typography variant="h4" sx={{ mb: '0 !important' }}>
              {formatMessage({ id: 'peopleDetail-AdditionalInformation' })}
            </Typography>

            {showAddInformationHeaderButton && (
              <AddInformationButton customFields={currentRoleCustomFieldsEmpty ?? []}>
                <Stack direction="row" alignItems="center" gap={1} sx={{ cursor: 'pointer' }}>
                  <Icon>
                    <PlusIcon />
                  </Icon>

                  <Typography variant="h3">
                    {formatMessage({ id: 'profile-AddInformation' })}
                  </Typography>
                </Stack>
              </AddInformationButton>
            )}
          </Stack>

          {!!currentRoleCustomFieldsFilled?.length && (
            <div className="cards-wrapper section custom-fields">
              {currentRoleCustomFieldsFilled.map((field) => (
                <CustomFieldProperty key={field.id} {...field} />
              ))}
            </div>
          )}

          {showAddInformationEmptyButton && (
            <div className="cards-wrapper section custom-fields">
              <Stack direction="row" justifyContent="center" alignItems="center" gap={1} p={1.25}>
                <Typography variant="h3">{userTypeAdditionalInfoMessage}</Typography>

                <AddInformationButton customFields={currentRoleCustomFieldsEmpty ?? []}>
                  <Button startIcon={<PlusIcon />} onClick={() => {}} size="small">
                    {formatMessage({ id: 'profile-AddInformation' })}
                  </Button>
                </AddInformationButton>
              </Stack>
            </div>
          )}
        </>
      )}
    </>
  );
};

type ViewApplicationButtonProps = {
  user: SyncUser;
  userType: UserType;
};

const applicationStatusIcon: { [k in ApplicationStatus]: ReactNode } = {
  open: <PublishIcon />,
  converted: <ConvertedIcon />,
  rejected: <CancelIcon />,
};

const ViewApplicationButton: FC<ViewApplicationButtonProps> = ({ user, userType }) => {
  const { formatMessage } = useIntl();
  const [opened, open, close] = useFlag();

  const relationId = user?.relation_id;

  const { data, isLoading } = useGetApplicationsByStudentIdsQuery(
    {
      children_ids: user?.user_id ?? '',
      school_user_relation_id: relationId ?? '',
    },
    {
      enabled: opened && !!user?.user_id && !!relationId && userType === 'student',
    },
  );

  const handleApplicationClick = useCallback((applicationId: string) => {
    window.open(`/applications/${applicationId}`, '_blank');
  }, []);

  const handleViewApplicationsClick = useCallback(
    async (e: React.MouseEvent<HTMLAnchorElement>) => {
      e.stopPropagation();

      open();
    },
    [open],
  );

  const applicationTypes = useMemo(
    () =>
      data?.map((app) => ({
        id: app.application_id,
        labelDate: app.date,
        icon: applicationStatusIcon[app.status],
      })) ?? [],
    [data],
  );

  const renderContent = () => {
    if (!data || isLoading) return <Loading />;

    if (!applicationTypes.length)
      return <FormattedMessage id="applications-StudentApplications-EmptyMessage" />;

    return applicationTypes.map((a, idx) => (
      <MenuItem
        key={idx}
        onClick={(e) => {
          e.stopPropagation();
          handleApplicationClick(a.id);
          close();
        }}
        sx={(theme) => ({
          m: 0,
          py: theme.spacing(0.5),
          px: theme.spacing(1.25),
          borderRadius: theme.spacing(0.5),
        })}
      >
        <Stack
          direction="row"
          gap={0.75}
          alignItems={'center'}
          justifyContent={'space-between'}
          sx={{
            '&:hover': {
              '.dateTag': {
                color: 'primary.main',
              },
            },
          }}
        >
          <Icon
            className="menuIcon"
            sx={(theme) => ({
              mr: theme.spacing(3),
            })}
          >
            {a.icon}
          </Icon>
          <Typography variant="caption">
            {formatMessage({ id: 'applications-StartingDate' })}
          </Typography>
          <Typography variant="body1" mr={3} color={theme.palette.common.grey2} className="dateTag">
            {format(new Date(a.labelDate), SHORT_FORMATTED_DATE_FORMAT_FNS)}
          </Typography>
        </Stack>
      </MenuItem>
    ));
  };

  return (
    <ClickAwayListener
      onClickAway={() => {
        if (opened) close();
      }}
    >
      <div>
        <Tooltip
          open={opened}
          disableTouchListener
          componentsProps={{
            tooltip: {
              sx: (theme) => ({
                padding: theme.spacing(1),
                maxWidth: 215,
              }),
            },
          }}
          title={renderContent()}
        >
          <Link
            onClick={handleViewApplicationsClick}
            to={{ hash: `#` }}
            replace
            className="menuIcon"
          >
            <Typography variant="h3">
              <FormattedMessage id="applications-ViewApplication" />
            </Typography>
          </Link>
        </Tooltip>
      </div>
    </ClickAwayListener>
  );
};

const AddInformationButton: FC<PropsWithChildren<{ customFields: CustomField[] }>> = ({
  customFields,
  children,
}) => {
  const [tooltipOpened, openTooltip, closeTooltip] = useFlag();
  const [modalControllerOpened, openModalController, closeModalController] = useFlag();

  const handleAddInformationClick = useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation();

      openTooltip();
    },
    [openTooltip],
  );

  return (
    <ClickAwayListener
      onClickAway={() => {
        if (tooltipOpened) {
          closeTooltip();
          closeModalController();
        }
      }}
    >
      <div>
        <Tooltip
          open={tooltipOpened}
          disableTouchListener
          componentsProps={{
            tooltip: {
              sx: (theme) => ({
                padding: theme.spacing(1),
                maxWidth: 400,
                display: modalControllerOpened ? 'none' : 'block',
                pointerEvents: modalControllerOpened ? 'none' : 'auto',
              }),
            },
          }}
          title={
            <>
              {!!customFields.length &&
                customFields.map((field, idx) => (
                  <MenuItem
                    key={idx}
                    sx={(theme) => ({
                      m: 0,
                      py: theme.spacing(0.5),
                      px: theme.spacing(1.25),
                      borderRadius: theme.spacing(0.5),
                      '.UIKit-Button__label': {
                        maxWidth: 150,
                      },
                      '.UIKit-Button__label p': {
                        fontSize: theme.typography.h3.fontSize,
                      },
                      '.UIKit-Button__content': {
                        gap: `${theme.spacing(1.5)} !important`,
                      },
                    })}
                  >
                    <CustomFieldProperty
                      key={field.id}
                      {...field}
                      onClick={() => openModalController()}
                      onClose={() => closeModalController()}
                    />
                  </MenuItem>
                ))}
            </>
          }
        >
          <div onClick={handleAddInformationClick}>{children}</div>
        </Tooltip>
      </div>
    </ClickAwayListener>
  );
};
