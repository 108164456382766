import { ProductSave } from '@schooly/api';
import { useAuth } from '@schooly/components/authentication';
import { Loading, ModalSmall } from '@schooly/style';
import { FC, useCallback, useMemo } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import AccessDenied from '../../../../components/common/AccessDenied';
import { useProduct } from '../../../../context/product/WithProduct';
import { SchoolProductCreateModalContent, ViewState } from './SchoolProductCreateModalContent';

export const SchoolProductCreateModal: FC = () => {
  const { schoolId = '' } = useAuth();
  const navigate = useNavigate();
  const close = useCallback(() => navigate('/settings/products'), [navigate]);
  const [searchParams] = useSearchParams();
  const { id, product, canCreate, isFetching, create, update, remove, isRemoving, isSaving } =
    useProduct();

  const { handleDelete, handleSave } = useMemo(() => {
    return {
      handleDelete: id ? () => remove(id).then((result) => result && close()) : undefined,
      handleSave: (product: ProductSave) => (id ? update : create)(product).then(close),
    };
  }, [close, create, id, remove, update]);

  if (isFetching) {
    return (
      <ModalSmall open onClose={close}>
        <Loading />
      </ModalSmall>
    );
  }

  if (!canCreate) {
    return (
      <ModalSmall open onClose={close}>
        <AccessDenied />
      </ModalSmall>
    );
  }

  return (
    <SchoolProductCreateModalContent
      initialState={!!id && searchParams.has(ViewState.Variants) ? ViewState.Variants : undefined}
      schoolId={schoolId}
      product={product}
      onClose={close}
      onSave={handleSave}
      onDelete={handleDelete}
      isDeleting={isRemoving}
      isSaving={isSaving}
    />
  );
};
