import { useInfiniteQuery, useMutation, useQuery } from '@tanstack/react-query';
import { CancelToken } from 'axios';
import { useState } from 'react';

// import { FiltersFiltersState } from '../context/filters/FiltersContext';
import {
  AttendanceCode,
  AttendanceEntriesForDate,
  AttendanceEntryForPeriod,
  AttendanceRegister,
  AttendanceRegisterForSchool,
  AttendanceStatistics,
} from './apiTypes/attendance';
import {
  AttendanceEntriesExportRequest,
  AttendanceStatisticsRequest,
} from './apiTypes/endpoints/attendance';
import {
  AttendanceAddEntries,
  AttendanceEntriesForDateRequest,
  AttendanceEntriesForPeriodRequest,
  AttendanceEntriesForRelationRequest,
  AttendanceEntriesForRelationResponse,
  ChangeAttendanceCodeOrderRequest,
  RemoveAttendanceCodeRequest,
  UpdateAttendanceEntryRequest,
} from './apiTypes/endpoints/attendance';
import { IColumnSort } from './apiTypes/endpoints/people';
import { ApiError, PagedResponse, SORT_DIRECTION } from './apiTypes/misc';
import {
  RQUseInfiniteQueryOptions,
  RQUseMutationOptions,
  RQUseMutationResult,
  RQUseQueryOptions,
} from './apiTypes/query';
import { FilterKeys, UserFilter } from './apiTypes/users';
import * as api from './requests';
import { removeObjectEmptyArrayValues } from './utils/removeObjectEmptyArrayValues';

const DEFAULT_PAGE_SIZE = 50;

const ATTENDANCE_URL = '/attendance';
const EXPORT_ATTENDANCE_URL = '/export/attendance';

export function getAttendanceCodes(schoolId: string): Promise<AttendanceCode[]> {
  return api.get(`${ATTENDANCE_URL}/codes/for-school/${schoolId}`);
}

export const GET_ATTENDANCE_CODES_QUERY = `${ATTENDANCE_URL}GET_ATTENDANCE_CODES__QUERY`;

export const useGetAttendanceCodesQuery = (
  schoolId: string,
  options?: RQUseQueryOptions<AttendanceCode[]>,
) => {
  return useQuery<AttendanceCode[], ApiError>(
    [GET_ATTENDANCE_CODES_QUERY, schoolId],
    () => getAttendanceCodes(schoolId),
    {
      ...options,
    },
  );
};

export function createAttendanceCode(
  schoolId: string,
  attendanceCodes: Omit<AttendanceCode, 'id'>[],
): Promise<{ success: string }> {
  return api.post(`${ATTENDANCE_URL}/codes/for-school/${schoolId}`, attendanceCodes);
}

export function editAttendanceCode({
  id: codeId,
  ...code
}: AttendanceCode): Promise<{ success: string }> {
  return api.patch(`${ATTENDANCE_URL}/codes/for-attendance/${codeId}`, code);
}

export function changeAttendanceCodesOrder({
  schoolId,
  order,
}: ChangeAttendanceCodeOrderRequest): Promise<{ success: string }> {
  return api.patch(`${ATTENDANCE_URL}/codes/order/for-school/${schoolId}`, order);
}

export function removeAttendanceCode({
  codeId,
  confirmed,
}: RemoveAttendanceCodeRequest): Promise<{ success: string; code_to_delete?: string }> {
  return api.remove(`${ATTENDANCE_URL}/codes/${codeId}?confirmed=${confirmed}`);
}

export interface AttendanceRegisterRequest {
  schoolId: string;
  groupIds?: string[];
  query?: string;
  filters?: Partial<UserFilter>;
  sort?: IColumnSort<keyof AttendanceRegisterForSchool>[];
  pageSize?: number;
  pageNumber?: number;
  token?: CancelToken;
}

export interface AttendanceRegisterUpsert {
  name: string;
  register_date: string;
  group_ids?: string[];
}

export function getAttendanceRegisters({
  schoolId,
  groupIds,
  query,
  filters,
  sort = [{ columnTextId: 'name', direction: SORT_DIRECTION.ASC }],
  pageSize = DEFAULT_PAGE_SIZE,
  pageNumber = 1,
  token,
}: AttendanceRegisterRequest): Promise<PagedResponse<AttendanceRegisterForSchool>> {
  return api.get(`${ATTENDANCE_URL}/registers-for-school/${schoolId}`, {
    params: {
      date_from: filters?.date?.[0],
      date_to: filters?.date?.[1],
      group_ids: groupIds?.join(','),
      search_query: query || undefined,
      // TODO: not supported here
      // sort_by: getSortParam(sort),
      page_size: pageSize,
      page_number: pageNumber,
      age_group_ids: filters?.age_group?.join(','),
    },
    cancelToken: token,
  });
}

export const GET_ATTENDANCE_REGISTERS_QUERY = `${ATTENDANCE_URL}GET_ATTENDANCE_REGISTERS_QUERY`;

export const ATTENDANCE_REGISTERS_FILTER_KEYS = [FilterKeys.Date, FilterKeys.AgeGroup] as const;

export type GetAttendanceRegistersQueryFilters = {
  [FilterKeys.Date]?: string[];
  [FilterKeys.AgeGroup]?: string[];
};

export type GetAttendanceRegistersQuerySort = {
  columnTextId: 'id' | 'register_date' | 'name';
  direction: SORT_DIRECTION;
};

export const useGetAttendanceRegistersQuery = (
  initialParams: Omit<AttendanceRegisterRequest, 'filters' | 'sort'> & {
    filters: GetAttendanceRegistersQueryFilters;
    sort?: GetAttendanceRegistersQuerySort;
  },
  options?: RQUseInfiniteQueryOptions<PagedResponse<AttendanceRegisterForSchool>>,
) => {
  const [params, setParams] = useState(initialParams);

  const query = useInfiniteQuery<PagedResponse<AttendanceRegisterForSchool>, ApiError>(
    [
      GET_ATTENDANCE_REGISTERS_QUERY,
      { ...params, filters: removeObjectEmptyArrayValues(params.filters) },
    ],
    ({ pageParam }) =>
      getAttendanceRegisters({
        pageNumber: pageParam,
        ...params,
        filters: removeObjectEmptyArrayValues(params.filters),
        sort: params.sort ? [params.sort] : undefined,
      }),
    {
      getNextPageParam: (lastPage) => {
        return !lastPage.total_pages || lastPage.current_page === lastPage.total_pages
          ? undefined
          : lastPage.next_page;
      },
      getPreviousPageParam: (firstPage) => {
        return firstPage.current_page ? firstPage.previous_page : undefined;
      },
      ...options,
    },
  );

  return { ...query, setParams, params };
};

export async function getAttendanceStatistics({
  schoolId,
  groupId,
  dateFrom,
  dateTo,
  token,
  ageGroups,
}: AttendanceStatisticsRequest): Promise<AttendanceStatistics> {
  return api.get(`${ATTENDANCE_URL}/registers-for-school/${schoolId}/statistics`, {
    params: {
      group_ids: groupId,
      date_from: dateFrom,
      date_to: dateTo,
      age_group_ids: ageGroups?.length ? ageGroups?.join(',') : undefined,
    },
    cancelToken: token,
  });
}

export const GET_ATTENDANCE_STATS_QUERY = `${ATTENDANCE_URL}/GET_ATTENDANCE_STATS_QUERY`;

export const useGetAttendanceStatsQuery = (
  params: AttendanceStatisticsRequest,
  options?: RQUseQueryOptions<AttendanceStatistics>,
) => {
  return useQuery<AttendanceStatistics, ApiError>(
    [GET_ATTENDANCE_STATS_QUERY, params],
    () => getAttendanceStatistics(params),
    {
      ...options,
    },
  );
};

export function getAttendanceRegister(registerId: string): Promise<AttendanceRegister> {
  return api.get(`${ATTENDANCE_URL}/register/${registerId}`);
}

export const GET_ATTENDANCE_REGISTER_QUERY = `${ATTENDANCE_URL}/GET_ATTENDANCE_REGISTER_QUERY`;

export const useGetAttendanceRegisterQuery = (
  registerId: string,
  options?: RQUseQueryOptions<AttendanceRegister>,
) => {
  return useQuery<AttendanceRegister, ApiError>(
    [GET_ATTENDANCE_REGISTER_QUERY, registerId],
    () => getAttendanceRegister(registerId),
    {
      ...options,
    },
  );
};

type CreateAttendanceRegisterParams = {
  schoolId: string;
  register: AttendanceRegisterUpsert;
};

type UpdateAttendanceRegisterParams = {
  id: string;
  register: AttendanceRegisterUpsert;
  confirmed?: boolean;
};

type DeleteAttendanceRegisterParams = {
  id: string;
  confirmed?: boolean;
};

function createAttendanceRegister(schoolId: string, params: AttendanceRegisterUpsert) {
  return api.post(`${ATTENDANCE_URL}/register-for-school/${schoolId}`, params);
}

export const useCreateAttendanceRegisterMutation = (
  options?: RQUseMutationOptions<AttendanceRegister, CreateAttendanceRegisterParams>,
): RQUseMutationResult<AttendanceRegister, CreateAttendanceRegisterParams> => {
  return useMutation(
    ({ schoolId, register }) => createAttendanceRegister(schoolId, register),
    options,
  );
};

function editAttendanceRegister(
  registerId: string,
  params: AttendanceRegisterUpsert,
  confirmed?: boolean,
) {
  return api.patch(`${ATTENDANCE_URL}/register/${registerId}?confirmed=${confirmed}`, params);
}

export const useEditAttendanceRegisterMutation = (
  options?: RQUseMutationOptions<
    { entries_to_delete?: number; success: string },
    UpdateAttendanceRegisterParams
  >,
): RQUseMutationResult<
  { entries_to_delete?: number; success: string },
  UpdateAttendanceRegisterParams
> => {
  return useMutation(
    ({ id, confirmed, register }) => editAttendanceRegister(id, register, confirmed),
    options,
  );
};

function deleteAttendanceRegister(id: string, confirmed?: boolean) {
  return api.remove(`${ATTENDANCE_URL}/register/${id}?confirmed=${confirmed}`);
}

export const useDeleteAttendanceRegisterMutation = (
  options?: RQUseMutationOptions<
    { entries_to_delete?: number; success: string },
    DeleteAttendanceRegisterParams
  >,
): RQUseMutationResult<
  { entries_to_delete?: number; success: string },
  DeleteAttendanceRegisterParams
> => {
  return useMutation(({ id, confirmed }) => deleteAttendanceRegister(id, confirmed), options);
};

export function getAttendanceEntriesForDate({
  groupId,
  date,
  query,
  token,
}: AttendanceEntriesForDateRequest): Promise<AttendanceEntriesForDate | undefined> {
  return api.get(`${ATTENDANCE_URL}/for-group/${groupId}/entries/for-date`, {
    params: {
      single_date: date,
      search_query: query || undefined,
    },
    cancelToken: token,
  });
}

export const GET_ATTENDANCE_ENTRIES_FOR_DATE_QUERY = `${ATTENDANCE_URL}GET_ATTENDANCE_ENTRIES_FOR_DATE_QUERY`;

export const useGetAttendanceEntriesForDateQuery = (
  params: AttendanceEntriesForDateRequest,
  options?: RQUseQueryOptions<AttendanceEntriesForDate | undefined>,
) => {
  return useQuery<AttendanceEntriesForDate | undefined, ApiError>(
    [GET_ATTENDANCE_ENTRIES_FOR_DATE_QUERY, params],
    () => getAttendanceEntriesForDate(params),
    {
      ...options,
    },
  );
};

export function getAttendanceEntriesForPeriod({
  groupId,
  date,
  query,
  pageSize = DEFAULT_PAGE_SIZE,
  pageNumber,
  token,
}: AttendanceEntriesForPeriodRequest): Promise<PagedResponse<AttendanceEntryForPeriod>> {
  return api.get(`${ATTENDANCE_URL}/for-group/${groupId}/entries/for-period`, {
    params: {
      date_from: date[0],
      date_to: date[1],
      search_query: query || undefined,
      page_size: pageSize,
      page_number: pageNumber,
    },
    cancelToken: token,
  });
}

export const GET_ATTENDANCE_ENTRIES_FOR_PERIOD_QUERY = `${ATTENDANCE_URL}GET_ATTENDANCE_ENTRIES_FOR_PERIOD_QUERY`;

export const useGetAttendanceEntriesForPeriodQuery = (
  initialParams: AttendanceEntriesForPeriodRequest,
  options?: RQUseInfiniteQueryOptions<PagedResponse<AttendanceEntryForPeriod>>,
) => {
  const [params, setParams] = useState(initialParams);

  const query = useInfiniteQuery<PagedResponse<AttendanceEntryForPeriod>, ApiError>(
    [GET_ATTENDANCE_ENTRIES_FOR_PERIOD_QUERY, params],
    ({ pageParam }) =>
      getAttendanceEntriesForPeriod({
        pageNumber: pageParam,
        ...params,
      }),
    {
      getNextPageParam: (lastPage) => {
        return !lastPage.total_pages || lastPage.current_page === lastPage.total_pages
          ? undefined
          : lastPage.next_page;
      },
      getPreviousPageParam: (firstPage) => {
        return firstPage.current_page ? firstPage.previous_page : undefined;
      },
      ...options,
    },
  );

  return { ...query, setParams, params };
};

export function addAttendanceEntries(groupId: string, data: AttendanceAddEntries) {
  return api.post(`${ATTENDANCE_URL}/for-group/${groupId}/entries`, data);
}

export function getAttendanceEntriesForRelation({
  relationId,
  dateFrom,
  dateTo,
  pageSize = DEFAULT_PAGE_SIZE,
  pageNumber,
  token,
}: AttendanceEntriesForRelationRequest): Promise<AttendanceEntriesForRelationResponse> {
  return api.get(`${ATTENDANCE_URL}/for-relation/${relationId}/entries`, {
    params: {
      date_from: dateFrom,
      date_to: dateTo,
      page_size: pageSize,
      page_number: pageNumber,
    },
    cancelToken: token,
  });
}

export const GET_ATTENDANCE_ENTRIES_FOR_RELATION_QUERY = `${ATTENDANCE_URL}/GET_ATTENDANCE_ENTRIES_FOR_RELATION_QUERY`;

export const useGetAttendanceEntriesForRelationQuery = (
  params: AttendanceEntriesForRelationRequest,
  options?: RQUseInfiniteQueryOptions<AttendanceEntriesForRelationResponse>,
) => {
  return useInfiniteQuery<AttendanceEntriesForRelationResponse, ApiError>(
    [GET_ATTENDANCE_ENTRIES_FOR_RELATION_QUERY, params],
    ({ pageParam }) => getAttendanceEntriesForRelation({ pageNumber: pageParam, ...params }),
    {
      getNextPageParam: (lastPage) => {
        return !lastPage.entries.total_pages ||
          lastPage.entries?.current_page === lastPage.entries?.total_pages
          ? undefined
          : lastPage.entries.next_page;
      },
      getPreviousPageParam: (firstPage) => {
        return firstPage.entries?.current_page ? firstPage.entries.previous_page : undefined;
      },
      ...options,
    },
  );
};

export function updateAttendanceEntry({
  registerId,
  relationId,
  data,
}: UpdateAttendanceEntryRequest) {
  return api.post(
    `${ATTENDANCE_URL}/entries/for-register/${registerId}/for-relation/${relationId}`,
    data,
  );
}

export function exportAttendanceRegister(registerId: string) {
  return api.get(`${EXPORT_ATTENDANCE_URL}/register/${registerId}`, {
    responseType: 'arraybuffer',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/pdf',
    },
  });
}

export const exportAttendanceForGroup = ({ groupId, date }: AttendanceEntriesExportRequest) => {
  return api.get(`${EXPORT_ATTENDANCE_URL}/for-group/${groupId}`, {
    responseType: 'arraybuffer',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/csv',
    },
    params: {
      date_from: date[0],
      date_to: date[1],
    },
  });
};

export const useExportAttendanceForGroupMutation = (
  options?: RQUseMutationOptions<ArrayBuffer, AttendanceEntriesExportRequest>,
): RQUseMutationResult<ArrayBuffer, AttendanceEntriesExportRequest> => {
  return useMutation(
    (params: AttendanceEntriesExportRequest) => exportAttendanceForGroup(params),
    options,
  );
};
