import {
  Icon,
  IconButton,
  Skeleton,
  Stack,
  TableRow,
  Theme,
  Tooltip,
  Typography,
} from '@mui/material';
import {
  PayerType,
  ProductVariantPrice,
  StudentProductSave,
  StudentProductVariant,
  StudentProductWithVariants,
} from '@schooly/api';
import { Currencies, CURRENCY_SYMBOLS } from '@schooly/constants';
import {
  Grid,
  GridBody,
  GridCell,
  GridHead,
  LockIcon,
  MinusIcon,
  TypographyWithOverflowHint,
} from '@schooly/style';
import { FC, useCallback, useMemo, useState } from 'react';
import { useFieldArray, UseFormReturn } from 'react-hook-form-lts';
import { useIntl } from 'react-intl';

import { EmptySvg } from '../../ProfileModalFeesList/EmptyListSvg';
import { DiscountInput } from './DiscountInput';
import { PayerTypeSelect } from './PayerTypeSelect';
import { ProductTypeSelect } from './ProductTypeSelect';
import { UpdateStudentProductsForm } from './StudentProductsModalContent';
import { VariantPriceSelect } from './VariantPriceSelect';

type StudentProductsModalListProps = {
  relationId: string;
  adultId: string;
  companyId: string | null;
  schoolId: string;
  form: UseFormReturn<UpdateStudentProductsForm>;
  currency?: Currencies;
  yearId: string;
};

export const StudentProductsModalList: FC<StudentProductsModalListProps> = ({
  form,
  schoolId,
  companyId,
  adultId,
  currency,
  yearId,
}) => {
  const { $t } = useIntl();
  const [payerType, setPayerType] = useState(companyId ? PayerType.Company : PayerType.Default);
  const [[missingFrequencyId, missingFrequencyForVariantId], setMissingFrequencyForProductId] =
    useState<[string, Set<string>]>(['', new Set<string>()]);

  const { remove, update } = useFieldArray({
    control: form.control,
    name: 'products',
  });

  const products = form.watch('products');

  const currencySymbol = currency ? CURRENCY_SYMBOLS[currency] : '';

  const handleUpdateProduct = useCallback(
    (index: number, productUpdate: Partial<StudentProductWithVariants>) => {
      const product = form.getValues('products')[index];

      if (!product) return;

      const shouldEvaluateMissingFrequency =
        productUpdate.frequency_id && product.frequency_id !== productUpdate.frequency_id;

      if (shouldEvaluateMissingFrequency) {
        setMissingFrequencyForProductId(([id, set]) => {
          const updatedSet = new Set(set);
          updatedSet.delete(productUpdate.variant_id || product.variant_id);
          return [id, updatedSet];
        });
      }

      update(index, { ...product, ...productUpdate });
    },
    [update, form],
  );

  const handleChangeProductsFrequencyId = useCallback(
    (id: string) => {
      const missingFrequencyForVariantId = new Set<string>();
      form.getValues('products').map((product, i) => {
        const productVariant = product.available_variants.find((v) => v.id === product.variant_id);

        if (!productVariant?.prices.some((p) => p.frequency_id === id)) {
          missingFrequencyForVariantId.add(product.variant_id);
          return null;
        }

        handleUpdateProduct(i, { frequency_id: id });
        return null;
      });

      setMissingFrequencyForProductId([id, missingFrequencyForVariantId]);
    },
    [handleUpdateProduct, form],
  );
  const handleChangeProductsPayerType = useCallback(
    (type: PayerType) => {
      form.getValues('products').map((_, i) => {
        handleUpdateProduct(i, { payer_type: type });
        return null;
      });

      setPayerType(type);
    },
    [handleUpdateProduct, form],
  );

  const availableFrequencyIds = useMemo(
    () =>
      Array.from(
        products.reduce<Set<string>>((acc, p) => {
          p.available_variants.map((v) =>
            v.prices.map((p) => {
              acc.add(p.frequency_id);
              return null;
            }),
          );
          return acc;
        }, new Set()),
      ),
    [products],
  );

  if (!products.length)
    return (
      <Stack flex={1} justifyContent="center" alignItems="center" gap={2} p={2}>
        <EmptySvg />
        <Typography textAlign="center" variant="h3" color="text.primary" maxWidth={280}>
          {$t({ id: 'profile-ThereAreNoActiveProductAssignmentsForStudent' })}
        </Typography>
      </Stack>
    );

  return (
    <Grid stickyHeader fixedLayout>
      <StudentProductsHeader
        companyId={companyId}
        adultId={adultId}
        schoolId={schoolId}
        selectedAllPayerType={payerType}
        selectedAllFrequencyId={missingFrequencyId || availableFrequencyIds[0]}
        frequencyIdOptions={availableFrequencyIds}
        onSelectFrequencyId={handleChangeProductsFrequencyId}
        onSelectPayerType={handleChangeProductsPayerType}
        yearId={yearId}
      />
      <GridBody>
        {products.map((product, i) => {
          const sameProducts = products.filter((p) => p.id === product.id);

          return (
            <StudentProductRow
              index={i}
              key={product.id + product.variant_id}
              showMissingFrequencyId={
                missingFrequencyForVariantId.has(product.variant_id)
                  ? missingFrequencyId
                  : undefined
              }
              schoolId={schoolId}
              product={product}
              otherAddedTypes={sameProducts
                .map((p) => p.type_name)
                .filter((typeName) => typeName !== product.type_name)}
              companyId={companyId}
              adultId={adultId}
              onDelete={!product.obligatory || sameProducts.length > 1 ? remove : undefined}
              onUpdate={handleUpdateProduct}
              currencySymbol={currencySymbol}
              yearId={yearId}
            />
          );
        })}
      </GridBody>
    </Grid>
  );
};

type FeesHeaderProps = {
  adultId: string;
  schoolId: string;
  companyId: string | null;
  frequencyIdOptions: string[];
  selectedAllPayerType: PayerType;
  selectedAllFrequencyId?: string;
  onSelectPayerType: (v: PayerType) => void;
  onSelectFrequencyId: (id: string) => void;
  yearId: string;
};
export const StudentProductsHeader: FC<FeesHeaderProps> = ({
  adultId,
  schoolId,
  companyId,
  frequencyIdOptions,
  selectedAllPayerType,
  selectedAllFrequencyId,
  onSelectPayerType,
  onSelectFrequencyId,
  yearId,
}) => {
  const { $t } = useIntl();
  const variantSelectDisabled = frequencyIdOptions.length < 2;

  return (
    <GridHead borderBottom>
      <GridCell width="105px">{$t({ id: 'products-ProductName' })} </GridCell>
      <GridCell>{$t({ id: 'products-Type' })}</GridCell>
      {!!companyId && (
        <GridCell width="125px" noPadding>
          <PayerTypeSelect
            shownInHead
            schoolId={schoolId}
            companyId={companyId}
            adultId={adultId}
            value={selectedAllPayerType}
            onSelect={onSelectPayerType}
            placeholder={$t({ id: 'products-SetAllTo' })}
            maxExpandedContainerWidth={300}
          />
        </GridCell>
      )}
      <GridCell width="125px" noPadding>
        <VariantPriceSelect
          shownInHead
          priceFrequencyId={selectedAllFrequencyId}
          schoolId={schoolId}
          disabled={variantSelectDisabled}
          renderRightIcon={variantSelectDisabled ? () => <></> : undefined}
          options={frequencyIdOptions.map((id) => ({ frequency_id: id, price: 0 }))}
          onSelect={(v) => onSelectFrequencyId(v.frequency_id)}
          placeholder={$t({ id: 'products-SetAllTo' })}
          maxExpandedContainerWidth={300}
          yearId={yearId}
        />
      </GridCell>
      <GridCell width="70px">{$t({ id: 'products-Price' })}</GridCell>
      <GridCell width="50px">{$t({ id: 'products-Dis' })}</GridCell>
      <GridCell width="40px" />
    </GridHead>
  );
};

type StudentProductRowProps = {
  index: number;
  schoolId: string;
  product: StudentProductWithVariants;
  adultId: string;
  companyId: string | null;
  showMissingFrequencyId?: string;
  otherAddedTypes: string[];
  onDelete?: (index: number) => void;
  onUpdate: (index: number, v: Partial<StudentProductSave>) => void;
  currencySymbol: string;
  yearId: string;
};
export const StudentProductRow: FC<StudentProductRowProps> = ({
  index,
  product,
  schoolId,
  companyId,
  adultId,
  showMissingFrequencyId,
  otherAddedTypes,
  onDelete,
  onUpdate,
  currencySymbol,
  yearId,
}) => {
  const { $t } = useIntl();

  const hoverProps = {
    sx: (theme: Theme) => ({
      '.icon-lock': {
        color: theme.palette.common.grey,
      },
      '&:hover': {
        'td.MuiTableCell-root': {
          backgroundColor: theme.palette.background.default,
          '.MuiTypography-root': {
            color: theme.palette.primary.main,
          },
          '.action-icon': {
            color: theme.palette.common.grey2,
          },
        },
      },
    }),
  };

  const {
    handleProductPayerChange,
    handleProductPriceChange,
    handleProductDiscountChange,
    handleTypeChange,
  } = useMemo(
    () => ({
      handleProductPayerChange: (type: PayerType) => {
        onUpdate(index, { payer_type: type });
      },
      handleProductPriceChange: (price: ProductVariantPrice) => {
        onUpdate(index, { frequency_id: price.frequency_id });
      },
      handleProductDiscountChange: (discount?: number) => {
        onUpdate(index, { discount_percent: discount });
      },
      handleTypeChange: (variant: StudentProductVariant) => {
        const hasSameFrequency = variant.prices.some(
          (p) => p.frequency_id === product.frequency_id,
        );

        onUpdate(index, {
          variant_id: variant.id,
          type_name: variant.type_name,
          frequency_id: hasSameFrequency ? product.frequency_id : variant.prices[0].frequency_id,
        });
      },
    }),
    [index, onUpdate, product.frequency_id],
  );

  const { price, variant } = useMemo(() => {
    const variant = product.available_variants.find((v) => v.id === product.variant_id);
    const price = variant?.prices.find((p) => p.frequency_id === product.frequency_id)?.price;

    return {
      price,
      variant,
    };
  }, [product.available_variants, product.frequency_id, product.variant_id]);

  const typeOptions = useMemo(
    () => product.available_variants.filter((v) => !otherAddedTypes.includes(v.type_name)),
    [otherAddedTypes, product.available_variants],
  );

  const skeleton = (
    <Typography>
      <Skeleton variant="text" />
    </Typography>
  );

  if (!variant)
    return (
      <TableRow>
        <GridCell>{skeleton}</GridCell>
        <GridCell>{skeleton}</GridCell>
        {!!companyId && <GridCell>{skeleton}</GridCell>}
        <GridCell>{skeleton}</GridCell>
        <GridCell>{skeleton}</GridCell>
        <GridCell>{skeleton}</GridCell>
        <GridCell>{skeleton}</GridCell>
      </TableRow>
    );

  const variantSelectDisabled = variant.prices.length < 2;

  return (
    <TableRow {...hoverProps}>
      <GridCell noVerticalPadding>
        <TypographyWithOverflowHint variant="h3" color="text.primary">
          {product.name}
        </TypographyWithOverflowHint>
      </GridCell>
      <GridCell noPadding>
        <ProductTypeSelect
          selectedTypeName={product.type_name}
          options={typeOptions}
          onSelect={handleTypeChange}
          maxExpandedContainerWidth={300}
        />
      </GridCell>
      {!!companyId && (
        <GridCell noPadding>
          <PayerTypeSelect
            schoolId={schoolId}
            companyId={companyId}
            adultId={adultId}
            value={product.payer_type}
            onSelect={handleProductPayerChange}
            maxExpandedContainerWidth={300}
          />
        </GridCell>
      )}
      <GridCell noPadding>
        <VariantPriceSelect
          schoolId={schoolId}
          options={variant.prices}
          priceFrequencyId={product.frequency_id}
          showMissingFrequencyId={!variantSelectDisabled ? showMissingFrequencyId : undefined}
          onSelect={handleProductPriceChange}
          disabled={variantSelectDisabled}
          renderRightIcon={variantSelectDisabled ? () => <></> : undefined}
          maxExpandedContainerWidth={300}
          yearId={yearId}
        />
      </GridCell>
      <GridCell noVerticalPadding>
        <Typography color="text.primary">
          {currencySymbol} {price}
        </Typography>
      </GridCell>
      <GridCell noPadding>
        <DiscountInput value={product.discount_percent} onChange={handleProductDiscountChange} />
      </GridCell>

      <GridCell
        noVerticalPadding
        onClick={
          onDelete
            ? (e) => {
                e.preventDefault();
                onDelete?.(index);
              }
            : undefined
        }
      >
        {!onDelete ? (
          <Tooltip
            title={$t({ id: 'products-ProductIsRequired' })}
            componentsProps={{
              tooltip: {
                sx: (theme) => ({
                  padding: theme.spacing(1.25),
                }),
              },
            }}
          >
            <Icon className="action-icon icon-lock">
              <LockIcon />
            </Icon>
          </Tooltip>
        ) : (
          <IconButton
            className="action-icon icon-minus"
            inverse
            sx={(theme) => ({
              '&:hover': {
                color: `${theme.palette.primary.main} !important`,
              },
            })}
          >
            <MinusIcon />
          </IconButton>
        )}
      </GridCell>
    </TableRow>
  );
};
