import { Stack, Typography } from '@mui/material';
import {
  StudentForCompany,
  useGetSchoolQuery,
  useGetStudentsForCompanyListQuery,
} from '@schooly/api';
import { useAuth } from '@schooly/components/authentication';
import { useInfiniteScroll } from '@schooly/hooks/use-infinite-scroll';
import { Counter, ModalSearch, PlusIcon, SimpleButton } from '@schooly/style';
import { useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router';

import { useCompany } from '../../../context/companies/WithCompany';
import { STUDENT_PRODUCTS_COMPANY_ID_PARAM } from '../../ProfileModal/tabs/ProfileModalPayers/StudentProductsModal/StudentProductsModal';
import { StudentProductsModalState } from '../../ProfileModal/tabs/ProfileModalPayers/StudentProductsModal/StudentProductsModalContent';
import { CompanyPreviewModalEmptyStub } from './CompanyPreviewModalEmptyStub';
import {
  CompanyPreviewStudentListSkeleton,
  CompanyPreviewStudentRow,
} from './CompanyPreviewModalStudentsGrid';

const PAGE_SIZE = 30;

export const CompanyPreviewModalStudents = () => {
  const { $t } = useIntl();
  const navigate = useNavigate();
  const { schoolId = '' } = useAuth();
  const { id: companyId, canEditCompanyStudents } = useCompany();

  const navigateToAddStudent = useCallback(
    () => navigate(`/companies/${companyId}/add-student`),
    [companyId, navigate],
  );

  const navigateToProductAssignment = (id: string) =>
    navigate(
      `/students/${id}/payers?${StudentProductsModalState.AddProducts}&${STUDENT_PRODUCTS_COMPANY_ID_PARAM}=${companyId}`,
    );

  const {
    data,
    isLoading,
    isFetching,
    setParams,
    params,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
  } = useGetStudentsForCompanyListQuery(
    {
      query: '',
      companyId,
    },
    { enabled: !!companyId, refetchOnMount: 'always' },
  );

  const loaderRef = useInfiniteScroll(isLoading || isFetchingNextPage, fetchNextPage, hasNextPage);

  const handleSetFiltersQuery = useCallback(
    (query: string) => {
      setParams((p) => ({ ...p, query }));
    },
    [setParams],
  );

  const students = useMemo(
    () =>
      data?.pages.reduce<StudentForCompany[]>((prev, curr) => [...prev, ...curr.results], []) ?? [],

    [data?.pages],
  );
  const totalStudents = data?.pages[0].count ?? 0;

  const { data: currentSchool } = useGetSchoolQuery(schoolId, {
    enabled: !!schoolId,
  });

  const isEmpty = !isFetching && !isLoading && !totalStudents;

  const renderContent = () => {
    if (isEmpty) {
      return (
        <CompanyPreviewModalEmptyStub
          emptySearchResults={!!params.query}
          onButtonClick={canEditCompanyStudents ? navigateToAddStudent : undefined}
          messageTextId={
            params.query ? 'companies-NoResultsMatching-Student' : 'companies-NoStudents'
          }
          buttonTextId="companies-AddStudent"
        />
      );
    }

    return (
      <Stack>
        {isLoading || isFetching ? (
          <CompanyPreviewStudentListSkeleton />
        ) : (
          students.map((student) => {
            return (
              <CompanyPreviewStudentRow
                student={student}
                key={student.relation.id}
                onEdit={
                  canEditCompanyStudents
                    ? () => navigateToProductAssignment(student.relation.id)
                    : undefined
                }
                currency={currentSchool?.currency}
              />
            );
          })
        )}
        {hasNextPage && (
          <>
            <div ref={loaderRef} />

            <CompanyPreviewStudentListSkeleton
              amount={Math.min(
                PAGE_SIZE,
                data ? totalStudents - data.pages.length * PAGE_SIZE : PAGE_SIZE,
              )}
            />
          </>
        )}
      </Stack>
    );
  };

  return (
    <Stack height="100%" gap={1.75} marginTop={-0.75}>
      <Stack direction="row" justifyContent="space-between" minHeight={40}>
        <Stack direction="row" alignItems="center">
          <Typography variant="h2">{$t({ id: 'companies-Students' })}</Typography>
          {!!totalStudents && <Counter>{totalStudents}</Counter>}
        </Stack>

        {!!(totalStudents || params.query) && (
          <Stack direction="row" gap={2.75}>
            <ModalSearch
              value={params.query}
              onChange={handleSetFiltersQuery}
              placeholder={$t({ id: 'people-Search' })}
            />
            {canEditCompanyStudents && (
              <SimpleButton inverse startIcon={<PlusIcon />} onClick={navigateToAddStudent}>
                {$t({ id: 'companies-AddStudent' })}
              </SimpleButton>
            )}
          </Stack>
        )}
      </Stack>

      {renderContent()}
    </Stack>
  );
};
