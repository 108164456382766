import { Stack } from '@mui/material';
import { Product, useGetProductsListQuery } from '@schooly/api';
import { Currencies, CURRENCY_SYMBOLS } from '@schooly/constants';
import {
  Grid,
  GridBody,
  ModalContent,
  ModalMain,
  SkeletonGridLoader,
  SkeletonRows,
} from '@schooly/style';
import { FC, useMemo } from 'react';

import { LegalEntityProductRow, LegalEntityProductsHeader } from './LegalEntityProductsGrid';

const PAGE_SIZE = 1;
const SKELETON_COLUMNS = 10;

export type LegalEntityProductsContentProps = {
  legalEntityId: string;
  schoolId: string;
  currency?: Currencies;
  yearId: string;
};

export const LegalEntityProductsContent: FC<LegalEntityProductsContentProps> = ({
  legalEntityId,
  schoolId,
  currency,
  yearId,
}) => {
  const { data, isLoading, isFetchingNextPage, fetchNextPage, hasNextPage } =
    useGetProductsListQuery(
      { query: '', schoolId, legalEntityIds: [legalEntityId], pageSize: PAGE_SIZE },
      { refetchOnMount: 'always' },
    );

  const total = data?.pages[0].count;
  const currencySymbol = currency ? CURRENCY_SYMBOLS[currency] : '';

  const entries = useMemo(
    () => data?.pages.reduce<Product[]>((prev, curr) => [...prev, ...curr.results], []) ?? [],

    [data?.pages],
  );

  return (
    <ModalMain sx={{ mt: 2.5 }}>
      <ModalContent active sx={{ pt: 0 }}>
        <Stack>
          <Grid
            stickyHeader
            fixedLayout
            sx={(theme) => ({
              minWidth: 900,
              [theme.breakpoints.down('md')]: {
                '@media (orientation: portrait)': {
                  marginRight: -2.5,
                },
              },
            })}
          >
            <LegalEntityProductsHeader />
            <GridBody>
              {entries?.map((entry) => (
                <LegalEntityProductRow
                  schoolId={schoolId}
                  product={entry}
                  key={entry.id}
                  currencySymbol={currencySymbol}
                  yearId={yearId}
                />
              ))}
              {isLoading && <SkeletonRows columnsCount={SKELETON_COLUMNS} amount={PAGE_SIZE} />}
              <SkeletonGridLoader
                isFetching={isLoading || isFetchingNextPage}
                fetchNextPage={fetchNextPage}
                hasNextPage={hasNextPage}
                columnsCount={SKELETON_COLUMNS}
                amount={Math.min(
                  PAGE_SIZE,
                  total && data ? total - data.pages.length * PAGE_SIZE : PAGE_SIZE,
                )}
              />
            </GridBody>
          </Grid>
        </Stack>
      </ModalContent>
    </ModalMain>
  );
};
