import { Stack } from '@mui/material';
import {
  DEFAULT_DATE_FORMAT_FNS,
  FilterKeys,
  GetParentsQueryFilters,
  GetParentsQuerySort,
  PARENT_QUERY_FILTER_KEYS,
  ParentSearchResult,
  SORT_DIRECTION,
  useGetParentsQuery,
} from '@schooly/api';
import { useAuth } from '@schooly/components/authentication';
import {
  getCombinedRowsFromParentSearchResults,
  PageHeader,
  PageHeaderSearchInput,
  StoredFilterSections,
  useFiltersStateFromSearchParams,
  useLastAppliedFiltersState,
  useSaveLastAppliedFiltersState,
  useSyncFiltersStateWithSearchParams,
} from '@schooly/components/filters';
import { MainGridNoResultsStub } from '@schooly/components/stubs';
import { GridBody, MainPageGrid, PlusIcon, SkeletonGridLoader, SkeletonRows } from '@schooly/style';
import { useSelectIds } from '@schooly/utils/bulk-actions';
import { format, startOfDay } from 'date-fns';
import { FC, useCallback, useEffect, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import AccessDenied from '../AccessDenied';
import { ParentsBulkActions } from './ParentsBulkActions';
import { ParentsFilters } from './ParentsFilters';
import { ParentRow, ParentsHeader } from './ParentsGrid';

export const PAGE_SIZE = 30;
export const SKELETON_COLS = 5;

type ParentsContentProps = {
  initialFilters?: GetParentsQueryFilters;
};

const ParentsContent: FC<ParentsContentProps> = ({ initialFilters }) => {
  const { permissions, schoolId } = useAuth();
  const navigate = useNavigate();

  const { formatMessage } = useIntl();
  const { selectedIds, toggleSelectAll, toggleSelectId, clearSelectedIds, toggleSelectedIds } =
    useSelectIds();
  const defaultFilters: GetParentsQueryFilters = useMemo(
    () => ({
      [FilterKeys.Date]: [format(startOfDay(new Date()), DEFAULT_DATE_FORMAT_FNS)],
    }),
    [],
  );
  const { lastAppliedFilter } = useLastAppliedFiltersState({
    type: StoredFilterSections.Parents,
    filterKeys: PARENT_QUERY_FILTER_KEYS,
    schoolId: schoolId || '',
  });

  const initialFiltersState = useFiltersStateFromSearchParams({
    filterKeys: PARENT_QUERY_FILTER_KEYS,
    defaultFilters,
    initialFilters,
  });

  const defaultUserFilters = useMemo(() => {
    return { ...defaultFilters, ...initialFilters };
  }, [defaultFilters, initialFilters]);

  const {
    data,
    hasNextPage,
    isFetchingNextPage,
    isLoading,
    params,
    error,
    setParams,
    fetchNextPage,
  } = useGetParentsQuery(
    {
      query: '',
      schoolId: schoolId || '',
      filters: lastAppliedFilter ?? initialFiltersState,
      pageSize: PAGE_SIZE,
      sort: { columnTextId: 'last_name', direction: SORT_DIRECTION.ASC },
    },
    { refetchOnMount: 'always' },
  );

  useEffect(clearSelectedIds, [params.filters, params.query, clearSelectedIds, params.sort]);

  useSyncFiltersStateWithSearchParams({
    pathname: '/parents',
    filters: params.filters,
  });

  useSaveLastAppliedFiltersState({
    type: StoredFilterSections.Parents,
    filters: params.filters,
    schoolId: schoolId || '',
  });

  const onAddParentClick = useCallback(() => {
    navigate('/parents/new');
  }, [navigate]);

  const handleChangeSort = useCallback(
    (sort: GetParentsQuerySort) => {
      setParams((p) => ({
        ...p,
        sort,
      }));
    },
    [setParams],
  );

  const handleSetFiltersQuery = useCallback(
    (query: string) => {
      setParams((p) => ({ ...p, query }));
    },
    [setParams],
  );

  const handleSetFilters = useCallback(
    (filters: GetParentsQueryFilters) => {
      setParams((p) => ({ ...p, filters }));
    },
    [setParams],
  );

  const entries = useMemo(
    () =>
      getCombinedRowsFromParentSearchResults(
        data?.pages.reduce(
          (prev, curr) => [...prev, ...curr.results],
          [] as ParentSearchResult[],
        ) ?? [],
      ),
    [data?.pages],
  );

  const handleSelectRow = useCallback(
    (id: string) => {
      if (selectedIds === 'all') {
        toggleSelectedIds(
          entries.reduce<string[]>((acc, next) => (next.id === id ? acc : [...acc, next.id]), []),
        );
      } else {
        toggleSelectId(id);
      }
    },
    [entries, selectedIds, toggleSelectId, toggleSelectedIds],
  );

  const total = data?.pages[0]?.count;

  const userTypePlural = formatMessage({
    id: 'userType-parent-plural',
  }).toLowerCase();

  if (!schoolId) return null;

  if (error) {
    return <AccessDenied />;
  }

  const buttonText = formatMessage({ id: 'parents-NewParent' }, { user: userTypePlural });

  return (
    <>
      <Stack gap={1}>
        <PageHeader
          pageTitleCounter={total}
          buttonText={buttonText}
          pageTitleTextId="section-Parents"
          showActionButton={permissions.includes('parent_manager')}
          buttonIcon={<PlusIcon />}
          onButtonClick={onAddParentClick}
        >
          <PageHeaderSearchInput
            value={params.query || ''}
            onChangeText={handleSetFiltersQuery}
            placeholder={formatMessage(
              { id: 'people-SearchAmongType' },
              {
                userTypePlural: userTypePlural.toLowerCase(),
              },
            )}
          />
        </PageHeader>
        <ParentsFilters
          defaultFilters={defaultFilters}
          onSetFilters={handleSetFilters}
          filters={params.filters}
          schoolId={schoolId || ''}
          defaultUserFilters={defaultUserFilters}
        />
      </Stack>
      <>
        <MainPageGrid
          mt={2}
          bottomElement={
            <ParentsBulkActions
              totalCount={total || 0}
              filters={params.filters}
              query={params.query}
              schoolId={schoolId}
              onClear={clearSelectedIds}
              selectedIds={selectedIds}
            />
          }
        >
          <ParentsHeader
            sort={params.sort}
            onChangeSort={handleChangeSort}
            onSelectAll={!!total ? toggleSelectAll : undefined}
            isSelectedAll={selectedIds === 'all'}
          />
          <GridBody>
            {entries?.map((entry) => (
              <ParentRow
                combinedParentsRow={entry}
                key={entry.key}
                onSelect={handleSelectRow}
                isSelected={selectedIds === 'all' || selectedIds.has(entry.id)}
              />
            ))}
            {isLoading && <SkeletonRows columnsCount={5} amount={PAGE_SIZE} />}
            <SkeletonGridLoader
              isFetching={isLoading || isFetchingNextPage}
              fetchNextPage={fetchNextPage}
              hasNextPage={hasNextPage}
              columnsCount={SKELETON_COLS}
              amount={Math.min(
                PAGE_SIZE,
                total && data ? total - data.pages.length * PAGE_SIZE : PAGE_SIZE,
              )}
            />
          </GridBody>
        </MainPageGrid>

        {!isLoading && !entries.length && (
          <MainGridNoResultsStub textId="parents-NoResults-title" />
        )}
      </>
    </>
  );
};

export default ParentsContent;
