import { Box, MenuItem, MenuList } from '@mui/material';
import { Assessment, Group, useGetAssessmentGroupsQuery } from '@schooly/api';
import { useInfiniteScroll } from '@schooly/hooks/use-infinite-scroll';
import { Loading } from '@schooly/style';
import { FC, useEffect, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { Link, useNavigate } from 'react-router-dom';

import HeaderFilter from '../../../components/common/HeaderFilter/HeaderFilter';
import { NoSearchResultsFound } from '../../../components/common/NoSearchResultsFound/NoSearchResultsFound';
import { LongNameWithVerticalTooltip } from '../../../components/uikit-components/LongNameWithVerticalTooltip/LongNameWithVerticalTooltip';
import { ModalSideBar } from '../../../components/uikit-components/Modal/Modal.styled';
import { getDefaultDraft } from '../../../context/filters/FiltersContext';
import { useFilters } from '../../../context/filters/useFilters';
import useAppLocation from '../../../hooks/useAppLocation';
import { useDebounce } from '../../../hooks/useDebounce';
import useQueryStringParams from '../../../hooks/useQueryStringParams';

interface AssessmentsPreviewModalSideBarProps {
  assessment?: Assessment;
}

export const AssessmentsPreviewModalSideBar: FC<AssessmentsPreviewModalSideBarProps> = ({
  assessment,
}) => {
  const { formatMessage } = useIntl();
  const navigate = useNavigate();
  const location = useAppLocation();
  const { group_id } = useQueryStringParams<'group_id'>();

  const { filters } = useFilters();

  const query = useDebounce(filters.draftQuery ?? '', 300);

  const { data, hasNextPage, fetchNextPage, isLoading, isFetching, isFetchingNextPage } =
    useGetAssessmentGroupsQuery(
      {
        assessment_id: assessment?.id ?? '',
        query,
        filters: filters.applied ?? getDefaultDraft(),
      },
      { enabled: Boolean(group_id) },
    );

  const loaderRef = useInfiniteScroll(isFetching, fetchNextPage, hasNextPage);

  const groups = useMemo<Group[]>(() => {
    return data?.pages.reduce<Group[]>((prev, curr) => [...prev, ...curr.results], []) ?? [];
  }, [data]);

  const showEmptyStub = !isLoading && !groups.length;

  const renderGroupsList = () => {
    if (isLoading) {
      return <Loading />;
    }

    if (showEmptyStub) {
      return <NoSearchResultsFound type="small" />;
    }

    return (
      <>
        {groups.map((group) => (
          <Link key={group.id} to={{ search: `?group_id=${group.id}` }} state={location.state}>
            <MenuItem selected={group.id === group_id}>
              <LongNameWithVerticalTooltip>{group.name}</LongNameWithVerticalTooltip>
            </MenuItem>
          </Link>
        ))}

        {hasNextPage && (
          <Box py={3}>
            {isFetchingNextPage && <Loading />}
            <div ref={loaderRef} />
          </Box>
        )}
      </>
    );
  };

  useEffect(() => {
    if (!group_id && assessment?.groups.length) {
      navigate({ search: `?group_id=${assessment?.groups[0].id}` }, { state: location.state });
    }
  }, [assessment?.groups, group_id, location.state, navigate]);

  return (
    <ModalSideBar
      sx={{ paddingTop: 1, overflow: 'hidden', display: 'flex', flexDirection: 'column' }}
    >
      <Box
        sx={(theme) => ({
          backgroundColor: theme.palette.background.paper,
          padding: theme.spacing(1),
          paddingTop: theme.spacing(0.5),
          maxWidth: 270,
        })}
      >
        <HeaderFilter
          searchPlaceholder={formatMessage({ id: 'people-Search' })}
          modalTitleTextId="groups-Filter-Title"
          modal
          bottomInline
          bottomInlineMax={1}
          withoutPopupMouseEvents
          isModalOpen
        />
      </Box>

      <MenuList sx={{ height: '100%', overflow: 'scroll', pt: 0 }}>{renderGroupsList()}</MenuList>
    </ModalSideBar>
  );
};
