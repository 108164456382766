import { Icon, IconButton, Stack, Tooltip } from '@mui/material';
import {
  DEFAULT_DATE_FORMAT_FNS,
  getTypedObjectKeys,
  GroupType,
  GroupWithMembers,
  SchoolProperty,
} from '@schooly/api';
import { useAuth } from '@schooly/components/authentication';
import { useConfirmationDialog } from '@schooly/components/confirmation-dialog';
import {
  SchoolPeriodsRangeSelect,
  SchoolPeriodsRangeSelectComponent,
} from '@schooly/components/filters';
import { DATE_FORMAT } from '@schooly/constants';
import {
  Attention2Icon,
  CrossIcon,
  Loading,
  LockIcon,
  MinusIcon,
  ModalSmall,
  MoreIcon,
  WithShowOnClick,
} from '@schooly/style';
import { newDateTimezoneOffset } from '@schooly/utils/date';
import { mobileDetect } from '@schooly/utils/mobile';
import { format } from 'date-fns';
import isEqual from 'lodash.isequal';
import React, { useCallback, useMemo, useRef } from 'react';
import { MessageDescriptor, useIntl } from 'react-intl';

import { ContextMenu } from '../../../components/common/ContextMenu/ContextMenu';
import { PersonCardIconButton } from '../../../components/common/PersonCard/PersonCardSelectable.styled';
import { ExtendedFieldError } from '../../../components/ui/Input/utils';
import { FormTextField } from '../../../components/uikit-components/FormTextField/FormTextField';
import { InputEndAdornmentIcon } from '../../../components/uikit-components/FormTextField/InputEndAdornment.styled';
import {
  ModalContent,
  ModalMain,
  ModalPanel,
  ModalTitle,
} from '../../../components/uikit-components/Modal/Modal.styled';
import { ModalAccessDenied } from '../../../components/uikit-components/Modal/ModalAccessDenied';
import { ModalFooterWithActions } from '../../../components/uikit-components/Modal/ModalFooterWithActions';
import { ModalHeader } from '../../../components/uikit-components/Modal/ModalHeader';
import { ModalHeaderInput } from '../../../components/uikit-components/Modal/ModalHeader.styled';
import { ModalPeopleExtensionPanel } from '../../../components/uikit-components/Modal/ModalPeopleExtensionPanel';
import { getUserTypeTextId } from '../../../helpers/misc';
import useFlag from '../../../hooks/useFlag';
import { useFormError } from '../../../hooks/useFormError';
import useSchoolYears from '../../../hooks/useSchoolYears';
import { GroupPreviewModalInfo } from '../GroupPreviewModal/GroupPreviewModalInfo';
import { DeleteHistoryDialog } from './Dialog/DeleteHistoryDialog';
import { GroupSidebarContent } from './GroupSidebarContent';
import { GroupContent } from './GroupUsersList/GroupContent';
import {
  DEFAULT_LIMITED_TO_SECTION_TEXT_IDS,
  LimitedToCategory,
} from './LimitedToSelect/LimitedToSelect';
import { useLimitedToValidation } from './LimitedToSelect/useLimitedTo';
import { getLimitedToOptions } from './LimitedToSelect/utils';
import { SubjectSelect } from './SubjectSelect/SubjectSelect';
import { AddGroupModalMode, AddGroupModalProps, useAddGroupModal } from './useAddGroupModal';

export enum AddGroupModalSection {
  Header,
  Staff,
  Students,
}

export const AddGroupModal: React.FC<AddGroupModalProps> = (props) => {
  const { $t } = useIntl();
  const { schoolId = '' } = useAuth();
  const {
    subjects,
    actions,
    form,
    groupViewerCanOnlyManageStudents,
    isGroupExpired,
    canEditGroup,
    deleteHistoryUsers,
    removeGroupMembers,
    contextActions,
    isIndividualMember,
    ...contextState
  } = useAddGroupModal({ core: true, ...props });
  const { renderError } = useFormError();

  const schoolPeriodsRangeSelectRef = useRef<SchoolPeriodsRangeSelectComponent>(null);
  const allCategories = getTypedObjectKeys(DEFAULT_LIMITED_TO_SECTION_TEXT_IDS);

  const isMobile = mobileDetect.mobile();

  const studentLimitedToOptions = getLimitedToOptions({
    categories: allCategories,
    schoolProperties:
      (contextState.schoolStudentProperties.filter((p) => 'type' in p) as SchoolProperty[]) ?? [],
    subjects: subjects ?? [],
    ageGroups: contextState.ageGroups,
    schoolLevels: contextState.schoolLevels,
  });
  const staffLimitedToOptions = getLimitedToOptions({
    categories: allCategories,
    schoolProperties:
      (contextState.schoolStaffProperties.filter((p) => 'type' in p) as SchoolProperty[]) ?? [],
    subjects: subjects ?? [],
    ageGroups: contextState.ageGroups,
    schoolLevels: contextState.schoolLevels,
  });

  const isSubjectSelected = Boolean(
    contextState?.group_type === GroupType.TutorGroup || contextState?.subject?.id,
  );

  const { studentRequiredCategories, hasStudentsLimitedTo } = useMemo(() => {
    const requiredCategories: LimitedToCategory[] = isSubjectSelected
      ? ['statuses', 'age_groups']
      : ['statuses'];
    return {
      studentRequiredCategories: requiredCategories,
      hasStudentsLimitedTo: requiredCategories.every((c) =>
        studentLimitedToOptions[c]?.some((o) =>
          contextState.limitedToStudents?.school_property_ids.includes(o.value as string),
        ),
      ),
    };
  }, [
    contextState.limitedToStudents?.school_property_ids,
    isSubjectSelected,
    studentLimitedToOptions,
  ]);

  const { staffRequiredCategories, hasStaffLimitedTo } = useMemo(() => {
    const requiredCategories: LimitedToCategory[] = ['statuses'];
    return {
      staffRequiredCategories: requiredCategories,
      hasStaffLimitedTo: requiredCategories.every((c) =>
        staffLimitedToOptions[c]?.some((o) =>
          contextState.limitedToStaff?.school_property_ids.includes(o.value as string),
        ),
      ),
    };
  }, [contextState.limitedToStaff?.school_property_ids, staffLimitedToOptions]);

  const { getConfirmation } = useConfirmationDialog();

  const { defaultValidity } = useSchoolYears();
  const { permissions } = useAuth();
  const isAdmin = permissions.includes('school_admin');
  const isStaffViewer = permissions.includes('staff_viewer');

  const isEditingLocked = !!contextState.groupId && groupViewerCanOnlyManageStudents;
  const isValidityLocked = isEditingLocked || (isGroupExpired && contextState.has_memberships);

  const [isConfirmationDialogOpen, showConfirmationDialog, hideConfirmationDialog] = useFlag();
  const { isFetching: isStudentValidating, validate: validateStudents } = useLimitedToValidation({
    schoolId: schoolId ?? '',
    userType: 'student',
  });
  const { isFetching: isStaffValidating, validate: validateStaff } = useLimitedToValidation({
    schoolId: schoolId ?? '',
    userType: 'staff',
  });

  const validityChecking = isStaffValidating || isStudentValidating;

  const peopleExtensionDisabled =
    !contextState.name || !contextState.validityRange?.[0] || validityChecking;

  const onSubmit = useCallback(() => {
    if (deleteHistoryUsers?.length) {
      showConfirmationDialog();
      return;
    }
    actions.submitGroup();
  }, [actions, deleteHistoryUsers?.length, showConfirmationDialog]);

  const { deleteHistoryStaff, deleteHistoryStudents } = useMemo(() => {
    const deleteHistoryStaff = contextState.staff
      ?.filter(({ user: { relation_id } }) =>
        deleteHistoryUsers?.some((u) => u.relation_id === relation_id),
      )
      .map(({ user }) => user);

    const deleteHistoryStudents = contextState.students
      ?.filter(({ user: { relation_id } }) =>
        deleteHistoryUsers?.some((u) => u.relation_id === relation_id),
      )
      .map(({ user }) => user);

    return { deleteHistoryStaff, deleteHistoryStudents };
  }, [contextState?.staff, contextState?.students, deleteHistoryUsers]);

  const dateError =
    !!contextState.errors?.validityRange &&
    typeof contextState.errors?.validityRange !== 'string' &&
    !!contextState.errors?.validityRange['values']?.['endDate'];

  const renderCustomIcon = useCallback(
    (userId: string) => {
      return isIndividualMember(userId) ? (
        <ContextMenu key={userId} actions={contextActions(userId)}>
          {(handleClick) => (
            <IconButton inverse onClick={handleClick}>
              <MoreIcon />
            </IconButton>
          )}
        </ContextMenu>
      ) : (
        <PersonCardIconButton onClick={() => actions.handleUserRemove(userId)}>
          <MinusIcon />
        </PersonCardIconButton>
      );
    },
    [actions, contextActions, isIndividualMember],
  );

  const dates = useMemo(() => {
    if (!contextState?.validity) return [];
    const [dateFrom, dateTo] = contextState.validityRange;
    if (!dateFrom || !dateTo) return [];
    return [dateFrom, dateTo];
  }, [contextState?.validity, contextState.validityRange]);

  const validateMembersValidity = useCallback(
    async (dateRange: string[]) => {
      const criteriaMemberIds = contextState?.groupCriteria.flatMap((c) => c.relation_ids ?? []);

      const staffMembers = contextState.groupStaff.length
        ? await validateStaff({
            dateRange,
            criteriaMemberIds: [],
            limitedTo: contextState.limitedToStaff,
            memberIds: contextState.groupStaff.map(({ relation_id }) => relation_id),
          })
        : undefined;

      const studentMembers =
        contextState.groupStudents.length || criteriaMemberIds.length
          ? await validateStudents({
              dateRange,
              criteriaMemberIds: criteriaMemberIds,
              limitedTo: contextState.limitedToStaff,
              memberIds: contextState.groupStudents.map(({ relation_id }) => relation_id),
            })
          : undefined;

      if (!staffMembers && !studentMembers) return;
      return {
        studentMemberIds: studentMembers?.deletedMemberIds,
        staffMemberIds: staffMembers?.deletedMemberIds,
        criteriaIds: studentMembers?.deletedCriteriaMemberIds,
      };
    },
    [
      contextState.groupStudents,
      contextState.groupStaff,
      contextState?.groupCriteria,
      contextState.limitedToStaff,
      validateStaff,
      validateStudents,
    ],
  );

  const handleValidityChange = useCallback(
    async (values: [Date, Date]) => {
      const startDate = format(values[0], DEFAULT_DATE_FORMAT_FNS);
      const endDate = format(values[1], DEFAULT_DATE_FORMAT_FNS);

      const updatedDate = [startDate, endDate];

      if (contextState.focusedField === 'validity') {
        actions.onFieldEditClick();
      }

      if (isEqual(updatedDate, dates)) return;

      if (
        !contextState.groupStudents.length &&
        !contextState.groupStaff.length &&
        !contextState.groupCriteria.length
      ) {
        actions.setValidityRangeHandler({
          date: updatedDate,
        });
        return;
      }

      const membersToRemove = await validateMembersValidity(updatedDate);

      if (membersToRemove) {
        const { staffMemberIds = [], studentMemberIds = [], criteriaIds = [] } = membersToRemove;

        let errorText = '';

        if (Boolean(staffMemberIds?.length)) {
          const userTypePlural = $t({
            id: getUserTypeTextId('staff', staffMemberIds.length > 1),
          }).toLowerCase();
          errorText = `${staffMemberIds.length} ${userTypePlural}`;
        }

        const onlyCriteriaToRemove = !studentMemberIds?.length && !!criteriaIds?.length;

        if (onlyCriteriaToRemove) {
          const criteriaText = $t({ id: 'groups-AvailableCriteria' }).toLowerCase();
          errorText = errorText ? `${errorText}, ${criteriaText}` : criteriaText;
        }

        if (Boolean(studentMemberIds?.length)) {
          const userTypePlural = $t({
            id: getUserTypeTextId('student', studentMemberIds.length > 1),
          }).toLowerCase();
          const studentText = `${studentMemberIds.length} ${userTypePlural}`;
          errorText = errorText ? `${errorText}, ${studentText}` : studentText;
        }

        const isConfirmed = Boolean(errorText)
          ? await getConfirmation({
              textId: 'groups-LimitedToBulkRemoveWarning',
              textValues: {
                text: errorText,
              },
            })
          : null;

        if (!isConfirmed) {
          schoolPeriodsRangeSelectRef.current?.updateDates(dates);
          return;
        } else {
          actions.setValidityRangeHandler({
            date: updatedDate,
          });
          removeGroupMembers({
            studentMemberIds,
            staffMemberIds,
            criteriaIds,
          });
        }
      }
    },
    [
      $t,
      actions,
      contextState.focusedField,
      contextState.groupCriteria,
      contextState.groupStaff?.length,
      contextState.groupStudents?.length,
      dates,
      getConfirmation,
      removeGroupMembers,
      validateMembersValidity,
    ],
  );

  if (!schoolId) return null;

  if (!contextState.isGroupLoading && (!canEditGroup || isGroupExpired)) {
    return (
      <ModalSmall open onClose={actions.closeModal}>
        <ModalHeader active withBorderBottom={false}>
          <IconButton onClick={actions.closeModal}>
            <CrossIcon />
          </IconButton>
        </ModalHeader>
        <ModalContent active>
          <ModalAccessDenied />
        </ModalContent>
      </ModalSmall>
    );
  }

  const renderContent = () => {
    if (contextState.isGroupLoading) {
      return <Loading />;
    }

    return (
      <form onSubmit={form.onSubmit(onSubmit)}>
        {contextState.mode === AddGroupModalMode.Initial && (
          <>
            <ModalHeader
              active
              errorText={renderError(contextState.errors?.name) ?? undefined}
              title={
                isEditingLocked ? (
                  <Stack direction="row" gap={1} sx={{ opacity: 0.5 }}>
                    <ModalTitle style={{ flex: 'none' }}>{contextState.name}</ModalTitle>
                    <InputEndAdornmentIcon>
                      <LockIcon />
                    </InputEndAdornmentIcon>
                  </Stack>
                ) : (
                  <ModalHeaderInput
                    placeholder={$t({ id: 'groups-GroupName' })}
                    value={contextState.name}
                    autoFocus={contextState.focusedField === 'name'}
                    onChange={actions.onGroupNameChange}
                    error={Boolean(contextState.errors?.name)}
                    data-cy="groups-modal-name-input"
                  />
                )
              }
            >
              <IconButton data-cy="modal-close" onClick={actions.closeModal}>
                <CrossIcon />
              </IconButton>
            </ModalHeader>

            <ModalPanel withBorderBottom active sx={{ overflow: 'unset' }}>
              <Stack gap={2.5}>
                <SchoolPeriodsRangeSelect
                  schoolId={schoolId}
                  date={dates}
                  onSetDate={handleValidityChange}
                  hasManagePermission={isAdmin}
                  defaultSchoolYear={defaultValidity}
                  disabled={isValidityLocked}
                  showActualPeriods
                  fromDateError={dateError}
                  toDateError={dateError}
                  ref={schoolPeriodsRangeSelectRef}
                  shouldOpen={contextState.focusedField === 'validity'}
                  isLoading={validityChecking}
                  {...(contextState.errors?.validityRange && {
                    error: {
                      messageTextId: (contextState.errors?.validityRange as MessageDescriptor).id,
                    } as ExtendedFieldError,
                  })}
                  renderHint={
                    contextState.dateNa && (
                      <WithShowOnClick showOnClick={!!isMobile}>
                        {(onClick, show) => (
                          <Tooltip
                            open={show}
                            title={$t({ id: 'groups-NoCurrentSchoolYear-ValidityHint' })}
                          >
                            <Stack
                              onClick={onClick}
                              sx={(theme) => ({
                                position: 'absolute',
                                top: theme.spacing(1.5),
                                right: theme.spacing(5),
                                color: 'warning.main',
                                fontSize: theme.spacing(2.5),
                                zIndex: theme.zIndex.drawer,

                                '& .svg-icon': { '& circle, & rect': { color: 'common.white' } },
                              })}
                            >
                              <Attention2Icon />
                            </Stack>
                          </Tooltip>
                        )}
                      </WithShowOnClick>
                    )
                  }
                />

                <SubjectSelect isEditingLocked={isEditingLocked} />

                <FormTextField
                  label={$t({ id: 'groups-GroupDescription' })}
                  multiline
                  fullWidth
                  minRows={2}
                  autoFocus={contextState.focusedField === 'description'}
                  onChange={actions.onInputChangeHandler('description')}
                  value={contextState.description}
                  data-cy="groups-modal-description-input"
                />
              </Stack>
            </ModalPanel>
          </>
        )}

        {contextState.mode !== AddGroupModalMode.Initial && (
          <GroupPreviewModalInfo
            group={contextState as unknown as GroupWithMembers}
            validity={
              contextState.validityRange
                ? `${format(
                    newDateTimezoneOffset(contextState.validityRange[0]),
                    DATE_FORMAT,
                  )} - ${format(newDateTimezoneOffset(contextState.validityRange[1]), DATE_FORMAT)}`
                : ''
            }
            onModalClose={actions.closeModal}
            onEditClick={actions.onFieldEditClick}
            editable
            locked={isEditingLocked}
            shortDescription
            titleErrorText={renderError(contextState.errors?.name) ?? undefined}
          />
        )}
        <ModalPeopleExtensionPanel
          titleId="section-Staff"
          addActionId="groups-AddStaff"
          editActionId="groups-EditStaff"
          count={contextState.groupStaff?.length}
          active={contextState.mode === AddGroupModalMode.Staff && !isConfirmationDialogOpen}
          disabled={peopleExtensionDisabled}
          onAddClick={actions.setStateHandler('mode', AddGroupModalMode.Staff)}
          actionButton={
            !peopleExtensionDisabled &&
            !isStaffViewer && (
              <Tooltip title={$t({ id: 'groups-NoManageStaffPermissions' })}>
                <Icon>
                  <LockIcon />
                </Icon>
              </Tooltip>
            )
          }
          testId="groups-modal-add-staff"
          sidebarContent={
            hasStaffLimitedTo ? (
              <GroupSidebarContent
                deleteHistoryUsers={deleteHistoryStaff}
                userType="staff"
                schoolId={schoolId}
                titleId="userType-staff"
                groupCriteria={contextState.groupCriteria}
                groupUsers={contextState.groupStaff}
                onUserClick={actions.handleUserAdd}
                onAddUsers={actions.handleUsersAdd}
                onCriteriaClick={actions.handleCriteriaClick}
                groupId={contextState.groupId}
                limitedTo={contextState.limitedToStaff}
                validityRange={contextState.validityRangeRequest}
                onRemovedUserClick={actions.handleUserRecover}
                activeTab={contextState.activeTab}
              />
            ) : undefined
          }
        >
          <ModalContent withBorderBottom active flat>
            <GroupContent
              userType="staff"
              schoolProperties={
                (contextState.schoolStaffProperties.filter(
                  (p) => 'type' in p,
                ) as SchoolProperty[]) || []
              }
              limitedTo={contextState.limitedToStaff}
              onLimitedToChange={(d, e) => actions.handleLimitedToChange(d, 'staff', e)}
              groupUsers={contextState.groupStaff}
              renderCustomIcon={renderCustomIcon}
              schoolId={schoolId}
              dateRange={contextState.validityRangeRequest}
              limitedToOptions={staffLimitedToOptions}
              requiredCategories={staffRequiredCategories}
            />
          </ModalContent>
        </ModalPeopleExtensionPanel>

        <ModalPeopleExtensionPanel
          titleId="section-Students"
          addActionId="groups-AddStudents"
          editActionId="groups-EditStudents"
          count={contextState.uniqStudentsCount}
          active={contextState.mode === AddGroupModalMode.Students && !isConfirmationDialogOpen}
          disabled={peopleExtensionDisabled}
          onAddClick={actions.setStateHandler('mode', AddGroupModalMode.Students)}
          forceShowEditButton={contextState.groupCriteria?.length > 0}
          testId="groups-modal-edit-students"
          sidebarContent={
            hasStudentsLimitedTo ? (
              <GroupSidebarContent
                deleteHistoryUsers={deleteHistoryStudents}
                schoolId={schoolId}
                userType="student"
                titleId="userType-student-plural"
                groupCriteria={contextState.groupCriteria}
                groupUsers={contextState.groupStudents}
                onUserClick={actions.handleUserAdd}
                onAddUsers={actions.handleUsersAdd}
                onCriteriaClick={actions.handleCriteriaClick}
                groupId={contextState.groupId}
                limitedTo={contextState.limitedToStudents}
                validityRange={contextState.validityRangeRequest}
                onRemovedUserClick={actions.handleUserRecover}
                activeTab={contextState.activeTab}
              />
            ) : undefined
          }
        >
          <ModalContent active flat>
            <GroupContent
              userType="student"
              schoolProperties={
                (contextState.schoolStudentProperties.filter(
                  (p) => 'type' in p,
                ) as SchoolProperty[]) || []
              }
              limitedTo={contextState.limitedToStudents}
              onLimitedToChange={(d, e) => actions.handleLimitedToChange(d, 'student', e)}
              groupUsers={contextState.groupStudents}
              groupCriteria={contextState.groupCriteria}
              onCriteriaClick={actions.handleCriteriaClick}
              renderCustomIcon={renderCustomIcon}
              schoolId={schoolId}
              dateRange={contextState.validityRangeRequest}
              limitedToOptions={studentLimitedToOptions}
              requiredCategories={studentRequiredCategories}
            />
          </ModalContent>
        </ModalPeopleExtensionPanel>

        <ModalMain />

        <ModalFooterWithActions
          saving={contextState.saving}
          deleting={contextState.deleting}
          disabled={
            isGroupExpired || contextState.saving || contextState.deleting || validityChecking
          }
          showDeleteButton={!!contextState.groupId}
          isNewItem={!contextState.groupId}
          onDeleteClick={actions.removeGroup}
        />
      </form>
    );
  };

  return (
    <ModalSmall open onClose={actions.closeModal}>
      {renderContent()}
      <DeleteHistoryDialog
        deleteHistoryStudents={deleteHistoryStudents}
        deleteHistoryStaff={deleteHistoryStaff}
        isOpen={isConfirmationDialogOpen}
        onClose={hideConfirmationDialog}
        onConfirm={actions.submitGroup}
        loading={contextState.saving}
      />
    </ModalSmall>
  );
};
