import { useMutation, useQuery } from '@tanstack/react-query';

import { ApiError } from './apiTypes/misc';
import { RQUseMutationOptions, RQUseMutationResult, RQUseQueryOptions } from './apiTypes/query';
import { BILLING_URL } from './commonPaths';
import * as api from './requests';

export enum PaymentFrequencyType {
  Weekly = 'weekly',
  Monthly = 'monthly',
  Termly = 'termly',
  Annually = 'annually',
  Biannually = 'biannually',
}

type Date = string;

type ActivityCycle = {
  start: Date;
  end: Date;
};

type ActivityCycleWithPeriod = {
  school_year_period_id: string;
} & ActivityCycle;

export type BillingPeriod = {
  generation_date: Date;
  invoice_date: Date;
  due_date: Date;
};

export type RecurringDaySpec = {
  offset_days: number;
  offset_cycle: 'current' | 'previous';
};

type FrequencyCommon = {
  id: string;
  due_days_count: number;
  in_use: boolean;
  year_id: string;
  has_invoices: boolean;
  has_products: boolean;
  type: PaymentFrequencyType;
  activity_period: ActivityCycle;
  activity_cycles: ActivityCycle[];
  billing_periods: BillingPeriod[];
  billing_periods_spec: {
    generation_date: RecurringDaySpec;
    invoice_date: RecurringDaySpec;
  };
};

export type WeeklyPaymentFrequency = {
  type: PaymentFrequencyType.Weekly;
} & FrequencyCommon;

export type MonthlyPaymentFrequency = {
  type: PaymentFrequencyType.Monthly;
} & FrequencyCommon;

export type TermlyWithoutPeriodGroup<T = null> = {
  type: PaymentFrequencyType.Termly;
  school_year_period_group_id: T;
  billing_periods_spec: null;
  activity_cycles: Array<ActivityCycleWithPeriod>;
} & Omit<FrequencyCommon, 'billing_periods_spec' | 'activity_cycles'>;

export type TermlyWithPeriodGroup = TermlyWithoutPeriodGroup<string>;

export type TermlyPaymentFrequency = TermlyWithPeriodGroup | TermlyWithoutPeriodGroup;

export type BiAnnuallyPaymentFrequency = {
  type: PaymentFrequencyType.Biannually;
} & FrequencyCommon;

export type AnnuallyPaymentFrequency = {
  type: PaymentFrequencyType.Annually;
} & FrequencyCommon;

export type PaymentFrequency =
  | MonthlyPaymentFrequency
  | WeeklyPaymentFrequency
  | TermlyPaymentFrequency
  | BiAnnuallyPaymentFrequency
  | AnnuallyPaymentFrequency;

type GetPaymentFrequenciesRequest = {
  year_id: string;
  school_id: string;
};
type GetPaymentFrequenciesResponse = {
  frequencies: PaymentFrequency[];
};

const getSchoolPaymentFrequencies = async ({
  school_id,
  year_id,
}: GetPaymentFrequenciesRequest): Promise<GetPaymentFrequenciesResponse> => {
  return api.get(`${BILLING_URL}/frequencies`, {
    params: {
      school_id,
      year_id,
    },
  });
};

export const GET_PAYMENT_FREQUENCIES_QUERY = `${BILLING_URL}/GET_PAYMENT_FREQUENCIES_QUERY`;

export const useGetSchoolPaymentFrequencies = (
  params: GetPaymentFrequenciesRequest,
  options?: RQUseQueryOptions<GetPaymentFrequenciesResponse>,
) => {
  return useQuery<GetPaymentFrequenciesResponse, ApiError>(
    [GET_PAYMENT_FREQUENCIES_QUERY, params],
    () => getSchoolPaymentFrequencies(params),
    {
      ...options,
    },
  );
};

export const UPDATE_PAYMENT_FREQUENCY_MUTATION = `${BILLING_URL}UPDATE_PAYMENT_FREQUENCY_MUTATION`;

export function updatePaymentFrequency({
  id,
  in_use,
}: PaymentFrequency): Promise<PaymentFrequency> {
  return api.patch(`${BILLING_URL}/frequencies/${id}`, {
    in_use,
  });
}

export const useUpdatePaymentFrequency = (
  options?: RQUseMutationOptions<PaymentFrequency, PaymentFrequency>,
): RQUseMutationResult<PaymentFrequency, PaymentFrequency> => {
  return useMutation(
    [UPDATE_PAYMENT_FREQUENCY_MUTATION],
    (params: PaymentFrequency) => updatePaymentFrequency(params),
    {
      ...options,
    },
  );
};
