import { Stack, Typography } from '@mui/material';
import { FC } from 'react';

import { useRouter } from '../../../context/router/useRouter';
import { ModalPanelProps, ModalTitle, ModalTitleMultiLine } from './Modal.styled';
import { ModalHeaderActions, ModalHeaderStyled } from './ModalHeader.styled';
import { ModalHeaderGoBack } from './ModalHeaderGoBack';

export interface ModalHeaderProps extends Omit<ModalPanelProps, 'title'> {
  title?: React.ReactNode;
  multiline?: boolean;
  withGoBack?: boolean;
  errorText?: string;
}

export const ModalHeader: FC<ModalHeaderProps> = ({
  title,
  children,
  multiline,
  withGoBack,
  errorText,
  ...props
}) => {
  const { stack } = useRouter();
  const canGoBack = withGoBack && stack.length > 1;
  const TitleComponent = multiline ? ModalTitleMultiLine : ModalTitle;

  return (
    <ModalHeaderStyled withGoBack={canGoBack} {...props}>
      <Stack width="100%">
        <TitleComponent title={typeof title === 'string' ? title : undefined}>
          {title}
        </TitleComponent>
        {errorText && (
          <Typography variant="body2" color="error.main" data-cy="modal-header-error">
            {errorText}
          </Typography>
        )}
      </Stack>

      {children && <ModalHeaderActions>{children}</ModalHeaderActions>}
      {canGoBack && <ModalHeaderGoBack />}
    </ModalHeaderStyled>
  );
};
