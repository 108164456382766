import { Button, Checkbox, FormControlLabel, IconButton, Stack, Typography } from '@mui/material';
import { deleteSchool } from '@schooly/api';
import { ApiError } from '@schooly/api';
import { useNotifications } from '@schooly/components/notifications';
import { ArrowRightIcon, CrossIcon, Spin } from '@schooly/style';
import { FC, useCallback, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import logo from '../../../../assets/images/schooly-logo.svg';
import { FormTextField } from '../../../../components/uikit-components/FormTextField/FormTextField';
import {
  ModalConfirm,
  ModalContent,
  ModalFooter,
  ModalMain,
  ModalOpaque,
} from '../../../../components/uikit-components/Modal/Modal.styled';
import { ModalHeader } from '../../../../components/uikit-components/Modal/ModalHeader';
import { useRouter } from '../../../../context/router/useRouter';
import { useSchool } from '../../../../hooks/useSchool';
import { SchoolSettingsLayout } from '../../SchoolSettingsLayout';

enum State {
  Confirm,
  EnterName,
  Deleted,
}

export const SchoolGeneralDeleteSchoolModal: FC = () => {
  const { $t } = useIntl();
  const { currentSchool, schoolId } = useSchool();
  const router = useRouter();
  const [deleting, setDeleting] = useState(false);
  const { showError } = useNotifications();
  const [isConfirmed, _setConfirmed] = useState(false);
  const [state, setState] = useState<State>(State.Confirm);
  const [name, _setName] = useState('');
  const [hasError, setError] = useState(false);
  const handleClose = router.goBack;

  const toggleConfirmed = useCallback(() => {
    hasError && setError(false);

    _setConfirmed((c) => !c);
  }, [hasError]);
  const setName = useCallback(
    (v: string) => {
      hasError && setError(false);

      _setName(v);
    },
    [hasError],
  );

  const isNameCorrect = name === currentSchool?.name;

  const handleNext = useCallback(async () => {
    if (!schoolId) return;

    switch (state) {
      case State.Confirm:
        if (!isConfirmed) {
          setError(true);
          break;
        }

        setError(false);
        setState(State.EnterName);
        break;
      case State.EnterName:
        if (!isNameCorrect) {
          setError(true);
          break;
        }

        try {
          setDeleting(true);
          setError(false);
          await deleteSchool(schoolId);
          setState(State.Deleted);
        } catch (err) {
          showError(err as ApiError);
          setDeleting(false);
        }

        break;
      case State.Deleted:
        break;
      default:
        return;
    }
  }, [state, showError, schoolId, isNameCorrect, isConfirmed]);

  const renderFormContent = () => {
    switch (state) {
      case State.Confirm:
        return (
          <>
            <Typography variant="h3" color="text.primary">
              <FormattedMessage id="school-delete-ThisActionWillDelete" />
            </Typography>
            <Stack gap={1} mt={1}>
              <Typography variant="h3" color="text.primary">
                <FormattedMessage id="school-delete-SchoolInformation" />
              </Typography>
              <Typography variant="h3" color="text.primary">
                <FormattedMessage id="school-delete-StudentInformation" />
              </Typography>
              <Typography variant="h3" color="text.primary">
                <FormattedMessage id="school-delete-StaffInformation" />
              </Typography>
            </Stack>
            <FormControlLabel
              sx={{ mt: 2 }}
              control={
                <Checkbox
                  onChange={toggleConfirmed}
                  checked={isConfirmed}
                  color={hasError ? 'error' : undefined}
                />
              }
              label={
                <>
                  <Typography variant="h3">
                    <FormattedMessage
                      id="school-delete-IConfirmThat"
                      values={{ schoolName: currentSchool?.name }}
                    />
                  </Typography>
                </>
              }
            />
            {hasError && (
              <Typography variant="body2" color="error" ml={3.25}>
                <FormattedMessage id="school-delete-errorFieldRequired" />
              </Typography>
            )}
          </>
        );
      case State.EnterName:
        return (
          <>
            <Typography variant="h3" color="text.primary">
              <FormattedMessage id="school-delete-PleaseEnterName" />
            </Typography>
            <FormTextField
              onChange={(e) => setName(e.target.value)}
              value={name}
              label={$t({ id: 'school-delete-SchoolName' })}
              required
              fullWidth
              canClear
              error={hasError}
              helperText={hasError ? $t({ id: 'school-delete-errorSchoolName' }) : undefined}
            />
          </>
        );
      default:
        return null;
    }
  };

  if (state === State.Deleted)
    return (
      <ModalOpaque open>
        <ModalContent active>
          <img src={logo} width={110} alt="Schooly" />
          <Typography variant="h1" my={2}>
            <FormattedMessage
              id="school-delete-SchoolRemoved"
              values={{ schoolName: currentSchool?.name }}
            />
          </Typography>
          <Stack alignItems="flex-end">
            <Button
              onClick={() => {
                window.location.href = window.location.origin;
              }}
            >
              <FormattedMessage id="school-delete-BackToMain" />
            </Button>
          </Stack>
        </ModalContent>
      </ModalOpaque>
    );

  return (
    <SchoolSettingsLayout>
      <ModalConfirm open fullWidth onClose={handleClose}>
        <ModalHeader
          multiline
          title={$t({ id: 'school-delete-AreYouSure' }, { schoolName: currentSchool?.name })}
          withBorderBottom={false}
          active
        >
          <IconButton disabled={deleting} onClick={handleClose}>
            <CrossIcon />
          </IconButton>
        </ModalHeader>
        <ModalMain>
          <ModalContent active>
            <Stack gap={1} mt={-2}>
              {renderFormContent()}
            </Stack>
          </ModalContent>
        </ModalMain>
        <ModalFooter withBorderTop={false} active>
          <Button variant="outlined" disabled={deleting} onClick={handleClose}>
            <FormattedMessage id="action-Cancel" />
          </Button>
          <Button
            color="error"
            onClick={handleNext}
            disabled={deleting}
            endIcon={deleting ? <Spin /> : <ArrowRightIcon />}
          >
            <FormattedMessage id="action-Continue" />
          </Button>
        </ModalFooter>
      </ModalConfirm>
    </SchoolSettingsLayout>
  );
};
