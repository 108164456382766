import { Button } from '@mui/material';
import { useGetSchoolPaymentFrequencies } from '@schooly/api';
import { useAuth } from '@schooly/components/authentication';
import { useFlag } from '@schooly/hooks/use-flag';
import { IncompleteSvg, Loading, PlusIcon, Spin } from '@schooly/style';
import { openJiraWidgetForRequest } from '@schooly/utils/open-jira-widget-for-request';
import React, { FC } from 'react';
import { useIntl } from 'react-intl';
import { Link, Outlet, useNavigate, useParams } from 'react-router-dom';

import MainLayout from '../../../components/uikit-components/MainLayout/MainLayout';
import { WithFrequencies } from '../../../context/frequencies/WithFrequencies';
import useSchoolYears from '../../../hooks/useSchoolYears';
import AccessDeniedPage from '../../AccessDenied';
import { SchoolPaymentFrequenciesEmptyStub } from './SchoolPaymentFrequenciesEmptyStub';

//TR-6100 This component contains commented code that is to be used
// when full functionality of frequencies is needed to be introduced

export const SchoolPaymentFrequencies: FC = () => {
  const { defaultValidity, isLoading: yearsFetching, getDefaultValidity } = useSchoolYears();
  const navigate = useNavigate();
  const { $t } = useIntl();
  const { schoolId = '', permissions, currentSchool, currentUser } = useAuth();
  const { yearId } = useParams<'yearId'>();
  const [isLoadingWidget, startLoading, endLoading] = useFlag(false);

  const canView = permissions.includes('product_and_invoice_viewer');
  const hasSchoolAdminPermissions = permissions.includes('school_admin');

  //TR-6100 Temporary need this request here.
  // In future we will be checking active frequencies in school years
  const { data, isLoading: frequenciesFetching } = useGetSchoolPaymentFrequencies(
    {
      school_id: schoolId,
      year_id: defaultValidity?.id ?? '',
    },
    { refetchOnMount: 'always', enabled: !!defaultValidity?.id },
  );

  // const hasFrequencies = schoolYears.some((year) => !!year.in_use_info.frequencies);
  const hasFrequencies = !!data?.frequencies.length;

  if (yearsFetching || frequenciesFetching)
    return (
      <MainLayout>
        <Loading />
      </MainLayout>
    );

  if (!canView) {
    return <AccessDeniedPage />;
  }

  // const schoolYear = schoolYears.find(({ id }) => id === yearId);
  // const isInvalidYear = yearId && !schoolYear;
  const isInvalidYear = yearId && yearId !== defaultValidity?.id;
  //Redirect to default school year
  const showDefaultYear = (!yearId || isInvalidYear) && defaultValidity && hasFrequencies;
  if (showDefaultYear) {
    navigate({ pathname: `/settings/frequencies/year/${defaultValidity.id}` }, { replace: true });
    return null;
  }

  //Redirect in case of no frequencies
  if (yearId && !hasFrequencies) {
    navigate({ pathname: '/settings/frequencies' }, { replace: true });
    return null;
  }

  // const currentSchoolYear = schoolYear ?? defaultValidity ?? null;
  const currentSchoolYear = defaultValidity ?? null;
  const hasOnlyPastYears = !getDefaultValidity(true);

  const hasNoYears = (!hasFrequencies && hasOnlyPastYears) || !currentSchoolYear;
  if (hasNoYears) {
    return (
      <MainLayout>
        <SchoolPaymentFrequenciesEmptyStub
          labelTextId="frequencies-NoCurrentOrFutureYears"
          action={
            hasSchoolAdminPermissions && (
              <Link to="/settings/frequencies/school_years">
                <Button startIcon={<PlusIcon />}>
                  {$t({ id: 'school-tabs-SchoolYears-AddSchoolYear' })}
                </Button>
              </Link>
            )
          }
        />
        <Outlet />
      </MainLayout>
    );
  }

  if (!hasFrequencies) {
    return (
      <MainLayout>
        {/* <SchoolPaymentFrequenciesEmptyStub
          labelTextId="frequencies-SetFrequenciesToStart"
          descriptionLabelTextId="frequencies-SetFrequenciesToStart-Description"
          action={
            canEdit && (
            <Button startIcon={<PlusIcon />}>{$t({ id: 'frequencies-SetFrequencies' })}</Button>
            )
          }
        />
        <Outlet /> */}

        <SchoolPaymentFrequenciesEmptyStub
          svg={<IncompleteSvg />}
          labelTextId="frequencies-RequestFrequencies"
          descriptionLabelTextId="frequencies-RequestFrequencies-Description"
          action={
            <Button
              onClick={async () => {
                startLoading();
                await openJiraWidgetForRequest('Frequencies', {
                  summary: 'Request Update to Invoicing Frequencies',
                  description: currentSchool?.name,
                  email: currentUser?.account_email,
                });
                endLoading();
              }}
              disabled={isLoadingWidget}
              startIcon={isLoadingWidget ? <Spin /> : undefined}
            >
              {$t({ id: 'action-SubmitRequest' })}
            </Button>
          }
        />
      </MainLayout>
    );
  }

  return (
    <MainLayout>
      <WithFrequencies schoolYear={currentSchoolYear} currentFrequencies={data?.frequencies}>
        <Outlet />
      </WithFrequencies>
    </MainLayout>
  );
};
