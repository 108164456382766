import { Box, Icon, Stack, Typography } from '@mui/material';
import {
  ConsentFormItem,
  DATE_FORMAT_SHORT_FNS,
  GetConsentFormQueryFilters,
  SchoolYear,
  SHORT_FORMATTED_DATE_FORMAT_FNS,
  useGetConsentFormItemsForRelationQuery,
  UserType,
} from '@schooly/api';
import { SearchInputFilterIconButton } from '@schooly/components/filters';
import { MD_BREAKPOINT_TABLE_MIN_WIDTH } from '@schooly/constants';
import { useFlag } from '@schooly/hooks/use-flag';
import { useInfiniteScroll } from '@schooly/hooks/use-infinite-scroll';
import {
  DropdownYears,
  EmptySchoolSvg,
  GridContainer,
  GridRowCell,
  GridRowDate,
  GridRowItem,
  GridRowName,
  GridRowStyled,
  HistoryLogIcon,
  Loading,
  ModalSearch,
  SimpleButton,
  TypographyWithOverflowHint,
} from '@schooly/style';
import { newDateTimezoneOffset } from '@schooly/utils/date';
import { getUserFullName } from '@schooly/utils/user-helpers';
import { format } from 'date-fns';
import React, { FC, useCallback, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link, useNavigate } from 'react-router-dom';

import {
  ConsentFormSignedInfo,
  ConsentItemsSkeletonRows,
} from '../../../../components/common/ConsentForms/ConsentFormPreview';
import {
  getConsentFormItemStatusColor,
  getConsentFormItemStatusLabelId,
} from '../../../../components/common/ConsentForms/helpers';
import { useProfile } from '../../../../context/profile/useProfile';
import { getRouteModalPathname } from '../../../../helpers/misc';
import { useSchool } from '../../../../hooks/useSchool';
import useSchoolYears from '../../../../hooks/useSchoolYears';
import { ConsentFormsHistoryPreviewModal } from '../../../ConsentForms/ConsentFormsHistoryPreviewModal';
import { useReferenceInfo } from '../../../ConsentForms/hooks';
import { ConsentFormsFilterPopup } from './ConsentFormsFilterPopup';

const PAGE_SIZE = 30;

type ProfileModalConsentFormsProps = {
  relationId: string;
};

export const ProfileModalConsentForms: FC<ProfileModalConsentFormsProps> = ({ relationId }) => {
  const { $t } = useIntl();
  const { schoolId } = useSchool();
  const { defaultValidity, schoolYears } = useSchoolYears();
  const { userType, user } = useProfile();

  const [filtersModalVisible, openFilterModal, closeFiltersModal] = useFlag(false);
  const [historyModalVisible, openHistoryModal, closeHistoryModal] = useFlag(false);

  const { data, isLoading, setParams, hasNextPage, params, fetchNextPage, isFetchingNextPage } =
    useGetConsentFormItemsForRelationQuery(
      {
        year_id: defaultValidity?.id!,
        page_size: PAGE_SIZE,
        relation_id: relationId,
        search_query: '',
      },
      { refetchOnMount: 'always', enabled: !!defaultValidity?.id && !!schoolId },
    );

  const loaderRef = useInfiniteScroll(isLoading || isFetchingNextPage, fetchNextPage, hasNextPage);

  const entriesByMonth = useMemo(() => {
    const entries =
      data?.pages.reduce<ConsentFormItem[]>((prev, curr) => [...prev, ...curr.results], []) ?? [];

    return entries?.reduce<Record<string, ConsentFormItem[]>>((prev, entry) => {
      const month = format(newDateTimezoneOffset(entry.publish_date), 'MMM');

      return prev[month]
        ? { ...prev, [month]: [...prev[month], entry] }
        : { ...prev, [month]: [entry] };
    }, {});
  }, [data?.pages]);

  const handleQueryChange = useCallback(
    (search_query: string) => {
      setParams((p) => ({ ...p, search_query }));
    },
    [setParams],
  );

  const selectedYear = useMemo(
    () => schoolYears.find((y) => y.id === params.year_id),
    [params.year_id, schoolYears],
  );

  const handleYearChange = useCallback(
    (schoolYear: SchoolYear) => {
      setParams((p) => ({ ...p, year_id: schoolYear.id }));
    },
    [setParams],
  );

  const handleSetFilters = useCallback(
    ({ status }: GetConsentFormQueryFilters) => {
      setParams((p) => ({ ...p, statuses: status?.length ? status : undefined }));
    },
    [setParams],
  );

  const filtersApplied = !!params.statuses?.length;
  const total = data?.pages[0].count;

  if (!userType || !schoolId || !user) return null;

  return (
    <>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        mb={1.75}
        mt={-0.75}
      >
        <Typography variant="h2">{$t({ id: 'profile-ConsentForms' })}</Typography>

        <Stack direction="row" gap={2.5} alignItems="center">
          <ModalSearch
            value={params.search_query ?? ''}
            onChange={handleQueryChange}
            placeholder={$t({ id: 'people-Search' })}
            withDebounce
          />

          <Icon
            sx={{
              position: 'relative',
              height: 22,
              '.MuiButtonBase-root': {
                right: 0,
                color: 'text.primary',
                ':hover': {
                  color: 'primary.main',
                },
              },
            }}
          >
            <SearchInputFilterIconButton
              hasAppliedIndicator={filtersApplied}
              onClick={openFilterModal}
            />
          </Icon>

          <SimpleButton inverse onClick={openHistoryModal} startIcon={<HistoryLogIcon />}>
            <FormattedMessage id="viewHistory" />
          </SimpleButton>

          <DropdownYears
            years={schoolYears}
            defaultYear={defaultValidity}
            currentYear={selectedYear}
            onYearChange={handleYearChange}
          />
        </Stack>
      </Stack>
      <Stack
        gap={0.75}
        sx={(theme) => ({
          overflowY: 'scroll',
          height: '100%',
          [theme.breakpoints.down('md')]: {
            mr: -2.5,
          },
        })}
      >
        {!isLoading && total === 0 && (
          <Stack gap={2.5} pt={8} alignSelf="center" justifySelf="center">
            <EmptySchoolSvg />

            <Typography variant="h3" color="common.grey2" textAlign="center" maxWidth={280}>
              {$t({
                id: 'consentForms-emptyProfileList',
              })}
            </Typography>
          </Stack>
        )}
        {Object.keys(entriesByMonth).map((month, i) => (
          <Box key={month} pt={i !== 0 ? 1.5 : 0}>
            <Typography variant="h4">{month}</Typography>
            <Stack
              sx={(theme) => ({
                [theme.breakpoints.down('md')]: {
                  overflowX: 'auto',
                  pr: 2.5,
                },
              })}
            >
              <GridContainer
                sx={(theme) => ({
                  [theme.breakpoints.down('md')]: {
                    minWidth: MD_BREAKPOINT_TABLE_MIN_WIDTH,
                  },
                })}
              >
                {entriesByMonth[month].map((entry) => (
                  <ConsentFormItemRow key={entry.id} item={entry} userType={userType} />
                ))}
              </GridContainer>
            </Stack>
          </Box>
        ))}
        {isLoading && <Loading />}
        {hasNextPage && (
          <>
            <div ref={loaderRef} />
            <ConsentItemsSkeletonRows
              widths={['50px', '23%', '25%', '15%', '23%']}
              rowCount={Math.min(
                PAGE_SIZE,
                total && data ? total - data.pages.length * PAGE_SIZE : PAGE_SIZE,
              )}
            />
          </>
        )}
      </Stack>
      {filtersModalVisible && (
        <ConsentFormsFilterPopup
          onSetFilters={handleSetFilters}
          onClose={closeFiltersModal}
          filters={{ status: params.statuses ?? [] }}
        />
      )}
      {historyModalVisible && defaultValidity && (
        <ConsentFormsHistoryPreviewModal
          relationId={relationId}
          initialSchoolYear={defaultValidity}
          onClose={closeHistoryModal}
          schoolYears={schoolYears}
          headerContent={
            <Stack>
              <Typography color="common.grey">
                {$t({ id: 'consentForms-studentFormHistoryPath' })}
              </Typography>
              <Typography variant="h1">{getUserFullName(user)}</Typography>
            </Stack>
          }
        />
      )}
    </>
  );
};

type ConsentFormItemRowProps = {
  item: ConsentFormItem;
  userType: UserType;
};

const ConsentFormItemRow: FC<ConsentFormItemRowProps> = ({ item, userType }) => {
  const { icon, title, path } = useReferenceInfo(item.reference);

  const statusColor = getConsentFormItemStatusColor(item.status);
  const navigate = useNavigate();
  const { $t } = useIntl();
  return (
    <GridRowStyled
      onClick={() => navigate(`/consent-form/${item.consent_form_id}`)}
      sx={{
        cursor: 'pointer',
        ':hover': {
          '.referenceIcon': {
            color: 'primary.main',
          },
        },
      }}
    >
      <GridRowItem sx={{ gap: 2, py: 1.25, px: 1 }}>
        <GridRowDate sx={{ flex: '0 0 46px' }}>
          {format(newDateTimezoneOffset(item.sign_date), DATE_FORMAT_SHORT_FNS)}
        </GridRowDate>

        <GridRowName
          sx={{
            flex: '0 0 190px',
            maxWidth: 190,
          }}
        >
          <Stack
            component={Link}
            to={path}
            direction="row"
            onClick={(e) => e.stopPropagation()}
            alignItems="center"
            sx={{
              gap: 0.5,
              ':hover': {
                '*': {
                  textDecoration: 'underline',
                },
              },
            }}
          >
            <Icon className="referenceIcon" sx={{ color: 'common.grey2' }} fontSize="small">
              {icon}
            </Icon>
            <TypographyWithOverflowHint maxWidth={210} variant="h3">
              {title}
            </TypographyWithOverflowHint>
          </Stack>
        </GridRowName>

        <GridRowName
          sx={{
            flex: '0 0 200px',
          }}
        >
          <TypographyWithOverflowHint maxWidth={200}>
            {userType === 'student' && (
              <>
                {item.signed_by ? (
                  <ConsentFormSignedInfo
                    userName={getUserFullName(item.signed_by)}
                    path={getRouteModalPathname('parent', item.signed_by)}
                    label={$t({
                      id: 'consentForms-signedBy',
                    })}
                  />
                ) : (
                  <Typography>-</Typography>
                )}
              </>
            )}
            {userType === 'parent' && (
              <ConsentFormSignedInfo
                userName={getUserFullName(item.signed_for)}
                path={getRouteModalPathname('student', item.signed_for)}
                label={$t({
                  id: 'consentForms-signedFor',
                })}
              />
            )}
          </TypographyWithOverflowHint>
        </GridRowName>
        <GridRowName>
          {item.sign_date && (
            <Typography color="text.primary">
              {format(newDateTimezoneOffset(item.sign_date), SHORT_FORMATTED_DATE_FORMAT_FNS)}
            </Typography>
          )}
        </GridRowName>
        <GridRowCell sx={{ flex: '0 0 100px' }}>
          <Typography className="status" color={statusColor}>
            {$t({ id: getConsentFormItemStatusLabelId(item.status) })}
          </Typography>
        </GridRowCell>
      </GridRowItem>
    </GridRowStyled>
  );
};
