import { DATE_FORMAT_RANGE_FNS, useGetGroupDetailsQuery } from '@schooly/api';
import { useAuth } from '@schooly/components/authentication';
import { usePeriodsLabel } from '@schooly/components/filters';
import { newDateTimezoneOffset } from '@schooly/utils/date';
import { format, isAfter } from 'date-fns';
import { FC, PropsWithChildren, useContext, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';

import useAppLocation from '../../hooks/useAppLocation';
import useQueryStringParams from '../../hooks/useQueryStringParams';
import useSchoolYears from '../../hooks/useSchoolYears';
import { getTabs, ViewGroupModalTabs } from '../../pages/Groups/utils';
import { useAttendanceForGroup } from '../attendance/useAttendanceForGroup';
import { RouterStateContext, RouterStateContextProps } from '../router/RouterStateContext';
import {
  CONTEXT_NAME,
  GroupContext,
  GroupContextLocation,
  GroupContextState,
} from './GroupContext';

export const WithGroup: FC<PropsWithChildren> = ({ children }) => {
  const location = useAppLocation<GroupContextLocation>();
  const { id } = useParams<'id'>();
  const query = useQueryStringParams();
  const { setContextState, setContextName } = useContext(
    RouterStateContext,
  ) as RouterStateContextProps<GroupContextState>;

  const { schoolYears } = useSchoolYears();

  const { getPeriodLabel } = usePeriodsLabel({ schoolYears });

  const isGroupPath = location.pathname.startsWith('/groups/');
  const groupId = isGroupPath ? id : undefined;

  const { permissions } = useAuth();

  const { data, isLoading, error } = useGetGroupDetailsQuery(
    { id: groupId ?? '' },
    { enabled: Boolean(groupId), refetchOnMount: 'always' },
  );

  useEffect(() => {
    setContextName(CONTEXT_NAME);
    setContextState({ group: data?.group, groupId });
  }, [data?.group, groupId, setContextState, setContextName]);

  const periodLabel = useMemo(() => {
    const { date_from, date_to } = data?.group.validity ?? {};

    return !!date_from && !!date_to
      ? getPeriodLabel({ startDate: date_from, endDate: date_to })
      : undefined;
  }, [data?.group.validity, getPeriodLabel]);

  const validity = useMemo(() => {
    if (!data?.group) {
      return '';
    }

    const { from_school_year, to_school_year, date_from, date_to } = data.group?.validity ?? {};

    if (from_school_year) {
      return `
      ${from_school_year.name}
      ${
        to_school_year && to_school_year?.id !== from_school_year.id
          ? ` — ${to_school_year?.name}`
          : ''
      }
    `;
    }

    return `${format(newDateTimezoneOffset(date_from), DATE_FORMAT_RANGE_FNS)} - ${format(
      newDateTimezoneOffset(date_to),
      DATE_FORMAT_RANGE_FNS,
    )}`;
  }, [data?.group]);

  const tabs = getTabs(permissions, data?.group);
  const activeTab = (query?.tab as ViewGroupModalTabs) || tabs[0]?.id;

  const isGroupExpired = useMemo(() => {
    if (!data?.group.validity.date_to) return;

    const now = new Date();
    return isAfter(now, new Date(data.group.validity.date_to));
  }, [data?.group?.validity]);

  const value = {
    ...data,
    fetching: isLoading,
    error,
    isExpired: isGroupExpired,
    groupId,
    validity,
    periodLabel,
    tabs,
    activeTab,
  };

  return (
    <GroupContext.Provider value={value}>
      {children}
      {!!data?.group && <GroupAttendanceDataPrefetch />}
    </GroupContext.Provider>
  );
};

// Prefetch default data for Group view modal attendance tab

const GroupAttendanceDataPrefetch: FC = () => {
  useAttendanceForGroup();
  return null;
};
