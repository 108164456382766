import { Box, IconButton, Stack, Typography } from '@mui/material';
import { CrossIcon, EditIcon, Loading, ModalSmall } from '@schooly/style';
import { getControllerErrorText } from '@schooly/utils/get-controller-error-text';
import React, { FC, useCallback } from 'react';
import { Controller, FormProvider } from 'react-hook-form-lts';
import { useIntl } from 'react-intl';

import { ModalContent, ModalMain } from '../../../components/uikit-components/Modal/Modal.styled';
import { ModalAccessDenied } from '../../../components/uikit-components/Modal/ModalAccessDenied';
import { ModalHeader } from '../../../components/uikit-components/Modal/ModalHeader';
import {
  ModalHeaderInput,
  ModalHeaderTitle,
} from '../../../components/uikit-components/Modal/ModalHeader.styled';
import { MessagesCreateModalMode } from '../../../context/messages/MessageContext';
import { useMessage } from '../../../context/messages/useMessage';
import { MessagesCreateModalDeleteConfirmation } from './confirmations/MessagesCreateModalDeleteConfirmation';
import { MessagesCreateModalFooter } from './MessagesCreateModalFooter';
import { MessagesCreateModalMessage } from './MessagesCreateModalMessage/MessagesCreateModalMessage';
import { MessagesCreateModalRecipients } from './MessagesCreateModalRecipients/MessagesCreateModalRecipients';
import { MessagesCreateModalRemoveArchived } from './MessagesCreateModalRemoveArchived';

export const MessagesCreateModal: FC = () => {
  const { fetching, mode, messageForm, autoFocus, canEdit, form, actions } = useMessage();

  const { formatMessage } = useIntl();

  const handleHeaderFocus = useCallback(() => {
    if (mode === MessagesCreateModalMode.Title) {
      return;
    }

    actions.setMode(MessagesCreateModalMode.Title, 'title');
  }, [actions, mode]);

  const handleBothForms: React.FormEventHandler<HTMLFormElement> = useCallback(
    (e) => {
      e.preventDefault();
      const formHandler = form.onSubmit((event) => {
        const shouldPreview = (event.nativeEvent as SubmitEvent).submitter?.hasAttribute(
          'data-preview',
        );

        actions.saveMessage(shouldPreview ? 'preview' : 'close');
      });
      return messageForm.handleSubmit(() => formHandler(e))();
    },
    [actions, form, messageForm],
  );

  if (!canEdit && !fetching) {
    return (
      <ModalSmall open onClose={actions.handleClose}>
        <ModalHeader
          active
          sx={(theme) => ({
            [theme.breakpoints.down('lg')]: {
              p: theme.spacing(2, 4),
            },
          })}
        >
          <IconButton onClick={actions.handleClose}>
            <CrossIcon />
          </IconButton>
        </ModalHeader>
        <ModalContent active>
          <ModalAccessDenied />
        </ModalContent>
      </ModalSmall>
    );
  }

  return (
    <ModalSmall open onClose={actions.handleClose}>
      {fetching ? (
        <Loading />
      ) : (
        <FormProvider {...messageForm}>
          <form onSubmit={handleBothForms}>
            <ModalHeader
              sx={(theme) => ({
                [theme.breakpoints.down('lg')]: {
                  p: theme.spacing(2, 4),
                },
                [theme.breakpoints.down('md')]: {
                  py: theme.spacing(3),
                },
              })}
              active={canEdit && mode === MessagesCreateModalMode.Title}
              title={
                <Controller
                  control={messageForm.control}
                  name="title"
                  rules={{ required: true }}
                  render={({ field, fieldState }) => {
                    const errorMessage = getControllerErrorText(
                      fieldState.error,
                      undefined,
                      formatMessage,
                    );

                    return canEdit && mode === MessagesCreateModalMode.Title ? (
                      <ModalHeaderInput
                        placeholder={formatMessage({ id: 'messages-Subject' })}
                        autoFocus={!field.value || autoFocus === 'title'}
                        error={!!fieldState.error}
                        onFocus={handleHeaderFocus}
                        helperText={errorMessage}
                        {...field}
                      />
                    ) : (
                      <Stack direction="row" gap={1} alignItems="center">
                        <Box>
                          <ModalHeaderTitle
                            endIcon={<EditIcon />}
                            iconsOnHover={!!field.value}
                            onClick={handleHeaderFocus}
                          >
                            {field.value}
                          </ModalHeaderTitle>
                        </Box>
                        {!!errorMessage && (
                          <Typography variant="body2" color="error">
                            {errorMessage}
                          </Typography>
                        )}
                      </Stack>
                    );
                  }}
                />
              }
            >
              <IconButton onClick={actions.handleClose}>
                <CrossIcon />
              </IconButton>
            </ModalHeader>
            <MessagesCreateModalRecipients />
            <MessagesCreateModalMessage />
            <ModalMain />
            <MessagesCreateModalFooter />

            <MessagesCreateModalDeleteConfirmation />
            <MessagesCreateModalRemoveArchived />
          </form>
        </FormProvider>
      )}
    </ModalSmall>
  );
};
