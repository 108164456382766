import {
  DATE_FORMAT_MONTH_SHORT_FNS,
  PaymentFrequency,
  PaymentFrequencyType,
  SHORT_FORMATTED_DATE_FORMAT_FNS,
  WeeklyPaymentFrequency,
} from '@schooly/api';
import { Period } from '@schooly/components/form-select';
import { toOrdinal } from '@schooly/components/recurring';
import { newDateTimezoneOffset } from '@schooly/utils/date';
import { differenceInDays, format, isSameMonth } from 'date-fns';

export function isWeeklyPaymentFrequency(
  frequency: PaymentFrequency,
): frequency is WeeklyPaymentFrequency {
  return frequency.type === PaymentFrequencyType.Weekly;
}

export function formatLabelDate(date: Date, formatString: string) {
  return format(date, formatString);
}

type FormatPeriodDatesLabelProps = {
  start: Date;
  end?: Date;
  type?: 'week' | 'month' | 'termly';
};
export function formatPeriodDatesLabel({ start, type, end }: FormatPeriodDatesLabelProps) {
  let currentFormat = '';

  switch (type) {
    case 'week':
    case 'termly':
      currentFormat = SHORT_FORMATTED_DATE_FORMAT_FNS;
      break;

    case 'month':
      currentFormat = DATE_FORMAT_MONTH_SHORT_FNS;
      break;

    default:
      currentFormat = SHORT_FORMATTED_DATE_FORMAT_FNS;
  }

  return `${formatLabelDate(start, currentFormat)}${
    end ? `  – ${formatLabelDate(end, currentFormat)}` : ''
  }`;
}

type FormatPeriodStringDatesLabelProps = {
  start: string;
  end?: string;
} & Pick<FormatPeriodDatesLabelProps, 'type'>;

export function formatPeriodStringDatesLabel({
  start,
  end,
  ...props
}: FormatPeriodStringDatesLabelProps) {
  return formatPeriodDatesLabel({
    start: newDateTimezoneOffset(start),
    end: end ? newDateTimezoneOffset(end) : undefined,
    ...props,
  });
}

type MergePeriodsOptions = {
  mergeSameMonth?: boolean;
};

export function mergePeriods(periods: Period[], options?: MergePeriodsOptions) {
  let startPeriodIdx = 0;
  const currentPeriods: Record<string, Omit<Period, 'end'> & { end?: string }> = {};

  for (const [idx, { start, end }] of periods.entries()) {
    const nextIdx = idx + 1;
    const nextDate = periods[nextIdx];
    const endDate = newDateTimezoneOffset(end);
    const prevPeriod = periods[startPeriodIdx];
    const startDate = prevPeriod ? prevPeriod.start : start;

    const hasGap = nextDate
      ? differenceInDays(newDateTimezoneOffset(nextDate.start), endDate) > 1
      : false;

    if (hasGap) {
      startPeriodIdx = nextIdx;
    }

    const sameMonth =
      options?.mergeSameMonth && isSameMonth(newDateTimezoneOffset(startDate), endDate);

    currentPeriods[startDate] = {
      start: startDate,
      end: sameMonth ? undefined : end,
    };
  }

  return Object.values(currentPeriods);
}

export function getCyclesForFrequencyCount(frequency: PaymentFrequency) {
  if (frequency.type === PaymentFrequencyType.Termly) {
    return frequency.school_year_period_group_id ? frequency.billing_periods.length : 0;
  }
  return frequency.billing_periods.length;
}

export function getBillingDateLabel(order: number, title: string) {
  return `${toOrdinal(order)} ${title}`;
}
