import { Divider, IconButton, Stack } from '@mui/material';
import {
  DEFAULT_DATE_FORMAT_FNS,
  FilterKeys,
  GetStaffQueryFilters,
  GetStaffQuerySort,
  SORT_DIRECTION,
  STAFF_ARRANGE_BY_FILTER_KEYS,
  STAFF_QUERY_FILTER_KEYS,
  StaffArrangeBy,
  StaffSearchResult,
  useGetStaffQuery,
} from '@schooly/api';
import { useAuth } from '@schooly/components/authentication';
import { ChartsCustomGrid, StudentsStaffCharts, useCharts } from '@schooly/components/charts';
import {
  ArrangedByCollapsableSection,
  getCombinedRowsFromSearchResults,
  PageHeader,
  PageHeaderSearchInput,
  StoredFilterSections,
  useArrangeByFromSearchParams,
  useFiltersStateFromSearchParams,
  useLastAppliedFiltersState,
  useSaveLastAppliedFiltersState,
  useSyncFiltersStateWithSearchParams,
} from '@schooly/components/filters';
import { MainGridNoResultsStub } from '@schooly/components/stubs';
import { usePrevious } from '@schooly/hooks/use-previous';
import {
  ChartIcon,
  GridBody,
  MainPageGrid,
  PlusIcon,
  SkeletonGridLoader,
  SkeletonRows,
} from '@schooly/style';
import { format, startOfDay } from 'date-fns';
import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import useUserCounts from '../../hooks/useUserCounts';
import AccessDenied from '../AccessDenied';
import { ArrangedByStaffGrid, StaffArrangedByList } from './StaffArrangedByList';
import { StaffFilters } from './StaffFilters';
import { StaffHeader, StaffRow } from './StaffGrid';

type StaffContentProps = {
  initialFilters?: GetStaffQueryFilters;
  initialArrangeBy?: StaffArrangeBy | null;
};

export const PAGE_SIZE = 30;
export const SKELETON_COLS = 6;

export const StaffContent: FC<StaffContentProps> = ({ initialFilters, initialArrangeBy }) => {
  const { userCounts } = useUserCounts();
  const { permissions, schoolId } = useAuth();
  const navigate = useNavigate();
  const { onToggleChartsOpened, isChartsOpened, showZeroValues } = useCharts();

  const { lastAppliedFilter, lastAppliedArrangeBy } = useLastAppliedFiltersState({
    type: StoredFilterSections.Staff,
    filterKeys: STAFF_QUERY_FILTER_KEYS,
    arrangeByKeys: STAFF_ARRANGE_BY_FILTER_KEYS,
    schoolId: schoolId || '',
  });

  const arrangeByFromSearchParams = useArrangeByFromSearchParams(STAFF_ARRANGE_BY_FILTER_KEYS);
  let arrangeBy = initialArrangeBy;

  if (lastAppliedArrangeBy !== undefined) {
    arrangeBy = lastAppliedArrangeBy;
  } else if (arrangeByFromSearchParams !== undefined) {
    arrangeBy = arrangeByFromSearchParams;
  }

  const [arrangeByWithCustomGrid, setArrangeByCustomGrid] = useState<{
    arrangeBy: StaffArrangeBy;
    totalCount?: number;
    grid: ChartsCustomGrid<GetStaffQueryFilters> | null;
  } | null>(arrangeBy ? { arrangeBy, grid: null } : null);
  const { formatMessage } = useIntl();
  const staffFiltersRef = useRef<StaffFilters | null>(null);

  const prevArrangeByWithCustomGrid = usePrevious(arrangeByWithCustomGrid);

  useEffect(() => {
    if (!prevArrangeByWithCustomGrid && arrangeByWithCustomGrid && !isChartsOpened) {
      onToggleChartsOpened();
    }
  }, [arrangeByWithCustomGrid, isChartsOpened, onToggleChartsOpened, prevArrangeByWithCustomGrid]);

  const handleSetArrangeBy = useCallback((arrangeBy: StaffArrangeBy | null) => {
    setArrangeByCustomGrid(arrangeBy ? { arrangeBy, grid: null } : null);
  }, []);

  const handleSetCustomGrid = useCallback((grid: ChartsCustomGrid<GetStaffQueryFilters> | null) => {
    setArrangeByCustomGrid((arrangeBy) => (arrangeBy ? { ...arrangeBy, grid } : null));
  }, []);

  const handleSetArrangeByTotalCount = useCallback((totalCount?: number) => {
    setArrangeByCustomGrid((arrangeBy) => (arrangeBy ? { ...arrangeBy, totalCount } : null));
  }, []);

  const defaultFilters: GetStaffQueryFilters = useMemo(
    () => ({
      [FilterKeys.Date]: [format(startOfDay(new Date()), DEFAULT_DATE_FORMAT_FNS)],
    }),
    [],
  );

  const initialFiltersState = useFiltersStateFromSearchParams({
    filterKeys: STAFF_QUERY_FILTER_KEYS,
    defaultFilters,
    initialFilters,
  });

  const defaultUserFilters = useMemo(() => {
    return { ...defaultFilters, ...initialFilters };
  }, [defaultFilters, initialFilters]);

  const {
    data,
    hasNextPage,
    isFetchingNextPage,
    isLoading,
    params,
    error,
    setParams,
    fetchNextPage,
  } = useGetStaffQuery(
    {
      query: '',
      schoolId: schoolId || '',
      filters: lastAppliedFilter ?? initialFiltersState,
      pageSize: PAGE_SIZE,
      sort: { columnTextId: 'last_name', direction: SORT_DIRECTION.ASC },
    },
    { refetchOnMount: 'always', enabled: arrangeByWithCustomGrid === null },
  );

  useSyncFiltersStateWithSearchParams({
    pathname: '/staff',
    filters: params.filters,
    arrangeBy: arrangeByWithCustomGrid ? arrangeByWithCustomGrid.arrangeBy : null,
    charts: isChartsOpened,
    zeroes: showZeroValues,
  });

  useSaveLastAppliedFiltersState({
    type: StoredFilterSections.Staff,
    filters: params.filters,
    arrangeBy: arrangeByWithCustomGrid ? arrangeByWithCustomGrid.arrangeBy : null,
    schoolId: schoolId || '',
  });

  const showAddStaffMemberModal = useCallback(() => {
    navigate('/staff/new');
  }, [navigate]);

  const handleChangeSort = useCallback(
    (sort: GetStaffQuerySort) => {
      setParams((p) => ({
        ...p,
        sort,
      }));
    },
    [setParams],
  );

  const handleSetFiltersQuery = useCallback(
    (query: string) => {
      setParams((p) => ({ ...p, query }));
    },
    [setParams],
  );

  const handleSetFilters = useCallback(
    (filters: GetStaffQueryFilters) => {
      setParams((p) => ({ ...p, filters }));
    },
    [setParams],
  );

  const entries = useMemo(
    () =>
      getCombinedRowsFromSearchResults(
        data?.pages.reduce((prev, curr) => [...prev, ...curr.results], [] as StaffSearchResult[]) ??
          [],
      ),
    [data?.pages],
  );

  const total = arrangeByWithCustomGrid
    ? // If we have arrange by
      arrangeByWithCustomGrid.grid
      ? // and have custom grid then show custom grid count
        arrangeByWithCustomGrid.grid.count
      : //or arrange by totalCount
        arrangeByWithCustomGrid.totalCount
    : // if no arrange by then just data.count
      data?.pages[0]?.count;

  const noResults = !isLoading && !entries.length;
  const showCharts = isChartsOpened && !noResults;

  if (!schoolId) return null;

  if (error) {
    return <AccessDenied />;
  }

  return (
    <>
      <Stack gap={1}>
        <PageHeader
          pageTitleCounter={total}
          buttonTextId="staff-NewStaff"
          pageTitleTextId="section-Staff"
          showActionButton={permissions.includes('staff_manager')}
          buttonIcon={<PlusIcon />}
          onButtonClick={showAddStaffMemberModal}
          buttonCounter={userCounts?.non_member_adults || undefined}
        >
          <PageHeaderSearchInput
            value={params.query || ''}
            onChangeText={handleSetFiltersQuery}
            placeholder={formatMessage(
              { id: 'people-SearchAmongType' },
              {
                userTypePlural: formatMessage({
                  id: 'userType-staff-plural',
                }).toLowerCase(),
              },
            )}
          />
        </PageHeader>
        <StaffFilters
          ref={staffFiltersRef}
          arrangeBy={arrangeByWithCustomGrid?.arrangeBy || null}
          onSetArrangeBy={handleSetArrangeBy}
          defaultFilters={defaultFilters}
          onSetFilters={handleSetFilters}
          filters={params.filters}
          schoolId={schoolId || ''}
          defaultUserFilters={defaultUserFilters}
          defaultUserArrangeBy={initialArrangeBy ?? null}
        />
      </Stack>
      {showCharts && (
        <Stack mt={showCharts || (!showCharts && arrangeByWithCustomGrid) ? 3 : 0}>
          <StudentsStaffCharts
            entityType="staff"
            breakDownByOptions={[
              FilterKeys.Status,
              FilterKeys.AgeGroup,
              FilterKeys.House,
              FilterKeys.Gender,
              FilterKeys.Nationality,
              FilterKeys.Department,
            ]}
            arrangeBy={arrangeByWithCustomGrid?.arrangeBy}
            filters={params.filters}
            query={params.query}
            onOpenCustomGrid={handleSetCustomGrid}
            onOpenArrangeByDropdown={staffFiltersRef.current?.openArrangeBy}
          />
        </Stack>
      )}
      {!arrangeByWithCustomGrid ? (
        <>
          <MainPageGrid mt={2.4}>
            <StaffHeader
              sort={params.sort}
              onChangeSort={handleChangeSort}
              rightIcon={
                !showCharts ? (
                  <IconButton onClick={onToggleChartsOpened} disabled={noResults} inverse>
                    <ChartIcon />
                  </IconButton>
                ) : undefined
              }
            />
            <GridBody>
              {entries?.map((entry) => (
                <StaffRow schoolId={schoolId} combinedStaffRow={entry} key={entry.key} />
              ))}
              {isLoading && <SkeletonRows columnsCount={SKELETON_COLS} amount={PAGE_SIZE} />}

              <SkeletonGridLoader
                isFetching={isLoading || isFetchingNextPage}
                fetchNextPage={fetchNextPage}
                hasNextPage={hasNextPage}
                columnsCount={SKELETON_COLS}
                amount={Math.min(
                  PAGE_SIZE,
                  total && data ? total - data.pages.length * PAGE_SIZE : PAGE_SIZE,
                )}
              />
            </GridBody>
          </MainPageGrid>

          {noResults && <MainGridNoResultsStub textId="staff-NoResults-title" />}
        </>
      ) : (
        <>
          {!showCharts && (
            <>
              <Stack flexDirection="row" justifyContent="flex-end" mb={1.5} mt={3.5} pr={1}>
                <IconButton onClick={onToggleChartsOpened} disabled={noResults} inverse>
                  <ChartIcon />
                </IconButton>
              </Stack>
              <Divider />
            </>
          )}
          {arrangeByWithCustomGrid.grid ? (
            <ArrangedByCollapsableSection
              isExpanded
              count={arrangeByWithCustomGrid.grid.count}
              title={arrangeByWithCustomGrid.grid.title}
            >
              <ArrangedByStaffGrid
                count={arrangeByWithCustomGrid.grid.count}
                sort={params.sort}
                filters={arrangeByWithCustomGrid.grid.filters}
                schoolId={schoolId}
                query={params.query}
                onChangeSort={handleChangeSort}
              />
            </ArrangedByCollapsableSection>
          ) : (
            <StaffArrangedByList
              schoolId={schoolId}
              filters={params.filters}
              showZeroCounts={showCharts ? showZeroValues : undefined}
              sort={params.sort}
              arrangeBy={arrangeByWithCustomGrid.arrangeBy}
              query={params.query}
              onChangeSort={handleChangeSort}
              onSetTotalCount={handleSetArrangeByTotalCount}
            />
          )}
        </>
      )}
    </>
  );
};
