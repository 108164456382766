import { IconButton, Stack } from '@mui/material';
import { PropertyTypeTagSelect, SubjectTagSelect } from '@schooly/components/filters';
import { SchoolUserRole } from '@schooly/constants';
import { CrossIcon, TagSelect } from '@schooly/style';
import React, { FC, ReactNode, useMemo } from 'react';
import { useIntl } from 'react-intl';

import { useSchool } from '../../../hooks/useSchool';
import { GroupsQueryFilters } from './AddGroupsExternalSidebar';

type InlineFiltersTagsProps = {
  onOpenFilters: () => void;
  onResetFilters: () => void;
  maxAmount?: number;
  filters: GroupsQueryFilters;
};

export const InlineFiltersTags: FC<InlineFiltersTagsProps> = ({
  filters,
  onOpenFilters,
  onResetFilters,
  maxAmount = 2,
}) => {
  const { schoolId } = useSchool();
  const { formatMessage } = useIntl();
  const tags = useMemo(() => {
    const tagComponents: ReactNode[] = [];

    if (!schoolId) return tagComponents;

    const commonTagsProps = {
      size: 'small',
      onClick: onOpenFilters,
      sx: { maxWidth: 115 },
    } as const;

    for (const id of [
      ...(filters.student_house || []),
      ...(filters.age_group || []),
      ...(filters.student_status || []),
    ]) {
      tagComponents.push(
        <PropertyTypeTagSelect
          key={id}
          id={id}
          schoolId={schoolId}
          userRole={SchoolUserRole.Student}
          {...commonTagsProps}
        />,
      );
    }
    for (const id of [...(filters.staff_house || []), ...(filters.staff_status || [])]) {
      tagComponents.push(
        <PropertyTypeTagSelect
          key={id}
          id={id}
          schoolId={schoolId}
          userRole={SchoolUserRole.Staff}
          {...commonTagsProps}
        />,
      );
    }

    for (const id of filters.subject || []) {
      tagComponents.push(
        <SubjectTagSelect schoolId={schoolId} key={id} id={id} {...commonTagsProps} />,
      );
    }

    if (filters.only_tutor_groups?.length) {
      tagComponents.push(
        <TagSelect
          key="tutor"
          label={formatMessage({ id: 'groups-OnlyTutorGroups' })}
          {...commonTagsProps}
        />,
      );
    }

    if (tagComponents.length > maxAmount) {
      return [
        ...tagComponents.slice(0, maxAmount),
        <TagSelect
          key="more"
          label={`+${tagComponents.length - maxAmount}`}
          {...commonTagsProps}
        />,
      ];
    }

    return tagComponents;
  }, [
    schoolId,
    onOpenFilters,
    filters.only_tutor_groups?.length,
    filters.student_house,
    filters.age_group,
    filters.student_status,
    filters.staff_house,
    filters.staff_status,
    filters.subject,
    maxAmount,
    formatMessage,
  ]);

  if (!tags.length) return null;

  return (
    <Stack flexDirection="row" flexWrap="wrap" gap={1}>
      {tags}{' '}
      <IconButton
        onClick={onResetFilters}
        sx={(theme) => ({
          color: theme.palette.common.grey,
          '&:hover': { color: theme.palette.text.primary },
        })}
      >
        <CrossIcon />
      </IconButton>
    </Stack>
  );
};
