import { Box, Button, Icon, IconButton, Stack, styled, Tooltip, Typography } from '@mui/material';
import {
  DefaultSchoolYear,
  PaymentFrequency,
  PaymentFrequencyType,
  ProductSave,
  SchoolYear,
  useGetSchoolPaymentFrequencies,
} from '@schooly/api';
import { toggleMultipleValueArrayProperty } from '@schooly/components/filters';
import { Currencies } from '@schooly/constants';
import { useFlag } from '@schooly/hooks/use-flag';
import {
  Counter,
  CrossIcon,
  DeleteIcon,
  DropdownYears,
  EditIcon,
  EmptySchoolSvg,
  FullDayIcon,
  HalfDayIcon,
  PlusIcon,
  SimpleButton,
} from '@schooly/style';
import {
  Dispatch,
  FC,
  FocusEventHandler,
  InputHTMLAttributes,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Controller, FieldError, useFieldArray, UseFormReturn } from 'react-hook-form-lts';
import { useIntl } from 'react-intl';

import { getInputErrorText } from '../../../../components/ui/Input/utils';
import useSchoolYears from '../../../../hooks/useSchoolYears';
import { EmptyTypes } from '../EmptyTypes';
import { getTypesByYears } from '../helpers';
import { ProductApplicableSelectMultiple } from './ProductApplicableSelectMultiple';
import { ProductPriceInput } from './ProductPriceInput';
import { IntersectionIds, INTERSECTS_ALL } from './SchoolProductCreateModalContent';

export const HEADER_HEIGHT = 56;
export const CELL_HEIGHT = 44;
export const FREQ_CELL_WIDTH = 110;

enum ValidationError {
  MissingApplicable = 'MissingApplicable',
  NameMustBeUnique = 'NameMustBeUnique',
  NoPricesProvided = 'NoPricesProvided',
}

type SchoolProductCreateModalVariantsProps = {
  frequencies: PaymentFrequency[];
  form: UseFormReturn<ProductSave>;
  schoolId: string;
  onVariantUpdated: () => void;
  typesIntersectionIds: IntersectionIds[];
  currency?: Currencies;
  selectedYear?: SchoolYear;
  setSelectedYear: Dispatch<SetStateAction<SchoolYear | undefined>>;
  yearsForSelect: SchoolYear[];
  defaultYear?: DefaultSchoolYear;
};
export const SchoolProductCreateModalVariants: FC<SchoolProductCreateModalVariantsProps> = ({
  form,
  frequencies,
  schoolId,
  typesIntersectionIds,
  onVariantUpdated,
  currency,
  selectedYear,
  setSelectedYear,
  yearsForSelect,
  defaultYear,
}) => {
  const { $t } = useIntl();

  const {
    fields: types,
    append,
    remove,
  } = useFieldArray({
    control: form.control,
    name: `types`,
  });

  const name = form.watch('name');
  const handleAddType = useCallback(
    () =>
      append({
        ...generateEmptyType(),
        year_id: selectedYear?.id,
      }),
    [append, selectedYear?.id],
  );

  const { schoolYears } = useSchoolYears();
  const typesByYears = useMemo(() => getTypesByYears(types, schoolYears), [schoolYears, types]);
  const currentTypes = typesByYears[selectedYear?.id ?? ''] ?? [];
  const hasCurrentTypes = Boolean(currentTypes.length);

  if (!types.length) return <EmptyTypes onAdd={handleAddType} />;

  //Based on TR-5885 user can only edit products for next year
  const canAddVariant = selectedYear?.id === defaultYear?.id;

  return (
    <>
      <Stack justifyContent="space-between" flexDirection="row" alignItems="center" mb={2.25}>
        <Stack flexDirection="row" alignItems="center">
          <Typography variant="h2">
            {$t({ id: 'products-Types' }, { productName: name })}
          </Typography>
          {hasCurrentTypes && (
            <Counter sx={{ minWidth: 20, minHeight: 20, textAlign: 'center' }}>
              {currentTypes.length}
            </Counter>
          )}
        </Stack>
        <Stack gap={2.5} direction="row">
          <DropdownYears
            years={yearsForSelect}
            defaultYear={defaultYear}
            currentYear={selectedYear}
            onYearChange={setSelectedYear}
            //Based on TR-5885 user can only edit products for next year
            disabled
          />
        </Stack>
      </Stack>
      {hasCurrentTypes ? (
        <>
          {currentTypes.map((type) => {
            const index = types.findIndex((t) => t.id === type.id);
            return (
              <ProductType
                schoolId={schoolId}
                index={index}
                key={type.id}
                frequencies={frequencies}
                form={form}
                onRemove={remove}
                intersectionIds={typesIntersectionIds[index]}
                onVariantUpdated={onVariantUpdated}
                currency={currency}
                yearId={selectedYear?.id ?? defaultYear?.id ?? ''}
              />
            );
          })}
          <Button
            variant="outlined"
            startIcon={<PlusIcon />}
            sx={{ alignSelf: 'flex-start' }}
            onClick={handleAddType}
          >
            {$t({ id: 'products-AddType-WithName' }, { name })}
          </Button>
        </>
      ) : (
        <EmptyTypes
          onAdd={canAddVariant ? handleAddType : undefined}
          svg={<EmptySchoolSvg />}
          productName={name}
        />
      )}
    </>
  );
};

type ProductTypeVariantsProps = {
  schoolId: string;
  frequencies: PaymentFrequency[];
  form: UseFormReturn<ProductSave>;
  index: number;
  onRemove: (i: number) => void;
  onVariantUpdated: () => void;
  intersectionIds?: IntersectionIds;
  currency?: Currencies;
  yearId: string;
};

const ProductType: FC<ProductTypeVariantsProps> = ({
  form,
  index,
  frequencies,
  schoolId,
  yearId,
  intersectionIds,
  onRemove,
  onVariantUpdated,
  currency,
}) => {
  const { $t } = useIntl();
  const { data } = useGetSchoolPaymentFrequencies({ school_id: schoolId, year_id: yearId });

  const {
    fields: variants,
    append,
    remove,
    update,
  } = useFieldArray({
    control: form.control,
    name: `types.${index}.variants`,
  });

  const [frequencyTypes, setFrequencyTypes] = useState<Set<PaymentFrequencyType>>(() => {
    const usedFrequencyIds = variants.flatMap((v) => v.prices.map((p) => p.frequency_id));
    const usedFrequencyTypes = frequencies
      .filter((f) => usedFrequencyIds.includes(f.id))
      .map((f) => f.type);

    return new Set(
      usedFrequencyTypes.length
        ? usedFrequencyTypes
        : [
            PaymentFrequencyType.Monthly,
            PaymentFrequencyType.Termly,
            PaymentFrequencyType.Annually,
          ],
    );
  });
  const handleAddVariant = useCallback(() => append(generateEmptyVariant()), [append]);

  const handleRemoveVariant = useCallback(
    (i: number) => {
      if (variants.length <= 1) {
        onRemove(index);
        return;
      }
      remove(i);
    },
    [index, onRemove, remove, variants.length],
  );

  const { addedFrequencies, availableFrequencies } = useMemo(() => {
    if (!data) {
      return {
        addedFrequencies: [],
        availableFrequencies: [],
      };
    }

    return frequencies.reduce<{
      addedFrequencies: PaymentFrequency[];
      availableFrequencies: PaymentFrequency[];
    }>(
      (acc, frequency) =>
        frequencyTypes.has(frequency.type)
          ? { ...acc, addedFrequencies: [...acc.addedFrequencies, frequency] }
          : {
              ...acc,
              availableFrequencies: frequency.in_use
                ? [...acc.availableFrequencies, frequency]
                : acc.availableFrequencies,
            },
      { addedFrequencies: [], availableFrequencies: [] },
    );
  }, [data, frequencies, frequencyTypes]);

  const toggleFrequency = (fr: PaymentFrequency) => () =>
    setFrequencyTypes((v) => {
      const newTypes = new Set(v);

      const variants = form.getValues(`types.${index}.variants`);

      if (newTypes.has(fr.type)) {
        newTypes.delete(fr.type);

        variants.map((variant, index) => {
          return update(index, {
            ...variant,
            prices: variant.prices.filter((price) => price.frequency_id !== fr.id),
          });
        });

        return newTypes;
      }

      newTypes.add(fr.type);
      return newTypes;
    });

  return (
    <Stack>
      <Controller
        control={form.control}
        name={`types.${index}.name`}
        rules={{
          required: true,
          //TODO Based on TR-5885 user can only create types for next year
          //Once this is changed validation for types in different years should be discussed and updated
          validate: (value, product) => {
            if (product.types.some((t, i) => t.name === value && i !== index)) {
              return ValidationError.NameMustBeUnique;
            }
          },
        }}
        render={({ field, fieldState }) => {
          return (
            <NameInput
              error={fieldState.error}
              placeholder={$t({ id: 'products-Name' })}
              {...field}
            />
          );
        }}
      />

      <Stack
        sx={(theme) => ({
          backgroundColor: theme.palette.background.paper,
          borderRadius: theme.spacing(1),
          mb: theme.spacing(2),
        })}
      >
        <Stack
          sx={{
            flexDirection: 'row',
            alignItems: 'center',
            height: `${HEADER_HEIGHT}px`,
          }}
        >
          <Stack flex={1} px={1}>
            <Typography color="common.grey">
              {$t({ id: 'products-TheProductIsApplicableTo' })}
            </Typography>
          </Stack>
          {addedFrequencies.map((fr) => {
            const f = frequencies.find((r) => r.type === fr.type);

            if (!f) return null;

            return (
              <Stack
                key={f.id}
                sx={(theme) => ({
                  width: `${FREQ_CELL_WIDTH}px`,
                  maxWidth: `${FREQ_CELL_WIDTH}px`,
                  px: 1,
                  position: 'relative',
                  justifyContent: 'center',
                  backgroundColor: theme.palette.background.paper,
                  transition: 'all .2s',
                  height: `${HEADER_HEIGHT}px`,
                  '.icon-delete': {
                    position: 'absolute',
                    alignSelf: 'center',
                    right: theme.spacing(1),
                    transition: 'all .2s',
                    opacity: 0,
                  },
                  '&:hover': {
                    backgroundColor: theme.palette.background.default,
                    paddingRight: theme.spacing(4),
                    '.frequency-date': {
                      color: theme.palette.text.primary,
                    },
                    '.icon-delete': {
                      opacity: 1,
                    },
                  },
                })}
              >
                <Typography variant="h3">{$t({ id: `frequencies-${fr.type}` })}</Typography>
                {addedFrequencies.length > 1 && (
                  <IconButton inverse className="icon-delete" onClick={toggleFrequency(fr)}>
                    <CrossIcon />
                  </IconButton>
                )}
              </Stack>
            );
          })}
          <Stack
            sx={{
              width: 50,
              justifyItems: 'center',
              alignItems: 'center',
            }}
          >
            {!!availableFrequencies.length && (
              <Tooltip
                title={
                  <Stack gap={1} alignItems="flex-start" width={200}>
                    {availableFrequencies.map((fr) => (
                      <SimpleButton
                        key={fr.id}
                        startIcon={<PlusIcon />}
                        onClick={toggleFrequency(fr)}
                      >
                        {$t(
                          { id: 'products-AddFrequency' },
                          { frequency: $t({ id: `frequencies-${fr.type}` }) },
                        )}
                      </SimpleButton>
                    ))}
                  </Stack>
                }
                disableFocusListener
                disableTouchListener
              >
                <IconButton inverse>
                  <PlusIcon />
                </IconButton>
              </Tooltip>
            )}
          </Stack>
        </Stack>
        <Stack
          flexDirection="row"
          sx={(theme) => ({ borderTop: `1px solid ${theme.palette.divider}` })}
        >
          <Stack flex={1}>
            {variants.map((variant, i) => (
              <ProductVariant
                key={variant.id}
                form={form}
                typeIndex={index}
                index={i}
                schoolId={schoolId}
                frequencies={addedFrequencies}
                onRemove={() => handleRemoveVariant(i)}
                intersectionIds={intersectionIds}
                onVariantUpdated={onVariantUpdated}
                currency={currency}
              />
            ))}
            <Stack px={1} py={1.25}>
              <SimpleButton
                startIcon={<PlusIcon />}
                sx={{ alignSelf: 'flex-start' }}
                onClick={handleAddVariant}
              >
                {$t({ id: 'products-AddVariant' })}
              </SimpleButton>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};

type ProductVariantProps = {
  schoolId: string;
  form: UseFormReturn<ProductSave>;
  typeIndex: number;
  index: number;
  onRemove: () => void;
  onVariantUpdated: () => void;
  frequencies: PaymentFrequency[];
  intersectionIds?: IntersectionIds;
  currency?: Currencies;
};
const ProductVariant: FC<ProductVariantProps> = ({
  typeIndex,
  index,
  form,
  schoolId,
  frequencies,
  intersectionIds,
  onRemove,
  onVariantUpdated,
  currency,
}) => {
  const { $t } = useIntl();

  const variant = form.watch(`types.${typeIndex}.variants.${index}`);

  useEffect(() => {
    onVariantUpdated();
  }, [index, onVariantUpdated, typeIndex, variant]);

  return (
    <Stack
      sx={(theme) => ({
        flexDirection: 'row',
        borderBottom: `1px solid ${theme.palette.divider}`,
        '&:hover': {
          backgroundColor: theme.palette.background.default,
        },
      })}
    >
      <Controller
        control={form.control}
        name={`types.${typeIndex}.variants.${index}`}
        rules={{
          validate: (value) => {
            if (!value.age_groups.length && !value.subjects.length)
              return ValidationError.MissingApplicable;
          },
        }}
        render={({ field, fieldState: { error } }) => {
          const { half_day, subjects, age_groups } = field.value || {};

          const hasEmptyError =
            error?.type === 'validate' && error.message === ValidationError.MissingApplicable;

          const intersectionsForDay = intersectionIds?.[half_day ? 'halfDayIds' : 'fullDayIds'];

          //TODO Based on TR-5885 user can only create types for next year
          //Once this is changed validation for types in different years should be discussed and updated
          const ageGroupsIntersections =
            !age_groups.length &&
            intersectionsForDay?.some(({ ageGroupId }) => ageGroupId !== INTERSECTS_ALL)
              ? INTERSECTS_ALL
              : age_groups.filter((agId) =>
                  intersectionsForDay?.map(({ ageGroupId }) => ageGroupId).includes(agId),
                );
          const subjectIntersections = subjects.filter((agId) =>
            intersectionsForDay?.map(({ subjectId }) => subjectId).includes(agId),
          );

          const hasIntersectionError = !!(
            subjectIntersections.length || ageGroupsIntersections.length
          );

          const DayIcon = half_day ? HalfDayIcon : FullDayIcon;

          return (
            <>
              <Stack width="50px" justifyContent="center" alignItems="center">
                <Tooltip
                  title={$t({
                    id: half_day
                      ? 'products-ProductVariantAppliesToHalfDay'
                      : 'products-ProductVariantAppliesToAll',
                  })}
                >
                  <IconButton
                    sx={(theme) => ({
                      '&:hover': {
                        color: theme.palette.common.main2,
                      },
                    })}
                    onClick={() => {
                      field.onChange({
                        ...field.value,
                        half_day: !half_day,
                      });
                    }}
                  >
                    <DayIcon />
                  </IconButton>
                </Tooltip>
              </Stack>
              <Stack
                flex={1}
                sx={(theme) => ({
                  borderLeft: `1px solid ${theme.palette.divider}`,
                  justifyContent: 'center',
                  position: 'relative',
                })}
              >
                <ProductApplicableSelectMultiple
                  schoolId={schoolId}
                  onSelectSubjectId={(id) => {
                    field.onChange({
                      ...field.value,
                      subjects: field.value.subjects.includes(id)
                        ? field.value.subjects.filter((sid) => sid !== id)
                        : [...field.value.subjects, id],
                    });
                  }}
                  onSelectAgeGroupIds={(ids) => {
                    field.onChange({
                      ...field.value,
                      age_groups: toggleMultipleValueArrayProperty(field.value.age_groups, ids),
                    });
                  }}
                  intersectionSubjectIds={subjectIntersections}
                  intersectionAgeGroupIds={ageGroupsIntersections}
                  selectedAgeGroupIds={field.value.age_groups}
                  selectedSubjectIds={field.value.subjects}
                />
                {(hasEmptyError || hasIntersectionError) && (
                  <Box
                    sx={(theme) => ({
                      pointerEvents: 'none',
                      position: 'absolute',
                      left: hasIntersectionError ? -51 : 0,
                      top: -1,
                      right: 0,
                      bottom: -1,
                      border: `1px solid ${theme.palette.error.main}`,
                    })}
                  />
                )}
              </Stack>
            </>
          );
        }}
      />
      <Controller
        control={form.control}
        name={`types.${typeIndex}.variants.${index}.prices`}
        rules={{
          validate: (value) => {
            if (value.some((v) => !!v.price)) return;
            return ValidationError.NoPricesProvided;
          },
        }}
        render={({ field, fieldState: { error } }) => {
          const hasNoPricesError =
            error?.type === 'validate' && error.message === ValidationError.NoPricesProvided;

          return (
            <Stack flexDirection="row" alignItems="center" position="relative">
              {frequencies.map((fr) => {
                const relatedPrice = field.value.find((p) => p.frequency_id === fr.id);

                return (
                  <Stack
                    key={fr.id}
                    sx={(theme) => ({
                      width: `${FREQ_CELL_WIDTH}px`,
                      maxWidth: `${FREQ_CELL_WIDTH}px`,
                      borderLeft: `1px solid ${theme.palette.divider}`,

                      alignSelf: 'stretch',
                      justifyContent: 'center',
                    })}
                  >
                    <ProductPriceInput
                      currency={currency ?? ''}
                      value={relatedPrice?.price}
                      onChange={(price) => {
                        field.onChange([
                          ...field.value.filter((p) => p.frequency_id !== fr.id),
                          ...(price ? [{ frequency_id: fr.id, price }] : []),
                        ]);
                      }}
                    />
                  </Stack>
                );
              })}
              {hasNoPricesError && (
                <Box
                  sx={(theme) => ({
                    pointerEvents: 'none',
                    position: 'absolute',
                    left: 0,
                    top: -1,
                    right: 0,
                    bottom: -1,
                    border: `1px solid ${theme.palette.error.main}`,
                  })}
                />
              )}
            </Stack>
          );
        }}
      />
      <Stack
        sx={(theme) => ({
          width: 50,
          alignSelf: 'stretch',
          borderLeft: `1px solid ${theme.palette.divider}`,
          justifyContent: 'center',
          alignItems: 'center',
        })}
      >
        <IconButton inverse onClick={onRemove}>
          <DeleteIcon />
        </IconButton>
      </Stack>
    </Stack>
  );
};

const generateEmptyVariant = (): ProductSave['types'][0]['variants'][0] => ({
  half_day: false,
  age_groups: [],
  subjects: [],
  prices: [],
});

const generateEmptyType = (): ProductSave['types'][0] => ({
  name: '',
  variants: [generateEmptyVariant()],
});

type NameInputProps = InputHTMLAttributes<HTMLInputElement> & {
  error?: FieldError;
};
export const NameInput: FC<NameInputProps> = ({
  error,
  onFocus: onFocusProps,
  onBlur: onBlurProps,
  ...rest
}) => {
  const { $t } = useIntl();
  const [isFocused, focus, blur] = useFlag(false);

  const onFocus: FocusEventHandler<HTMLInputElement> = (...props) => {
    onFocusProps?.(...props);
    focus();
  };
  const onBlur: FocusEventHandler<HTMLInputElement> = (...props) => {
    onBlurProps?.(...props);
    blur();
  };

  return (
    <Stack
      sx={(theme) => ({
        mb: 1,
        gap: theme.spacing(0.5),
        position: 'relative',
        maxWidth: 500,
        pr: isFocused ? 0 : 3,
        transition: 'all .2s',
        '.edit-icon': {
          position: 'absolute',
          right: 0,
          top: theme.spacing(0.5),
          pointerEvents: 'none',
          transition: 'all .2s',
          opacity: isFocused ? 0 : undefined,
        },
        ':not(:hover) .edit-icon': {
          opacity: 0,
        },
      })}
    >
      <NameInputStyled
        error={!!error}
        onFocus={onFocus}
        onBlur={onBlur}
        autoFocus={!rest.value}
        placeholder={$t({ id: 'products-Name' })}
        {...rest}
      />
      {error && (
        <>
          <Typography variant="caption" color="error.main">
            {error.message === ValidationError.NameMustBeUnique
              ? $t({ id: 'products-TheNameMustBeUnique' })
              : $t(getInputErrorText(error))}
          </Typography>
        </>
      )}

      <Icon className="edit-icon">
        <EditIcon />
      </Icon>
    </Stack>
  );
};

const NameInputStyled = styled('input')<{ error: boolean }>(({ theme, error }) => ({
  background: 'none',
  borderLeft: 'none',
  borderRight: 'none',
  borderTop: 'none',
  borderBottom: error
    ? `2px solid ${theme.palette.error.main} !important`
    : `2px solid transparent`,
  width: '100%',
  ...theme.typography.h2,
  padding: 0,
  transition: 'all .2s',
  color: error ? theme.palette.error.main : undefined,
  '&:hover, &:focus': {
    borderBottomColor: theme.palette.text.primary,
  },
  '&::placeholder': {
    color: error ? theme.palette.error.main : theme.palette.common.grey,
  },
}));
