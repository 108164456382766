import {
  Icon,
  IconButton,
  Stack,
  TableHeadProps,
  TableRow,
  Theme,
  Tooltip,
  Typography,
} from '@mui/material';
import { PaymentFrequency, Product } from '@schooly/api';
import { SchoolUserRole } from '@schooly/constants';
import { useFlag } from '@schooly/hooks/use-flag';
import { useSchoolProperties } from '@schooly/hooks/use-school-properties';
import {
  ArrowAngleIcon,
  DropdownIcon,
  FullDayIcon,
  GridCell,
  GridHead,
  HalfDayIcon,
  LockIcon,
  TypographyWithOverflowHint,
} from '@schooly/style';
import { FC, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';

import { getVariantPrice, renderAccountName, useProductFrequency } from './helpers';
import { ProductApplicable } from './ProductsApplicable';
import {
  AllFrequenciesNotInUse,
  FrequenciesTooltip,
  NotInUseFrequencyLabel,
} from './SchoolProductCommon';

type ProductsHeaderProps = {
  frequencies: PaymentFrequency[];
};

export const ProductsHeader: FC<TableHeadProps & ProductsHeaderProps> = ({
  frequencies,
  ...rest
}) => {
  const { $t } = useIntl();

  return (
    <GridHead borderBottom {...rest}>
      <GridCell width="20px" />
      <GridCell width={frequencies.length <= 2 ? '250px' : '200px'}>
        {$t({ id: 'products-Name' })}
      </GridCell>
      <GridCell width="30px" />
      <GridCell width="30%">{$t({ id: 'products-Account' })}</GridCell>
      <GridCell width="20%">{$t({ id: 'products-Trigger' })}</GridCell>
      <GridCell width="20%">{$t({ id: 'products-Product-Required' })}</GridCell>

      {frequencies.map((f) => {
        return (
          <GridCell key={f.id} width="120px">
            <Stack direction="row" alignItems="center" gap={0.5}>
              {$t({ id: `frequencies-${f.type}` })}
              {!f.in_use && (
                <Icon>
                  <LockIcon />
                </Icon>
              )}
            </Stack>
          </GridCell>
        );
      })}
      <GridCell width="40px" />
    </GridHead>
  );
};

type ProductRowProps = {
  product: Product;
  schoolId: string;
  isSelected?: boolean;
  onSelect?: (v: string) => void;
  currencySymbol: string;
  frequencies: PaymentFrequency[];
  canViewFrequency: boolean;
};

export const ProductRow: FC<ProductRowProps> = ({
  product,
  schoolId,
  currencySymbol,
  frequencies,
  canViewFrequency,
}) => {
  const ref = useRef<HTMLAnchorElement | null>(null);
  const { $t } = useIntl();
  const [isExpanded, expand, collapse] = useFlag();
  const { propertiesMap } = useSchoolProperties({ schoolId, userType: SchoolUserRole.Student });
  const [rowHovered, setRowHovered] = useState(false);

  const { name, id, types, account, trigger, obligatory } = product;

  const hoverProps = {
    onMouseEnter: () => setRowHovered(true),
    onMouseLeave: () => setRowHovered(false),
    sx: (theme: Theme) => ({
      ...(rowHovered && {
        'td.MuiTableCell-root': {
          backgroundColor: theme.palette.background.default,
          '.MuiTypography-root': {
            color: theme.palette.primary.main,
          },
        },
      }),
    }),
  };
  const { allTypesNotActive, productTypeNotActiveMap } = useProductFrequency({
    frequencies: frequencies,
    product,
  });

  const borderBottomOfFirstRow = isExpanded ? false : undefined;

  const typeNames = product.types.map((t, i, arr) => {
    const typeName = `"${t.name}"`;
    if (arr.length <= 1) return typeName;

    const lastIndex = arr.length - 1;
    const separator = i < lastIndex - 1 ? ',' : '';
    return i !== lastIndex ? `${typeName}${separator}` : `${$t({ id: ' and' })} ${typeName}`;
  });

  return (
    <>
      <TableRow ref={ref} component={Link} to={`/settings/products/${id}`} {...hoverProps}>
        <GridCell borderBottom={borderBottomOfFirstRow} />
        <GridCell borderBottom={borderBottomOfFirstRow}>
          <Stack direction="row" gap={0.75}>
            <TypographyWithOverflowHint variant="h3" color="text.primary">
              {name}
            </TypographyWithOverflowHint>
            {allTypesNotActive && !isExpanded && (
              <AllFrequenciesNotInUse
                name={`${$t({
                  id: product.types.length > 1 ? 'products-Type-plural' : 'products-Type',
                })}
                ${typeNames.join('')}
                `}
                plural={typeNames.length > 1}
              />
            )}
          </Stack>
        </GridCell>
        <GridCell borderBottom={borderBottomOfFirstRow} />
        <GridCell borderBottom={borderBottomOfFirstRow}>
          <TypographyWithOverflowHint color="text.primary">
            {renderAccountName(account)}
          </TypographyWithOverflowHint>
        </GridCell>
        <GridCell borderBottom={borderBottomOfFirstRow}>
          <TypographyWithOverflowHint color="text.primary">
            {propertiesMap.status.find((s) => s.id === trigger.extra_data.status)?.name}
          </TypographyWithOverflowHint>
        </GridCell>
        <GridCell borderBottom={borderBottomOfFirstRow}>
          <Typography color="text.primary">{$t({ id: obligatory ? 'yes' : 'no' })}</Typography>
        </GridCell>
        {!!frequencies.length && (
          <GridCell colSpan={frequencies.length} borderBottom={borderBottomOfFirstRow} />
        )}
        <GridCell
          borderBottom={borderBottomOfFirstRow}
          onClick={(e) => {
            e.preventDefault();
            isExpanded ? collapse() : expand();
          }}
        >
          {!!types.length && (
            <IconButton
              inverse
              sx={(theme) => ({
                mr: 1.5,
                transform: isExpanded ? 'rotate(180deg)' : undefined,
                color: rowHovered ? theme.palette.common.grey2 : undefined,
              })}
            >
              <DropdownIcon />
            </IconButton>
          )}
        </GridCell>
      </TableRow>
      <>
        {isExpanded &&
          types.map((type, typeIndex, types) => {
            const isLastType = typeIndex === types.length - 1;
            const isFirstType = typeIndex === 0;

            const notActive = productTypeNotActiveMap[type.id];
            return (
              <>
                <TableRow
                  key={type.id}
                  component={Link}
                  to={`/settings/products/${id}`}
                  {...hoverProps}
                >
                  <GridCell borderBottom={false} />
                  <GridCell borderBottom={false} p={0} pl={1}>
                    <Stack direction="row" gap={2} alignItems="center">
                      <Icon
                        sx={(theme) => ({
                          color: rowHovered
                            ? theme.palette.common.grey2
                            : theme.palette.common.grey,
                        })}
                      >
                        <ArrowAngleIcon />
                      </Icon>
                      <Stack
                        width="100%"
                        py={2}
                        sx={(theme) => ({
                          borderTop: `1px solid ${theme.palette.divider}`,
                        })}
                        direction="row"
                        gap={0.5}
                      >
                        <Typography variant="h3" color="text.primary">
                          {type.name}
                        </Typography>

                        {notActive && (
                          <AllFrequenciesNotInUse
                            name={`${$t({ id: 'products-Type' })} ${type.name}`}
                          />
                        )}
                      </Stack>
                    </Stack>
                  </GridCell>
                  <GridCell
                    borderBottom={false}
                    colSpan={4 + frequencies.length}
                    borderTop={isExpanded}
                  />
                  <GridCell borderBottom={false} borderTop={isExpanded && !isFirstType} />
                </TableRow>
                {type.variants.map((variant, index, variants) => {
                  const isLastVariant = index === variants.length - 1;

                  return (
                    <TableRow
                      key={variant.id}
                      component={Link}
                      to={`/settings/products/${id}`}
                      {...hoverProps}
                    >
                      <GridCell
                        colSpan={2}
                        borderBottom={isLastVariant && isLastType ? undefined : false}
                      />
                      <GridCell borderBottom={isLastVariant && isLastType ? undefined : false}>
                        <Icon
                          sx={(theme) => ({
                            color: rowHovered
                              ? theme.palette.primary.main
                              : theme.palette.common.grey2,
                          })}
                        >
                          {variant.half_day ? (
                            <Tooltip
                              title={$t({
                                id: 'products-ThisProductVariantAppliesToHalfDayStudents',
                              })}
                            >
                              <Icon>
                                <HalfDayIcon />
                              </Icon>
                            </Tooltip>
                          ) : (
                            <Tooltip
                              title={$t({ id: 'products-ThisProductVariantAppliesToAllStudents' })}
                            >
                              <Icon>
                                <FullDayIcon />
                              </Icon>
                            </Tooltip>
                          )}
                        </Icon>
                      </GridCell>
                      <GridCell
                        colSpan={3}
                        borderBottom={!isLastType && isLastVariant ? false : undefined}
                      >
                        <ProductApplicable
                          pt={0.25}
                          color="text.primary"
                          schoolId={schoolId}
                          selectedAgeGroupIds={variant.age_groups}
                          selectedSubjectIds={variant.subjects}
                        />
                      </GridCell>
                      {frequencies.map((frequency) => {
                        const price = getVariantPrice(variant, currencySymbol, frequency.id);

                        return (
                          <GridCell
                            key={frequency.id}
                            borderBottom={!isLastType && isLastVariant ? false : undefined}
                          >
                            <FrequenciesTooltip
                              title={
                                !frequency.in_use && !!price ? (
                                  <NotInUseFrequencyLabel
                                    frequency={frequency}
                                    canView={canViewFrequency}
                                  />
                                ) : (
                                  ''
                                )
                              }
                            >
                              <Typography
                                sx={(theme) => ({
                                  color: frequency.in_use
                                    ? theme.palette.text.primary
                                    : `${theme.palette.common.grey} !important`,
                                  ...(!price && {
                                    '&&&.MuiTypography-root': {
                                      color: 'text.secondary',
                                    },
                                  }),
                                })}
                              >
                                {price || '-'}
                              </Typography>
                            </FrequenciesTooltip>
                          </GridCell>
                        );
                      })}
                      <GridCell borderBottom={isLastVariant && isLastType ? undefined : false} />
                    </TableRow>
                  );
                })}
              </>
            );
          })}
      </>
    </>
  );
};
